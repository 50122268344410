import * as React from 'react';

import { Document } from 'Application/types';
import { formBackendDateString } from 'src/shared/utils/Utils';

import { FileListProps } from 'Common/Form';

import { CrossBtn } from 'shared/styled';
import { DocumentsTableWrapper, Table, TableHead, TableBody } from './styles';

export interface IRow {
  id: number;
  data: Array<string | number | JSX.Element>;
}

interface Props extends Partial<FileListProps> {
  className?: string;
  headers?: (string | JSX.Element)[];
  rows: IRow[];
  sizes?: string[];
  footer?: JSX.Element;
}

export const DocumentsTable: React.FC<Props> = ({
  className,
  headers = ['№', 'Название', 'Дата добавления', 'Размер файла', ''],
  rows,
  footer,
  sizes = ['5%', '50%', '20%', '20%', '5%']
}) => (
  <DocumentsTableWrapper className={!!className ? className : ''}>
    <Table sizes={sizes}>
      <TableHead>
        <tr>
          {headers.map((item, key) => (
            <th key={key}>{item}</th>
          ))}
        </tr>
      </TableHead>

      <TableBody>
        {rows.map(row => (
          <tr key={row.id}>
            {row.data.map((item, key) => (
              <td key={key}>
                {/* Last item - Svg Download Link, should be out of span */}
                {key < row.data.length - 1 ? <span>{item}</span> : item}
              </td>
            ))}
          </tr>
        ))}
      </TableBody>

      {!!footer && footer}
    </Table>
  </DocumentsTableWrapper>
);
