import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { openModal } from 'Modal/actions/toggleModal';
import { MODAL_NAMES } from 'src/features/Common/Modal/types';
import { OpenModalDataType } from 'Modal/reducers/toggleModal';
import { Modal } from 'src/features/Common';
import {
  req as putMonetaryClaimDeleteToAuction,
  reset as resetMonetaryClaimDeleteToAuction
} from 'src/features/SCF/actions/putMonetaryClaimDeleteToAuction';
import {
  req as getCompanyByInn,
  RequestDataType,
  ResponseDataType
} from 'src/features/SCF/actions/getCompanyByInnThirdParty';
import {
  req as getMonetaryClaims,
  RequestDataType as MonetaryClaimReq,
  ResponseDataType as MonetaryClaimRes
} from 'entities/SCF/Debtor/model/actions/getMonetaryClaimsSuppliesRole';
import {
  req as getByMonetaryClaimHint,
  ResponseData as ResponseMonetaryClaimHint
} from 'entities/SCF/Debtor/model/actions/getByMonetaryClaimDebtor';

import { STORE, REQUEST_STATUSES, ResponseError } from 'globaltypes';
import {
  SupplierInfoPageStyled,
  SupplierInfoArea,
  SupplierInfo,
  ErrorDeleteDiscount
} from './styles';
import { Loader, Menu } from 'src/features/Layouts/components';
import { SUPPLIER_TABS } from './types';
import MonetaryClaims from './Tabs/MonetaryClaims/MonetaryClaims';
import { Contracts } from './Tabs/Contracts/Contracts';

interface StateToProps {
  companyByInn: ResponseDataType;
  status: REQUEST_STATUSES;
  error: ResponseError;
  monetaryClaims: MonetaryClaimRes;
  statusMonetaryClaims: REQUEST_STATUSES;
  monetaryClaimsHint: ResponseMonetaryClaimHint[];
  statusMonetaryClaimsHint: REQUEST_STATUSES;
  statusDeleteAuction: REQUEST_STATUSES;
  errorDeleteAuction: ResponseError;
}

interface DispatchToProps {
  getCompanyByInn: (data: RequestDataType) => void;
  getMonetaryClaims: (data: MonetaryClaimReq) => void;
  getByMonetaryClaimHint: (guid: string) => void;
  putMonetaryClaimDeleteToAuction: (guid: string, auction_guid: string) => void;
  resetMonetaryClaimDeleteToAuction: () => void;
  openModal: (data: OpenModalDataType) => void;
}

interface MatchParams {
  id: string;
  tab: keyof typeof SUPPLIER_TABS;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const SupplierInfoPage: React.FC<Props> = ({
  getCompanyByInn,
  companyByInn,
  status,
  getMonetaryClaims,
  monetaryClaims,
  statusMonetaryClaims,
  getByMonetaryClaimHint,
  monetaryClaimsHint,
  statusMonetaryClaimsHint,
  putMonetaryClaimDeleteToAuction,
  resetMonetaryClaimDeleteToAuction,
  statusDeleteAuction,
  errorDeleteAuction,
  openModal,
  history,
  match
}) => {
  const [activeTab, setActiveTab] = React.useState<keyof typeof SUPPLIER_TABS>(
    (match.params.tab &&
      (match.params.tab.toUpperCase() as keyof typeof SUPPLIER_TABS)) ||
      'DEFAULT'
  );

  React.useEffect(() => {
    return () => resetMonetaryClaimDeleteToAuction();
  }, []);

  React.useEffect(
    () => {
      const searchParams = new URLSearchParams(history.location.search);
      const page = +searchParams.get('page') || 1;

      getMonetaryClaims({ page, pageSize: 20, supplierInn: match.params.id });
    },
    [history.location.search]
  );

  React.useEffect(
    () => {
      if (statusDeleteAuction === REQUEST_STATUSES.GOT) {
        const searchParams = new URLSearchParams(history.location.search);
        const page = +searchParams.get('page') || 1;

        getMonetaryClaims({ page, pageSize: 20, supplierInn: match.params.id });
      }
      if (statusDeleteAuction === REQUEST_STATUSES.ERROR) {
        openModal({ name: MODAL_NAMES.ERROR_DELETE_AUCTION });
      }
    },
    [statusDeleteAuction]
  );

  React.useEffect(
    () => {
      if (match.params.id) {
        getCompanyByInn({ inn: match.params.id });
      }

      return () => {
        companyByInn.companyFullName = '';
        companyByInn.inn = '';
        companyByInn.kpp = '';
        companyByInn.companyAddress = '';
      };
    },
    [match.params.id]
  );

  const handleActiveTab = (tabName: keyof typeof SUPPLIER_TABS) => {
    const baseUrl = history.location.pathname.replace(
      `/${activeTab.toLowerCase()}`,
      ''
    );

    let newPathname: string;

    if (tabName === 'DEFAULT') {
      newPathname = baseUrl;
    } else {
      newPathname = `${baseUrl}/${tabName.toLowerCase()}`;
    }

    history.replace({ pathname: newPathname, search: 'page=1' });
    setActiveTab(tabName);
  };

  return (
    <SupplierInfoPageStyled>
      {status === REQUEST_STATUSES.REQUEST && <Loader />}

      {status === REQUEST_STATUSES.GOT && (
        <SupplierInfoArea>
          <p>Поставщик</p>
          <h1>{companyByInn.companyFullName}</h1>
          <SupplierInfo>
            <div>
              <p>
                ИНН:{' '}
                {companyByInn.inn ? companyByInn.inn : Number(match.params.id)}
              </p>
              <p>ОГРН: {companyByInn.ogrn}</p>
              <p>КПП: {companyByInn.kpp}</p>
            </div>
            <p>Адрес: {companyByInn.companyAddress}</p>
          </SupplierInfo>
        </SupplierInfoArea>
      )}

      <Menu
        activeTab={activeTab}
        handleActiveTab={handleActiveTab}
        tabs={SUPPLIER_TABS}
      />

      {!!Object.keys(errorDeleteAuction).length && (
        <Modal name={MODAL_NAMES.ERROR_DELETE_AUCTION}>
          <ErrorDeleteDiscount>
            <div>Ошибка: {errorDeleteAuction.code}</div>
            <div>{errorDeleteAuction.body}</div>
          </ErrorDeleteDiscount>
        </Modal>
      )}

      {activeTab === 'DEFAULT' && (
        <MonetaryClaims
          getByMonetaryClaimHint={getByMonetaryClaimHint}
          monetaryClaimsHint={monetaryClaimsHint}
          statusMonetaryClaimsHint={statusMonetaryClaimsHint}
          monetaryClaims={monetaryClaims}
          status={statusMonetaryClaims}
          putMonetaryClaimDeleteToAuction={putMonetaryClaimDeleteToAuction}
          type={['scf', 'debtor']}
          noData={{
            title: 'ПОСТАВКИ НЕ НАЙДЕНЫ',
            text: 'поставки от этого поставщика не найдены'
          }}
        />
      )}

      {activeTab === 'CONTRACTS' && <Contracts />}
    </SupplierInfoPageStyled>
  );
};

const mapStateToProps = ({ SCF, SCFDebtor }: STORE) => ({
  companyByInn: SCF.getCompanyByInnThirdParty.data,
  status: SCF.getCompanyByInnThirdParty.status,
  error: SCF.getCompanyByInnThirdParty.error,
  monetaryClaims: SCFDebtor.getMonetaryClaimsSuppliesRole.data,
  statusMonetaryClaims: SCFDebtor.getMonetaryClaimsSuppliesRole.status,
  monetaryClaimsHint: SCFDebtor.getByMonetaryClaimDebtor.data,
  statusMonetaryClaimsHint: SCFDebtor.getByMonetaryClaimDebtor.status,
  statusDeleteAuction: SCF.putMonetaryClaimDeleteToAuction.status,
  errorDeleteAuction: SCF.putMonetaryClaimDeleteToAuction.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCompanyByInn,
      getMonetaryClaims,
      getByMonetaryClaimHint,
      putMonetaryClaimDeleteToAuction,
      resetMonetaryClaimDeleteToAuction,
      openModal
    },
    dispatch
  );

const SupplierInfoPageConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(SupplierInfoPage)
);

export { SupplierInfoPageConnect as SupplierInfoPage };