import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import {
  STORE,
  REQUEST_STATUSES,
  USER_PERMISSIONS,
  ResponseError
} from 'globaltypes';
import { history } from 'src/shared/utils/History';

import {
  Document,
  APPLICATION_STATUSES,
  SELECT_STATUS,
  APPLICATION_TYPE_ROUTES,
  GuaranteesApplicationRead
} from 'Application/types';

import { TransitionWrapper, ANIMATION_NAMES } from 'Common';
import {
  Spinner,
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE
} from 'Layouts/components';

import {
  ApplicationViewStyled,
  LoaderContainerStyled,
  ViewSeparatorTop,
  EditApplicationBtn,
  ActivityWrapper,
  SentToBankStyled,
  ShowToAgentWrapper,
  CheckboxStyleShowToAgent,
  ProductApiContainer,
  Spinner as SpinnerApi,
  Limit,
  Rejection,
  UploadButton,
  TitleTabContainer,
  RejectionToggle,
  CompanyHead,
  TableWrapper,
  TDDocumentName,
  Period,
  IsNecessarily,
  FileContainer,
  IsUploadContainer,
  DownloadFile,
  ButtonContainer,
  PlatformContainer,
  TooltipBoxStyle
} from './styles';
import { ResponseData } from 'src/features/Application/reducers/getCompanyHints';
import { ResponseData as ResponseActivities } from 'src/features/Application/actions/getActivitiesByApplication';
import { ResponseData as ResponseProductOfApplication } from 'src/features/Application/actions/getProductApplication';
import { set as setApplicationProcessMode } from 'Application/actions/setApplicationProcessMode';
import {
  RequestDataType as SetApplicationProcessModeRequestDataType,
  APPLICATION_PROCESS_MODES
} from 'Application/reducers/setApplicationProcessMode';
import { req as postFinFile } from 'entities/Cabinet/ExternalAgent/model/actions/postFinancialDocumentExternal';
import { req as postNotFinFile } from 'entities/Cabinet/ExternalAgent/model/actions/postNonFinancialDocumentExternal';
import { req as postSelectApplication } from 'Application/actions/postSelectApplication';
import { req as getDownloadDocumets } from 'src/features/DossierForCompany/actions/getDownloadDocument';
import { req as getDonwloadNonFinDocument } from 'src/features/DossierForCompany/actions/getDonwloadNonFinDocument';
import {
  req as sendToBank,
  reset as resetSentToBank
} from 'src/features/Application/actions/putSendToBank';
import { req as postExternalApiAlfaBank } from 'Application/actions/postExternalApiAlfaBank';
import { req as postExternalApiPSB } from 'Application/actions/postExternalApiPSB';
import { req as getExternalApiGIR_BO } from 'Application/actions/getExternalApiGIR_BO';
import { req as putSetPreapproved } from 'Application/actions/putSetPreapproved';
import { req as putSetApproved } from 'Application/actions/putSetApproved';
import { req as putRejected } from 'src/features/Application/actions/putRejected';
import { req as postSendCompanyToScoring } from 'src/features/Leads/actions/postSendCompanyToScoring';
import {
  req as getAllOffers,
  reset as resetAllOffers,
  ResponseData as ResAllOffers
} from 'Application/actions/getAllOffers';
import {
  req as postOfferSend,
  reset as resetOfferSend
} from 'Application/actions/postOfferSend';
import { req as postDocumentsOfDocumentList } from 'src/features/Application/actions/postDocumentsOfDocumentLists';
import {
  req as getDocumentListExternal,
  reset as resetDocumentListExternal
} from 'entities/Cabinet/ExternalAgent/model/actions/getDocumentListExternal';
import {
  ResponseData as ResDocumentListExternal,
  DocumentList
} from 'entities/Cabinet/ExternalAgent/model/reducers/getDocumentListExternal';
import { ResponseData as FinancailsSummaryData } from 'Application/reducers/getFinancailsSummary';
import { ApplicationTitle } from 'src/features/Application/components/ApplicationView/EachApplicationView/DataBlock/styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import { CheckboxStyle } from 'src/features/Leads/components/LeadView/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faChevronDown,
  faChevronUp,
  faEye,
  faEyeSlash,
  faSpinner,
  faTimes,
  faUpload
} from '@fortawesome/free-solid-svg-icons';
import { formSumStringThousands } from 'src/shared/utils/Utils';
import {
  CloseButton,
  PopupContainer,
  PopupContent
} from 'src/features/Products/components/ProductView/styled';
import {
  ModalContainer,
  ProductOffersSection,
  PurchaseContent,
  SendApi
} from 'pages/Cabinet/InternalAgent/Pages/ApplicationProccess/GuaranteesForm/GuaranteesFormStep0/styles';
import { PersonDocumentsType } from 'src/features/DocumentList/types';
import {
  RejectReasonModal,
  rejectReasons
} from 'src/features/Application/components/ApplicationView/EachApplicationView/RejectReasonModal';
import { DelegateInternalAgentToApplicationSelect } from 'src/features/Application/components/ApplicationView/EachApplicationView/DelegateInternalAgentToApplicationSelect/DelegateInternalAgentToApplicationSelect';
import { SwitchBanksBlock } from './SwitchBanksBlock/SwitchBanksBlock';
import { ContentContainer } from './ContentContainer/ContentContainer';
import { ChooseBanksModal } from './ChooseBanksModal/ChooseBanksModal';
import { ActionButtonsBlock } from './ActionButtonsBlock/ActionButtonsBlock';
import { ActionModal } from './ActionModal/ActionModal';
import { CommentTabs } from './CommentTabs/CommentTabs';
import { redirectToApplication } from 'shared/lib/routing/redirectToApplication';

export interface Info {
  name: string;
  value: JSX.Element | string;
}

export interface IApplicationInfo {
  mainInfo: Info[];
  subInfo: Info[];
}

export interface IApplicationDocuments {
  documents?: {
    general: Document[];
    company: Document[] | any;
  };
}

export type IApplicationData = IApplicationInfo & IApplicationDocuments;

export interface IOwnProps extends IApplicationData {
  children?: any;
}

interface StateToProps {
  externalApplication: Partial<GuaranteesApplicationRead>;
  statusApplication: REQUEST_STATUSES;
  switchBankStatus: REQUEST_STATUSES;
  sendToBankStatus: REQUEST_STATUSES;
  permissions: USER_PERMISSIONS[];
  roles: string[];
  companyHints: ResponseData[];
  productOfApplication: ResponseProductOfApplication[];
  statusProductOfApplication: REQUEST_STATUSES;
  errorProductOfApplication: ResponseError;
  activities: ResponseActivities;
  statusActivities: REQUEST_STATUSES;
  errorActivities: ResponseError;
  statusPostAlfaBank: REQUEST_STATUSES;
  statusPostPSB: REQUEST_STATUSES;
  financailsSummaryData: FinancailsSummaryData[];
  statusExternalApiGIR_BO: REQUEST_STATUSES;
  statusFinancailsSummaryData: REQUEST_STATUSES;
  statusSendToBank: REQUEST_STATUSES;
  errorSendToBank: ResponseError;
  statusCompanyToScoring: REQUEST_STATUSES;
  allOffers: ResAllOffers[];
  statusSendApi: REQUEST_STATUSES;
  sendApiError: string;
  documentList: ResDocumentListExternal;
  statusDocumentList: REQUEST_STATUSES;
  statusFinDocUpload: REQUEST_STATUSES;
  statusNotFinDocUpload: REQUEST_STATUSES;
  statusPostDocumentsOfDocumentList: REQUEST_STATUSES;
}

interface DispatchToProps {
  setApplicationProcessMode: (
    data: SetApplicationProcessModeRequestDataType
  ) => void;
  postSelectApplication: (data: any) => void;
  sendToBank: (id: number) => void;
  postExternalApiAlfaBank: (ApplicationID: number) => void;
  postExternalApiPSB: (ApplicationID: number) => void;
  getExternalApiGIR_BO: (inn: string, year: number) => void;
  resetSentToBank: () => void;
  putSetPreapproved: (id: number) => void;
  putSetApproved: (id: number) => void;
  putRejected: (id: number, { rejectSubject, rejectReason }) => void;
  postSendCompanyToScoring: (inn: string) => void;
  getAllOffers: (id: string) => void;
  resetAllOffers: () => void;
  postOfferSend: (id: number) => void;
  resetOfferSend: () => void;
  postFinFile: (guid: string, data: FormData) => void;
  postNotFinFile: (guid: string, data: FormData) => void;
  getDownloadDocumets: (guid: string) => void;
  getDonwloadNonFinDocument: (guid: string) => void;
  postDocumentsOfDocumentList: (documentListGuid: string) => void;
  getDocumentListExternal: (id: string) => void;
  resetDocumentListExternal: () => void;
}

interface MatchParams {
  id: string;
  inBankId?: string;
}

type Props = RouteComponentProps<MatchParams> &
  IOwnProps &
  StateToProps &
  DispatchToProps;

const EachApplicationView: React.FC<Props> = ({
  setApplicationProcessMode,
  externalApplication,
  statusApplication,
  switchBankStatus,
  sendToBankStatus,
  permissions,
  roles,
  mainInfo,
  subInfo,
  documents,
  companyHints,
  productOfApplication,
  postSelectApplication,
  statusProductOfApplication,
  errorProductOfApplication,
  sendToBank,
  activities,
  statusActivities,
  errorActivities,
  postExternalApiAlfaBank,
  statusPostAlfaBank,
  postExternalApiPSB,
  statusPostPSB,
  getExternalApiGIR_BO,
  statusExternalApiGIR_BO,
  financailsSummaryData,
  statusFinancailsSummaryData,
  statusSendToBank,
  errorSendToBank,
  resetSentToBank,
  putSetPreapproved,
  putSetApproved,
  putRejected,
  postSendCompanyToScoring,
  statusCompanyToScoring,
  getAllOffers,
  resetAllOffers,
  allOffers,
  postOfferSend,
  resetOfferSend,
  statusSendApi,
  sendApiError,
  getDocumentListExternal,
  resetDocumentListExternal,
  documentList,
  statusDocumentList,
  postFinFile,
  statusFinDocUpload,
  postNotFinFile,
  statusNotFinDocUpload,
  getDonwloadNonFinDocument,
  getDownloadDocumets,
  postDocumentsOfDocumentList,
  statusPostDocumentsOfDocumentList,
  children,
  match
}: Props) => {
  const productRef = React.useRef<HTMLDivElement>(null);
  const sendApiErrorModalRef = React.useRef<HTMLDivElement>(null);
  const [productStatus, setProductStatus] = React.useState(
    productOfApplication.reduce((acc, product) => {
      acc[product.id] = product.status;
      return acc;
    }, {})
  );
  const [productId, setProductId] = React.useState(null);
  const [isRejectModal, setIsRejectModal] = React.useState(false);
  const [isSendApiError, setIsSendApiError] = React.useState(false);
  const [
    filtredFinancailsSummaryData,
    setFiltredFinancailsSummaryData
  ] = React.useState([]);
  const [isSectionProductOffers, setIsSectionProductOffers] = React.useState(
    true
  );
  const [file, setFile] = React.useState({ id: null, file: null });
  const [isSectionDocumentList, setIsSectionDocumentList] = React.useState(
    true
  );
  const [filtredInnDocuments, setFiltredInnDocuments] = React.useState<
    DocumentList[]
  >([]);
  const [filtredPersonDocuments, setFiltredPersonDocuments] = React.useState<
    PersonDocumentsType[]
  >([]);
  const [companyName, setCompanyName] = React.useState('');
  const [companyINN, setCompanyINN] = React.useState('');
  const [isRejection, setIsRejection] = React.useState(false);
  const [offerId, setOfferId] = React.useState(null);

  React.useEffect(() => {
    return () => resetDocumentListExternal();
  }, []);

  React.useEffect(
    () => {
      if (
        statusDocumentList === REQUEST_STATUSES.GOT &&
        !!documentList.items.length
      ) {
        setCompanyINN(documentList.items[0].companyINN);
        setCompanyName(documentList.items[0].companyShortName);

        const filtredInnDocuments = documentList.items.filter(item => {
          return item.companyINN && !item.personINN;
        });

        const uniquePersons = {};

        documentList.items.forEach(item => {
          const {
            personINN,
            personName,
            personComment,
            documentName,
            isMandatory,
            isUploaded,
            guid,
            documentCategory,
            documentTypeId
          } = item;

          if (personINN) {
            if (!uniquePersons[personINN]) {
              uniquePersons[personINN] = {
                personName,
                personComment,
                documents: []
              };
            }

            if (documentName) {
              uniquePersons[personINN].documents.push({
                documentName,
                isMandatory,
                isUploaded,
                guid,
                documentCategory,
                documentTypeId
              });
            }
          }
        });

        const resultArray = Object.keys(uniquePersons).map(personINN => ({
          personName: uniquePersons[personINN].personName,
          personComment: uniquePersons[personINN].personComment,
          documents: uniquePersons[personINN].documents || []
        }));

        setFiltredInnDocuments(filtredInnDocuments);
        setFiltredPersonDocuments(resultArray);
      }
    },
    [statusDocumentList]
  );

  React.useEffect(
    () => {
      if (statusPostDocumentsOfDocumentList === REQUEST_STATUSES.GOT) {
        if (match.params.inBankId) {
          getDocumentListExternal(match.params.inBankId);
        } else if (match.params.id) {
          getDocumentListExternal(match.params.id);
        }
      }
    },
    [statusPostDocumentsOfDocumentList]
  );

  React.useEffect(
    () => {
      if (statusApplication === REQUEST_STATUSES.GOT) {
        if (match.params.inBankId) {
          getDocumentListExternal(match.params.inBankId);
        } else if (match.params.id) {
          getDocumentListExternal(match.params.id);
        }

        redirectToApplication(externalApplication);
      }
    },
    [externalApplication, statusApplication]
  );

  React.useEffect(
    () => {
      if (!!financailsSummaryData.length) {
        setFiltredFinancailsSummaryData(
          financailsSummaryData.sort((a, b) => a.year - b.year)
        );
      }
    },
    [financailsSummaryData]
  );

  // React.useEffect(
  //   () => {
  //     if (match.params.id && !match.params.inBankId) {
  //       getAllOffers(match.params.id);
  //     }

  //     return () => resetAllOffers();
  //   },
  //   [match.params.id]
  // );

  // React.useEffect(
  //   () => {
  //     if (statusSendApi === REQUEST_STATUSES.GOT) {
  //       getAllOffers(match.params.id);
  //     }

  //     if (statusSendApi === REQUEST_STATUSES.ERROR) {
  //       setIsSendApiError(true);

  //       return () => resetOfferSend();
  //     }
  //   },
  //   [statusSendApi]
  // );

  const onProductClick = (id: number) => {
    window.open(`/crm/products/${id}`, '_blank');
  };

  const scrollToProduct = () => {
    if (productRef.current) {
      productRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleOutsideClick = (
    event: MouseEvent,
    targetName: React.RefObject<unknown>
  ) => {
    const target = event.target as Element;

    if (
      targetName.current &&
      !(targetName.current as Element).contains(target)
    ) {
      setIsSendApiError(false);
    }
  };

  const navigateToAddActivity = () => {
    const state = {
      inn: externalApplication.code,
      applicationCode: match.params.id
    };

    localStorage.setItem('applicationState', JSON.stringify(state));

    window.open('/crm/activities/new', '_blank');
  };

  const handleSentToBank = (e: React.MouseEvent<HTMLElement>, id: number) => {
    e.preventDefault();
    e.stopPropagation();
    sendToBank(id);
  };

  const navigateToActivities = (id: number) => {
    window.open(`/crm/activities/${id}`, '_blank');
  };

  const sendRequestsGIR_BO = (year: number) => {
    if (year < 2019) return;

    sendRequest(year)
      .then(() => sendRequestsGIR_BO(year - 1))
      .catch(() => sendRequestsGIR_BO(year - 1));
  };

  const sendRequest = (year: number) => {
    return new Promise((resolve, reject) => {
      resolve(getExternalApiGIR_BO(externalApplication.code, year));
    });
  };

  const closePopup = () => {
    resetSentToBank();
  };

  const handleOptionClick = (value: string, id: number) => {
    if (value === '1') {
      sendToBank(id);
    } else if (value === '2') {
      putSetPreapproved(id);
    } else if (value === '3') {
      putSetApproved(id);
    } else if (value === '4') {
      setIsRejectModal(true);
    }

    setProductId(id);
    setProductStatus(prevStatus => ({ ...prevStatus, [id]: value }));
  };

  function getFilteredOptions(status: number) {
    return Object.entries(SELECT_STATUS)
      .map(([key, value], index) => {
        if (status === 1 && key === '3') {
          return null;
        }
        if (status === 3 && key === '1') {
          return null;
        }
        return {
          id: (index + 1).toString(),
          name: `${key} - ${value}`
        };
      })
      .filter(option => option !== null);
  }

  const getRejectReasonMessage = (rejectCode: number) => {
    const reasons = Object.values(rejectReasons).flat();
    const matchedReason = reasons.find(reason =>
      reason.startsWith(`${rejectCode}`)
    );
    return matchedReason;
  };

  const sendCompanyToScoring = () => {
    postSendCompanyToScoring(externalApplication.code);
  };

  const filteredOffers = allOffers.filter(offer => {
    if (!isRejection && offer.offerBankStatus === 1) {
      return false;
    }
    return true;
  });

  const handleSetFile = (guid: string, file) => {
    setFile({ id: guid, file });
  };

  const postFile = (docCategory: number, guid: string) => {
    if (file) {
      const formData = new FormData();
      formData.append('guid', guid);
      formData.append('file', file.file);

      if (docCategory === 1) {
        postFinFile(match.params.id, formData);
      } else if (docCategory === 2) {
        postNotFinFile(match.params.id, formData);
      }
    }
  };

  const handleDownloadDocument = (documentCategory: number, guid: string) => {
    if (documentCategory === 1) {
      getDownloadDocumets(guid);
    } else if (documentCategory === 2) {
      getDonwloadNonFinDocument(guid);
    }
  };

  return (
    <ApplicationViewStyled>
      {/* Application modals, opens when you click on ActionButtons */}
      <ActionModal />

      <ViewSeparatorTop>
        <TransitionWrapper in={true} animationName={ANIMATION_NAMES.FADE}>
          <EditApplicationBtn
            onClick={() => {
              setApplicationProcessMode({
                mode: APPLICATION_PROCESS_MODES.EDIT
              });

              history.push(
                `/cabinet/partner/application-form-${APPLICATION_TYPE_ROUTES[
                  externalApplication.financingType
                ].replace('_', '-')}/edit/${externalApplication.id}`
              );
            }}
          >
            Редактировать заявку
          </EditApplicationBtn>
        </TransitionWrapper>
      </ViewSeparatorTop>

      {/* Change application id block.
        Visible only in if application was transfered to banks  */}
      <SwitchBanksBlock />

      {/* Application content */}
      <ContentContainer
        application={externalApplication}
        mainInfo={mainInfo}
        subInfo={subInfo}
        documents={documents}
        permissions={permissions}
        roles={roles}
        companyHints={companyHints}
        productOfApplication={productOfApplication}
        postSelectApplication={postSelectApplication}
        scrollToProduct={scrollToProduct}
        sendRequestsGIR_BO={sendRequestsGIR_BO}
        financailsSummaryData={filtredFinancailsSummaryData}
        statusFinancailsSummaryData={statusFinancailsSummaryData}
        statusExternalApiGIR_BO={statusExternalApiGIR_BO}
        documentList={documentList}
      >
        {children}
      </ContentContainer>

      {/* Application action buttons block,
        contains specific set of buttons, which changes application status */}
      <ActionButtonsBlock />

      {permissions.includes(USER_PERMISSIONS.DELEGATE_TO_INTERNAL_AGENTS) &&
        externalApplication.status ===
          APPLICATION_STATUSES.TRANSFERRED_TO_AGENT && (
          <DelegateInternalAgentToApplicationSelect
            applicationId={externalApplication.id}
          />
        )}
      {/* Application Comments, not visible in drafts */}
      <CommentTabs />

      {!!documentList.items.length && (
        <>
          <TitleTabContainer>
            <p>Список документов</p>
            <FontAwesomeIcon
              icon={isSectionDocumentList ? faChevronUp : faChevronDown}
              onClick={() => setIsSectionDocumentList(prevState => !prevState)}
            />
          </TitleTabContainer>

          {isSectionDocumentList && (
            <>
              <CompanyHead>
                <p>{companyName}</p>
                <p>ИНН: {companyINN}</p>
              </CompanyHead>

              <TableWrapper>
                <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                  <TableHeaderStyled>
                    <tr>
                      <TableThStyled width="12%">Тип документа</TableThStyled>
                      <TableThStyled width="8%">Период</TableThStyled>
                      <TableThStyled width="30%">На платформе FF</TableThStyled>
                    </tr>
                  </TableHeaderStyled>
                  <tbody>
                    {filtredInnDocuments.map(document => (
                      <TableRowStyled key={document.guid}>
                        <td>{document.documentName}</td>
                        <td>
                          <Period>
                            <p>
                              {document.taxYear && document.taxPeriodName
                                ? `${document.taxYear}, ${
                                    document.taxPeriodName
                                  }`
                                : '-'}
                            </p>
                          </Period>
                        </td>
                        <td>
                          <PlatformContainer>
                            <IsNecessarily isUploaded={document.isUploaded}>
                              {document.isMandatory && <p>*Обязательный</p>}
                            </IsNecessarily>

                            <FileContainer>
                              <IsUploadContainer
                                isUploaded={document.isUploaded}
                              >
                                {document.isUploaded ? (
                                  <div>
                                    <FontAwesomeIcon icon={faCheck} />
                                    <p>Загружен</p>
                                  </div>
                                ) : (
                                  <div>
                                    <FontAwesomeIcon icon={faTimes} />
                                    <p>Не загружен</p>
                                  </div>
                                )}
                              </IsUploadContainer>
                              {/* {!document.isUploaded ? (
                                <input
                                  type="file"
                                  onChange={e =>
                                    handleSetFile(
                                      document.guid,
                                      e.target.files[0]
                                    )
                                  }
                                />
                              ) : (
                                <DownloadFile
                                  onClick={() =>
                                    handleDownloadDocument(
                                      document.documentCategory,
                                      document.documentGuid
                                    )
                                  }
                                >
                                  Скачать
                                </DownloadFile>
                              )} */}
                              {file.id === document.guid && (
                                <ButtonContainer>
                                  <UploadButton
                                    disabled={
                                      statusFinDocUpload ===
                                      REQUEST_STATUSES.REQUEST
                                    }
                                    onClick={() =>
                                      postFile(
                                        document.documentCategory,
                                        document.guid
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon icon={faUpload} />
                                    <p>Загрузить</p>
                                  </UploadButton>
                                  {statusFinDocUpload ===
                                    REQUEST_STATUSES.REQUEST && (
                                    <SpinnerApi icon={faSpinner} />
                                  )}
                                </ButtonContainer>
                              )}
                            </FileContainer>
                          </PlatformContainer>
                        </td>
                      </TableRowStyled>
                    ))}
                  </tbody>
                </TableStyled>
              </TableWrapper>

              {filtredPersonDocuments.map((person, index) => (
                <React.Fragment key={index}>
                  <CompanyHead>
                    <p>{person.personName}</p>
                    <p>{person.personComment}</p>
                  </CompanyHead>

                  <TableWrapper>
                    <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                      <TableHeaderStyled>
                        <tr>
                          <TableThStyled width="12%">
                            Тип документа
                          </TableThStyled>
                          <TableThStyled width="25%">
                            На платформе FF
                          </TableThStyled>
                        </tr>
                      </TableHeaderStyled>
                      <tbody>
                        {person.documents.map((document, index) => (
                          <TableRowStyled key={index}>
                            <td>{document.documentName}</td>
                            <td>
                              <PlatformContainer>
                                <IsNecessarily isUploaded={document.isUploaded}>
                                  {document.isMandatory && <p>*Обязательный</p>}
                                </IsNecessarily>

                                <FileContainer>
                                  <IsUploadContainer
                                    isUploaded={document.isUploaded}
                                  >
                                    {document.isUploaded ? (
                                      <div>
                                        <FontAwesomeIcon icon={faCheck} />
                                        <p>Загружен</p>
                                      </div>
                                    ) : (
                                      <div>
                                        <FontAwesomeIcon icon={faTimes} />
                                        <p>Не загружен</p>
                                      </div>
                                    )}
                                  </IsUploadContainer>
                                  {/* {!document.isUploaded ? (
                                    <input
                                      type="file"
                                      onChange={e =>
                                        handleSetFile(
                                          document.guid,
                                          e.target.files[0]
                                        )
                                      }
                                    />
                                  ) : (
                                    <DownloadFile
                                      onClick={() =>
                                        handleDownloadDocument(
                                          document.documentCategory,
                                          document.documentGuid
                                        )
                                      }
                                    >
                                      Скачать
                                    </DownloadFile>
                                  )} */}
                                  {file.id === document.guid && (
                                    <ButtonContainer>
                                      <UploadButton
                                        disabled={
                                          statusNotFinDocUpload ===
                                          REQUEST_STATUSES.REQUEST
                                        }
                                        onClick={() =>
                                          postFile(
                                            document.documentCategory,
                                            document.guid
                                          )
                                        }
                                      >
                                        <FontAwesomeIcon icon={faUpload} />
                                        <p>Загрузить</p>
                                      </UploadButton>
                                      {statusNotFinDocUpload ===
                                        REQUEST_STATUSES.REQUEST && (
                                        <SpinnerApi icon={faSpinner} />
                                      )}
                                    </ButtonContainer>
                                  )}
                                </FileContainer>
                              </PlatformContainer>
                            </td>
                          </TableRowStyled>
                        ))}
                      </tbody>
                    </TableStyled>
                  </TableWrapper>
                </React.Fragment>
              ))}
            </>
          )}
        </>
      )}

      <ModalContainer
        visible={isSendApiError}
        onClick={e => handleOutsideClick(e, sendApiErrorModalRef)}
      >
        <PurchaseContent ref={sendApiErrorModalRef}>
          <p>{sendApiError}</p>
        </PurchaseContent>
      </ModalContainer>

      {(switchBankStatus === REQUEST_STATUSES.REQUEST ||
        sendToBankStatus === REQUEST_STATUSES.REQUEST) && (
        <LoaderContainerStyled>
          <Spinner size={100} />
        </LoaderContainerStyled>
      )}

      <ActionResponseBlock
        showIn={switchBankStatus === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ApplicationViewStyled>
  );
};

const mapStateToProps = ({
  Application,
  ExternalAgentRole,
  User,
  Leads
}: STORE) => ({
  externalApplication: ExternalAgentRole.getApplicationExternal.data,
  statusApplication: ExternalAgentRole.getApplicationExternal.status,
  companyHints: Application.getCompanyHints.data,
  switchBankStatus: Application.getInBankApplication.status,
  sendToBankStatus: Application.sendApplicationToBanks.status,
  productOfApplication: Application.getProductApplication.data,
  statusProductOfApplication: Application.getProductApplication.status,
  errorProductOfApplication: Application.getProductApplication.error,
  permissions: User.getUserData.data.permissions,
  roles: User.getUserData.data.roles,
  activities: Application.getActivitiesByApplication.data,
  statusActivities: Application.getActivitiesByApplication.status,
  errorActivities: Application.getActivitiesByApplication.error,
  statusPostAlfaBank: Application.postExternakApiAlfaBank.status,
  statusPostPSB: Application.postExternakApiPSB.status,
  financailsSummaryData: Application.getFinancialsSummary.data,
  statusFinancailsSummaryData: Application.getFinancialsSummary.status,
  statusExternalApiGIR_BO: Application.getExternalApiGIR_BO.status,
  statusSendToBank: Application.putSendToBank.status,
  errorSendToBank: Application.putSendToBank.error,
  statusCompanyToScoring: Leads.postSendCompanyToScoring.status,
  allOffers: Application.getAllOffers.data,
  statusSendApi: Application.postOfferSend.status,
  sendApiError: Application.postOfferSend.error.body,
  documentList: ExternalAgentRole.getDocumentListExternal.data,
  statusDocumentList: ExternalAgentRole.getDocumentListExternal.status,
  statusFinDocUpload: ExternalAgentRole.postFinancialDocumentExternal.status,
  statusNotFinDocUpload:
    ExternalAgentRole.postNonFinancialDocumentExternal.status,
  statusPostDocumentsOfDocumentList:
    Application.postDocumentsOfDocumentList.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setApplicationProcessMode,
      postSelectApplication,
      sendToBank,
      postExternalApiAlfaBank,
      postExternalApiPSB,
      getExternalApiGIR_BO,
      resetSentToBank,
      putSetPreapproved,
      putSetApproved,
      putRejected,
      postSendCompanyToScoring,
      getAllOffers,
      resetAllOffers,
      postOfferSend,
      resetOfferSend,
      postFinFile,
      postNotFinFile,
      getDownloadDocumets,
      getDonwloadNonFinDocument,
      postDocumentsOfDocumentList,
      getDocumentListExternal,
      resetDocumentListExternal
    },
    dispatch
  );

const EachApplicationViewConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(EachApplicationView)
);

export { EachApplicationViewConnect as EachApplicationView };
