import styled from 'styled-components';
import { gray, rr } from 'shared/styled';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  p {
    font-size: 16px;
    color: ${gray};
    margin: 0;
  }
`;

export const Title = styled.h2`
  font-size: 30px;
  color: black;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  font-family: ${rr};
  font-size: 16px;
  margin: 50px 0;

  & > div {
    display: grid;
    align-items: center;
    column-gap: 10px;
    grid-template-columns: 65% 35% 80px;

    p {
      margin: 0;

      &:nth-child(2) {
        text-align: end;
      }
    }

    &.debtor-info {
      align-items: baseline;
      grid-template-columns: 75% max-content;

      .debtor-container {
        width: fit-content;
        display: flex;
        row-gap: 5px;
        flex-direction: column;
        align-items: start;
      }
    }
  }
`;