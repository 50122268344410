import { GET_MONETARY_CLAIMS_SUPPLIES_ROLE } from 'entities/SCF/Debtor/model/reducers/getMonetaryClaimsSuppliesRole';

export interface MonataryClaim {
  auctionGuid: string;
  baseFullSumm: number;
  baseMonetaryClaimGuid: string;
  baseSumm: number;
  baseVatPecentage: number;
  baseVatSumm: number;
  cessedMonetaryClaimGuid: string;
  createdDateTime: string;
  creditorInn: string;
  creditorName: string;
  currencyCode: string;
  currencyID: number;
  emergenceDate: string;
  firstDebtorRegistryOccurence: number;
  guid: string;
  invoiceDocumentDate: string;
  invoiceDocumentName: string;
  invoiceDocumentNumber: string;
  lastDebtorRegistryOccurence: number;
  maturityDate: string;
  montaryClaimId: null;
  paidStatus: number;
  status: string;
  supplyContractNumber: string;
}

export interface ResponseDataType {
  page: number;
  pageSize: number;
  baseFullSum?: number;
  suppliersQty?: number;
  totalItems: number;
  items: MonataryClaim[];
}

export interface RequestDataType {
  page: number;
  pageSize?: number;
  supplierInn?: string;
  minBaseSum?: string;
  maxBaseSum?: string;
  StartDate?: string;
  EndDate?: string;
  status?: string;
  auction?: string;
  minMaturityDate?: string;
  maxMaturityDate?: string;
  withStats?: boolean;
  supplierIdentified?: boolean;
  allGuids?: boolean;
  sortBy?: string;
}

export const req = (data: RequestDataType) => ({
  type: GET_MONETARY_CLAIMS_SUPPLIES_ROLE.REQ,
  data
});

export const got = (data: ResponseDataType) => ({
  type: GET_MONETARY_CLAIMS_SUPPLIES_ROLE.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_MONETARY_CLAIMS_SUPPLIES_ROLE.ERR,
  error
});

export const reset = () => ({
  type: GET_MONETARY_CLAIMS_SUPPLIES_ROLE.RESET
});