import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { differenceInDays, format, isLeapYear } from 'date-fns';

import { Loader } from 'Layouts/components';
import { Calc as ResponseDataType } from 'entities/SCF/Supplier/model/actions/getMonetaryClaimsEarlyPaymentApplicationsByGuid';
import {
  req as getByMonetaryClaimHint,
  ResponseData as ResponseMonetaryClaimSupplier,
  reset as resetByMonetaryClaimHint
} from 'entities/SCF/Supplier/model/actions/getByMonetaryClaimSupplier';
import { req as postCreateAgreementSupplierRole } from 'src/features/SCF/actions/postCreateAgreementSupplierRole';
import { ResponseDataType as OneDebtorResponseDataType } from 'entities/SCF/Supplier/model/actions/getInvestorOneDebtorSupplierRoleByGuid';
import {
  ContractsPageStyled,
  BtnsBlock,
  CreateBtn,
  CreateBtnText,
  ListSection,
  FullList,
  BackBtn,
  BackBtnText,
  SelectActive,
  SelectContainerActive,
  SelectContainerInactive,
  SelectInactive,
  ConfirmSectionWide,
  ConfirmSectionCheckBox,
  ConfirmSectionRightPart,
  DiscountItem,
  TDInvoiceDocumenContainer,
  InvoiceDocumentDate,
  DocumentHint,
  SkeletonWrapper
} from '../styles';
import { STORE, REQUEST_STATUSES, ResponseError } from 'globaltypes';
import {
  TableHeaderCellStyled,
  TableStyled,
  TableHeaderCellCenteredStyled
} from 'src/features/SCF/UI/Table/styles';
import {
  TableRowStyled,
  TdCentred
} from 'src/features/SCF/UI/Table/TableRow/styles';
import { TableHeaderStyled } from 'src/features/SCF/UI/Table/TableHeader/styles';
import { formSumStringThousands } from 'src/shared/utils/Utils';

interface StateToProps {
  status: REQUEST_STATUSES;
  error: ResponseError;
  monetaryClaimsEarlyPaymentApplications: ResponseDataType[];
  investorOneDebtor: OneDebtorResponseDataType;
  monetaryClaimsHint: ResponseMonetaryClaimSupplier[];
  statusMonetaryClaimsHint: REQUEST_STATUSES;
}

interface DispatchToProps {
  getByMonetaryClaimHint: (guid: string) => void;
  resetByMonetaryClaimHint: () => void;
  postCreateAgreementSupplierRole: (guid: string) => void;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const ApplicationCalcBaseVatSum: React.FC<Props> = ({
  getByMonetaryClaimHint,
  resetByMonetaryClaimHint,
  monetaryClaimsHint,
  statusMonetaryClaimsHint,
  investorOneDebtor,
  monetaryClaimsEarlyPaymentApplications,
  status,
  postCreateAgreementSupplierRole,
  history,
  match
}) => {
  const [confirmState, setConfirmState] = React.useState(false);

  const handleConfirmStateChange = () => {
    setConfirmState(prevState => !prevState);
  };

  const onBackClick = () => {
    history.push(`/supplier/early_payment_applications?page=1`);
  };

  const onSignClick = () => {
    const { id } = match.params;
    postCreateAgreementSupplierRole(id);
    history.push(`/supplier/early_payment_applications/${id}/agreement`);
  };

  const fullRate = (): number => {
    return investorOneDebtor.baseDiscountRate * 1.2;
  };

  const DiscountCounter = monetaryClaimsEarlyPaymentApplications.reduce(
    (total, claim) => {
      return total + claim.baseFullSumm;
    },
    0
  );

  const discount = (maturityDate: string) => {
    const daysInYear = () => (isLeapYear(new Date()) ? 366 : 365);

    return (
      (fullRate() *
        differenceInDays(
          new Date(maturityDate),
          new Date(investorOneDebtor.payDate)
        )) /
      daysInYear()
    );
  };

  const amountReceived = (baseFullSumm: number, maturityDate: string) => {
    if (
      investorOneDebtor.monetaryClaimsTotalFullSum > 0 &&
      investorOneDebtor.absoluteDiscount > 0
    ) {
      return (
        investorOneDebtor.monetaryClaimsTotalFullSum -
        investorOneDebtor.absoluteDiscount
      );
    } else {
      return baseFullSumm * (1 - discount(maturityDate) / 100);
    }
  };

  const totalAmountReceived = monetaryClaimsEarlyPaymentApplications.reduce(
    (total, claim) => {
      return total + amountReceived(claim.baseFullSumm, claim.maturityDate);
    },
    0
  );

  const amountClaimsWithoutDiscount = () => {
    return investorOneDebtor.monetaryClaimsTotalFullSum > 0
      ? investorOneDebtor.monetaryClaimsTotalFullSum
      : DiscountCounter;
  };

  return (
    <ContractsPageStyled>
      <ListSection>
        <FullList>
          {status === REQUEST_STATUSES.REQUEST && <Loader />}
          {status === REQUEST_STATUSES.GOT && (
            <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
              <TableHeaderStyled>
                <tr>
                  <TableHeaderCellCenteredStyled width="8%">
                    ID
                  </TableHeaderCellCenteredStyled>
                  <TableHeaderCellCenteredStyled width="18%">
                    СУММА БЕЗ НДС
                  </TableHeaderCellCenteredStyled>
                  <TableHeaderCellCenteredStyled width="10%">
                    НДС
                  </TableHeaderCellCenteredStyled>
                  <TableHeaderCellCenteredStyled width="18%">
                    СУММА С НДС
                  </TableHeaderCellCenteredStyled>
                  <TableHeaderCellCenteredStyled width="12%">
                    ДОКУМЕНТЫ
                  </TableHeaderCellCenteredStyled>
                  <TableHeaderCellCenteredStyled width="12%">
                    ДАТА ОПЛАТЫ
                  </TableHeaderCellCenteredStyled>
                  <TableHeaderCellCenteredStyled width="20%">
                    СРОК ДИСКОНТА ДН.
                  </TableHeaderCellCenteredStyled>
                  <TableHeaderCellStyled
                    width="16%"
                    style={{ paddingTop: '14px' }}
                  >
                    СТАВКА ДИСКОНТА
                    <br />
                    %годовых
                  </TableHeaderCellStyled>
                  <TableHeaderCellCenteredStyled width="12%">
                    ДИСКОНТ
                  </TableHeaderCellCenteredStyled>
                  <TableHeaderCellCenteredStyled width="18%">
                    СУММА К ПОЛУЧЕНИЮ
                  </TableHeaderCellCenteredStyled>
                </tr>
              </TableHeaderStyled>
              <tbody>
                {monetaryClaimsEarlyPaymentApplications.map((item, key) => (
                  <TableRowStyled key={key}>
                    <TdCentred style={{ fontFamily: 'Roboto-Regular' }}>
                      {item.guid.substring(item.guid.length - 5)}
                    </TdCentred>
                    <TdCentred>
                      {item.baseSumm.toLocaleString('ru', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                    </TdCentred>
                    {item.baseVatPecentage != null ? (
                      <TdCentred>
                        {item.baseVatPecentage.toLocaleString('ru', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })}
                        %
                      </TdCentred>
                    ) : (
                      <td />
                    )}
                    <TdCentred>
                      {item.baseFullSumm.toLocaleString('ru', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                    </TdCentred>
                    <TDInvoiceDocumenContainer>
                      <div>
                        <div>
                          <p>
                            {item.invoiceDocumentName}{' '}
                            {item.invoiceDocumentNumber}
                          </p>
                        </div>
                        <InvoiceDocumentDate>
                          <p>
                            {new Date(
                              item.invoiceDocumentDate
                            ).toLocaleDateString()}
                          </p>
                          {item.invoiceDocsQty &&
                            item.invoiceDocsQty > 1 && (
                              <p
                                onMouseEnter={() =>
                                  getByMonetaryClaimHint(item.guid)
                                }
                                onMouseLeave={resetByMonetaryClaimHint}
                              >
                                и ещё {item.invoiceDocsQty - 1}
                              </p>
                            )}
                        </InvoiceDocumentDate>
                      </div>
                      {!!monetaryClaimsHint &&
                        item.invoiceDocsQty &&
                        item.invoiceDocsQty > 1 && (
                          <DocumentHint>
                            {monetaryClaimsHint.map(hint => (
                              <div key={hint.guid}>
                                <div>
                                  <div>
                                    {statusMonetaryClaimsHint ===
                                    REQUEST_STATUSES.REQUEST ? (
                                      <SkeletonWrapper
                                        width={'140px'}
                                        height={'20px'}
                                      />
                                    ) : (
                                      statusMonetaryClaimsHint ===
                                        REQUEST_STATUSES.GOT &&
                                      `${hint.documentName} ${
                                        hint.documentNumber
                                      }`
                                    )}
                                  </div>
                                  <div>
                                    {statusMonetaryClaimsHint ===
                                    REQUEST_STATUSES.REQUEST ? (
                                      <SkeletonWrapper
                                        width={'120px'}
                                        height={'20px'}
                                      />
                                    ) : (
                                      statusMonetaryClaimsHint ===
                                        REQUEST_STATUSES.GOT &&
                                      `${new Date(
                                        hint.documentDate
                                      ).toLocaleDateString()}`
                                    )}
                                  </div>
                                </div>
                                <div>
                                  {statusMonetaryClaimsHint ===
                                  REQUEST_STATUSES.REQUEST ? (
                                    <SkeletonWrapper
                                      width={'120px'}
                                      height={'20px'}
                                    />
                                  ) : (
                                    statusMonetaryClaimsHint ===
                                      REQUEST_STATUSES.GOT &&
                                    `${formSumStringThousands(
                                      hint.baseFullSumm
                                    )}`
                                  )}
                                </div>
                              </div>
                            ))}
                            <div>
                              <p>ИТОГО</p>{' '}
                              <p>{formSumStringThousands(item.baseFullSumm)}</p>
                            </div>
                          </DocumentHint>
                        )}
                    </TDInvoiceDocumenContainer>
                    <TdCentred>
                      <p
                        style={{
                          textDecoration: 'line-through',
                          color: '#9FA6B2',
                          fontSize: '12px',
                          textAlign: 'center',
                          margin: '0',
                          width: 'auto'
                        }}
                      >
                        {format(new Date(item.maturityDate), 'dd.MM.yyyy')}
                      </p>
                      {format(
                        new Date(investorOneDebtor.payDate),
                        'dd.MM.yyyy'
                      )}
                    </TdCentred>
                    <TdCentred>
                      {differenceInDays(
                        new Date(item.maturityDate),
                        new Date(investorOneDebtor.payDate)
                      )}
                    </TdCentred>

                    <td>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '5px',
                          justifyContent: 'flex-start',
                          width: 'auto',
                          margin: '5px 0'
                        }}
                      >
                        {investorOneDebtor.baseDiscountRate.toFixed(3)}%{' '}
                        <p
                          style={{
                            color: '#9FA6B2',
                            fontSize: '14px',
                            margin: '0',
                            width: 'auto'
                          }}
                        >
                          без НДС
                        </p>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '5px',
                          justifyContent: 'flex-start',
                          width: 'auto',
                          marginBottom: '5px'
                        }}
                      >
                        {formSumStringThousands(fullRate(), {
                          minFractionDigits: 3,
                          maxFractionDigits: 3
                        })}
                        %{' '}
                        <p
                          style={{
                            color: '#9FA6B2',
                            fontSize: '14px',
                            margin: '0',
                            width: 'auto'
                          }}
                        >
                          с НДС
                        </p>
                      </div>
                    </td>
                    <TdCentred>
                      {formSumStringThousands(discount(item.maturityDate), {
                        minFractionDigits: 4,
                        maxFractionDigits: 4
                      })}
                      %
                    </TdCentred>
                    <TdCentred>
                      {formSumStringThousands(
                        amountReceived(item.baseFullSumm, item.maturityDate)
                      )}
                    </TdCentred>
                  </TableRowStyled>
                ))}
              </tbody>
            </TableStyled>
          )}
        </FullList>
      </ListSection>
      {status == REQUEST_STATUSES.GOT && (
        <ConfirmSectionWide style={{ marginTop: '10px' }}>
          <ConfirmSectionRightPart>
            <DiscountItem>
              <p style={{ color: '#9FA6B2', fontSize: '14px' }}>
                Сумма требований без дисконта
              </p>
              <p
                className="discount-1"
                id="discount-1"
                style={{ color: '#9FA6B2', fontSize: '14px' }}
              >
                {formSumStringThousands(amountClaimsWithoutDiscount())} руб.
              </p>
            </DiscountItem>
            <DiscountItem>
              <p style={{ color: '#9FA6B2', fontSize: '14px' }}>
                Дисконт за раннюю оплату
              </p>
              <p style={{ color: '#9FA6B2', fontSize: '14px' }}>
                {investorOneDebtor.absoluteDiscount > 0
                  ? formSumStringThousands(investorOneDebtor.absoluteDiscount)
                  : formSumStringThousands(
                      amountClaimsWithoutDiscount() - totalAmountReceived
                    )}{' '}
                руб.
              </p>
            </DiscountItem>
            <DiscountItem>
              <p style={{ fontSize: '16px' }}>Сумма к получению</p>
              <p style={{ fontSize: '16px' }}>
                {investorOneDebtor.monetaryClaimsTotalFullSum > 0 &&
                investorOneDebtor.absoluteDiscount > 0
                  ? formSumStringThousands(
                      investorOneDebtor.monetaryClaimsTotalFullSum -
                        investorOneDebtor.absoluteDiscount
                    )
                  : formSumStringThousands(totalAmountReceived)}{' '}
                руб.
              </p>
            </DiscountItem>
          </ConfirmSectionRightPart>
        </ConfirmSectionWide>
      )}
      <ConfirmSectionWide>
        <ConfirmSectionRightPart>
          <ConfirmSectionCheckBox>
            {confirmState === false ? (
              <SelectContainerInactive onClick={handleConfirmStateChange}>
                <SelectInactive />
              </SelectContainerInactive>
            ) : (
              <SelectContainerActive onClick={handleConfirmStateChange}>
                <SelectActive />
              </SelectContainerActive>
            )}
            <p>Выражаю согласие с условиями дисконтирования оплаты</p>
          </ConfirmSectionCheckBox>
          <BtnsBlock>
            <BackBtn onClick={onBackClick}>
              <BackBtnText>Назад</BackBtnText>
            </BackBtn>
            {confirmState === false ? (
              <CreateBtn
                style={{
                  backgroundColor: '#9FA6B2',
                  cursor: 'not-allowed'
                }}
              >
                <CreateBtnText>Далее</CreateBtnText>
              </CreateBtn>
            ) : (
              <CreateBtn onClick={onSignClick}>
                <CreateBtnText>Далее</CreateBtnText>
              </CreateBtn>
            )}
          </BtnsBlock>
        </ConfirmSectionRightPart>
      </ConfirmSectionWide>
    </ContractsPageStyled>
  );
};

const mapStateToProps = ({ SCFSupplier, SCFDebtor }: STORE) => ({
  monetaryClaimsEarlyPaymentApplications:
    SCFSupplier.getMonetaryClaimsEarlyPaymentApplicationsByGuid.data,
  status: SCFSupplier.getMonetaryClaimsEarlyPaymentApplicationsByGuid.status,
  error: SCFSupplier.getMonetaryClaimsEarlyPaymentApplicationsByGuid.error,
  investorOneDebtor: SCFSupplier.getInvestorOneDebtorSupplierRoleByGuid.data,
  monetaryClaimsHint: SCFDebtor.getByMonetaryClaimDebtor.data,
  statusMonetaryClaimsHint: SCFDebtor.getByMonetaryClaimDebtor.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getByMonetaryClaimHint,
      resetByMonetaryClaimHint,
      postCreateAgreementSupplierRole
    },
    dispatch
  );

const ApplicationCalcBaseVatSumConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ApplicationCalcBaseVatSum)
);

export { ApplicationCalcBaseVatSumConnect as ApplicationCalcBaseVatSum };