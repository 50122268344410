import * as React from 'react';
import { Route, Switch } from 'react-router';

import { DebtorView } from 'pages/Cabinet/InternalAgent/Pages/Debtors/Debtors';
import { SuppliersView } from 'pages/Cabinet/InternalAgent/Pages/Suppliers/Suppliers';
import { FactorView } from 'pages/Cabinet/InternalAgent/Pages/Factors/Factors';
import { SupplyContracts } from 'src/features/SupplyContracts/components/InternalSupplyView/InternalSupplyView';
import {
  BarSection,
  Registries,
  Supplies,
  ContractSupplies,
  DocumentForms
} from 'pages/SCF/Internal/components';
import { EarlyPaymentApplications } from 'src/features/EarlyPaymentApplications/components/EarlyPaymentApplications';
import { InternalCabinetStyled } from './styled';
import { CabinetSection } from 'shared/styled';
import { Debtors } from './Debtors/Debtors';
import { SupplierList } from './Suppliers/Suppliers';
import { Factors } from './Factors/Factors';
import { BuyersToSupplierLink } from './BuyersToSupplierLink/BuyersToSupplierLink';
import { EarlyPaymentApplicationView } from 'src/features/EarlyPaymentApplications/components/EarlyPaymentApplicationView';

const InternalCabinet: React.FC = () => {
  return (
    <InternalCabinetStyled>
      <BarSection />

      <CabinetSection>
        <Switch>
          <Route
            exact={true}
            path="/internal/registries"
            component={Registries}
          />

          <Route
            exact={true}
            path="/internal/monetary_claims"
            component={Supplies}
          />

          <Route
            exact={true}
            path="/internal/supply_contracts"
            component={ContractSupplies}
          />

          <Route
            exact={true}
            path="/internal/document_forms"
            component={DocumentForms}
          />

          <Route
            exact={true}
            path="/internal/early_payment_applications"
            component={EarlyPaymentApplications}
          />

          <Route
            exact={true}
            path="/internal/early_payment_applications/:guid"
            component={EarlyPaymentApplicationView}
          />

          <Route exact={true} path="/internal/debtors" component={Debtors} />

          <Route
            exact={true}
            path="/internal/debtors/:inn/:tab?"
            component={DebtorView}
          />

          <Route
            exact={true}
            path="/internal/suppliers"
            component={SupplierList}
          />

          <Route
            exact={true}
            path="/internal/suppliers/:inn/:tab?"
            component={SuppliersView}
          />

          <Route exact={true} path="/internal/factors" component={Factors} />

          <Route
            exact={true}
            path="/internal/factors/:inn/:tab?"
            component={FactorView}
          />

          <Route
            exact={true}
            path="/internal/supply_contracts/:guid"
            component={SupplyContracts}
          />

          <Route
            exact={true}
            path="/internal/buyers_to_suppliers_links"
            component={BuyersToSupplierLink}
          />
        </Switch>
      </CabinetSection>
    </InternalCabinetStyled>
  );
};

export { InternalCabinet };