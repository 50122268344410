import * as React from 'react';
import { Route, Switch } from 'react-router';
import { BarSection } from './BarSection/BarSection';
import RegistriesPage from './Registries/Registries';
import SettingsPage from '../../../../features/SCF/components/Settings/Settings';
import { SuppliersPage } from './Suppliers/Suppliers';
import { SuppliesList } from './Supplies/Supplies';
import { SupplierInfoPage } from './Suppliers/SupplierInfo/SupplierInfo';
import { SuppliersAddPage } from './AddSuppliers/AddSuppliers';
import PurchaseContractsPage from './PurchaseContracts/PurchaseContracts';
import DebtorFactoringContractsPage from './FactoringContracts/FactoringContracts';
import DiscountApplicationsPage from './DiscountApplications/DiscountApplications';
import MainPage from './MainPage/MainPage';
import { CabinetStyled } from './styles';
import DiscountAuctions from './DiscountAuctions/DiscountAuctions';

const DebtorCabinet: React.FC = () => (
  <CabinetStyled>
    <BarSection />
    <Switch>
      <Route exact path={'/debtor'} render={() => <MainPage />} />
      <Route path={'/debtor/settings'} render={() => <SettingsPage />} />
      <Route path={'/debtor/registries'} render={() => <RegistriesPage />} />
      <Route
        exact
        path={'/debtor/suppliers'}
        render={() => <SuppliersPage />}
      />
      <Route
        exact
        path={'/debtor/suppliers/add'}
        render={() => <SuppliersAddPage />}
      />
      <Route
        exact
        path={'/debtor/suppliers/:id/:tab?'}
        render={() => <SupplierInfoPage />}
      />
      <Route
        path={'/debtor/purchase_contracts'}
        render={() => <PurchaseContractsPage />}
      />
      <Route
        path={'/debtor/factoring_contracts'}
        render={() => <DebtorFactoringContractsPage />}
      />
      <Route
        path={'/debtor/discount_applications'}
        render={() => <DiscountApplicationsPage />}
      />
      <Route path={'/debtor/supplies'} render={() => <SuppliesList />} />
      <Route
        path={'/debtor/discount_auctions'}
        render={() => <DiscountAuctions />}
      />
    </Switch>
  </CabinetStyled>
);

export { DebtorCabinet };
