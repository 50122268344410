import styled from 'styled-components';

import { rr, black } from 'shared/styled';

export const WarningBlockStyled = styled.div`
  width: 100%;

  margin: 48px 0;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
`;

export const WarningTextBlock = styled.div`
  margin-left: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
`;

export const WarningText = styled.p`
  margin: 0;
  font-family: ${rr};
  font-size: 1.6rem;
  line-height: 1.6;
  color: ${black};
`;
