import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExternalLinkSquareAlt,
  faSortAmountDownAlt,
  faSortAmountUpAlt,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import { REQUEST_STATUSES } from 'src/globaltypes';
import { ResponseDataType as MonetaryClaimRes } from 'entities/SCF/Debtor/model/actions/getMonetaryClaimsSuppliesRole';
import { ResponseData as MonetaryClaimInternalAgent } from 'entities/SCF/Internal/model/actions/getMonetaryClaimsInternalAgent';
import { Loader, Pagination } from 'src/features/Layouts/components';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import { ResponseData as ResponseMonetaryClaimHint } from 'entities/SCF/Debtor/model/actions/getByMonetaryClaimDebtor';
import { formSumStringThousands } from 'src/shared/utils/Utils';
import {
  DocumentHint,
  TDInvoiceDocumenContainer,
  NoData,
  NoDataSummContainer,
  Status,
  SummContainer,
  SummDataContainer,
  SupplierContainer,
  SkeletonWrapper,
  TooltipBoxStyle,
  TDSupplier,
  SupplyContract,
  InvoiceDocumentDate,
  StatusContainer,
  StatusAuction,
  TDSupplyContract,
  SupplyContractNumber,
  SortContainer
} from './styled';
import { navyBlue } from 'src/shared/styled';

interface Props {
  monetaryClaims: MonetaryClaimRes | MonetaryClaimInternalAgent;
  status: REQUEST_STATUSES;
  type: Array<'internal' | 'debtor' | 'scf'>;
  getByMonetaryClaimHint?: (guid: string) => void;
  monetaryClaimsHint?: ResponseMonetaryClaimHint[];
  resetByMonetaryClaimHint?: () => void;
  statusMonetaryClaimsHint?: REQUEST_STATUSES;
  noData: { title: string; text: string };
  putMonetaryClaimDeleteToAuction?: (
    guid: string,
    auction_guid: string
  ) => void;
  isSummSort: boolean;
  handleSortedSumm: React.Dispatch<React.SetStateAction<boolean>>;
  isMaturityDate: boolean;
  handleSortedMaturityDate: React.Dispatch<React.SetStateAction<boolean>>;
}

const MonetaryClaims: React.FC<Props> = ({
  monetaryClaims,
  status,
  getByMonetaryClaimHint,
  monetaryClaimsHint,
  statusMonetaryClaimsHint,
  resetByMonetaryClaimHint,
  putMonetaryClaimDeleteToAuction,
  type,
  noData,
  isSummSort,
  handleSortedSumm,
  isMaturityDate,
  handleSortedMaturityDate
}) => {
  const [isHovered, setIsHovered] = React.useState({ id: null, select: false });

  function isEqual(a, b) {
    return Math.abs(a - b) <= 0.03;
  }

  const navigateToSupplier = (inn: string) => {
    if (type.includes('internal')) {
      window.open(`/internal/suppliers/${inn}`, '_blank');
    } else {
      window.open(`/debtor/suppliers/${inn}`, '_blank');
    }
  };

  const navigateToDebtor = (inn: string) => {
    if (type.includes('internal')) {
      window.open(`/internal/debtors/${inn}`, '_blank');
    }
  };

  const navigateToPurchaseContracts = (guid: string) => {
    window.open(`/debtor/purchase_contracts/${guid}`, '_blank');
  };

  const navigateToDiscountAuctionsMonetaryClaims = (auction_guid: string) => {
    window.open(
      `/debtor/discount_auctions/${auction_guid}/monetary_claims`,
      '_blank'
    );
  };

  const navigateToSupplyContract = (guid: string) => {
    window.open(`/internal/supply_contracts/${guid}`, '_blank');
  };

  return (
    <>
      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT &&
        !!monetaryClaims.items.length && (
          <>
            <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
              <TableHeaderStyled>
                <tr>
                  <TableThStyled width="5%">ID</TableThStyled>
                  <TableThStyled width="8%">Поставщик</TableThStyled>
                  {type.includes('internal') && (
                    <TableThStyled width="10%">Дебитор</TableThStyled>
                  )}
                  <TableThStyled width="10%" style={{ textAlign: 'center' }}>
                    Договор
                  </TableThStyled>
                  <TableThStyled width="15%">
                    <SortContainer>
                      <div>Сумма к оплате</div>
                      <FontAwesomeIcon
                        icon={
                          isSummSort ? faSortAmountUpAlt : faSortAmountDownAlt
                        }
                        color={isSummSort !== null && navyBlue}
                        onClick={() => {
                          handleSortedMaturityDate(null);
                          handleSortedSumm(prevState => !prevState);
                        }}
                      />
                    </SortContainer>
                  </TableThStyled>
                  <TableThStyled width="6%">Дата счета</TableThStyled>
                  <TableThStyled width="6%">
                    <SortContainer>
                      <div>Дата оплаты</div>
                      <FontAwesomeIcon
                        icon={
                          isMaturityDate
                            ? faSortAmountUpAlt
                            : faSortAmountDownAlt
                        }
                        color={isMaturityDate !== null && navyBlue}
                        onClick={() => {
                          handleSortedSumm(null);
                          handleSortedMaturityDate(prevState => !prevState);
                        }}
                      />
                    </SortContainer>
                  </TableThStyled>
                  <TableThStyled width="12%">
                    Закрывающие документы
                  </TableThStyled>
                  <TableThStyled width="16%">Статус</TableThStyled>
                </tr>
              </TableHeaderStyled>
              <tbody>
                {monetaryClaims.items.map(claim => (
                  <TableRowStyled key={claim.guid}>
                    <td>{claim.guid.slice(-6)}</td>
                    <TDSupplier>
                      <SupplierContainer>
                        <div>
                          <p>{claim.creditorInn}</p>
                          {claim.creditorInn &&
                            claim.creditorInn !== 'XXXXXXXXXXXX' && (
                              <TooltipBoxStyle
                                textVerify={'Открыть в новом окне'}
                              >
                                <FontAwesomeIcon
                                  icon={faExternalLinkSquareAlt}
                                  onClick={() =>
                                    navigateToSupplier(claim.creditorInn)
                                  }
                                />
                              </TooltipBoxStyle>
                            )}
                        </div>
                        <p>{claim.creditorName}</p>
                      </SupplierContainer>
                    </TDSupplier>
                    {type.includes('internal') && (
                      <TDSupplier>
                        <SupplierContainer>
                          <div>
                            <p>{claim.debtorInn}</p>
                            {!!claim.debtorInn && (
                              <TooltipBoxStyle
                                textVerify={'Открыть в новом окне'}
                              >
                                <FontAwesomeIcon
                                  icon={faExternalLinkSquareAlt}
                                  onClick={() =>
                                    navigateToDebtor(claim.debtorInn)
                                  }
                                />
                              </TooltipBoxStyle>
                            )}
                          </div>
                          <p>{claim.debtorName}</p>
                        </SupplierContainer>
                      </TDSupplier>
                    )}
                    <TDSupplyContract>
                      {claim.supplyContractGuid ? (
                        <SupplyContract>
                          {claim.supplyContractNumber}
                          {type.includes('internal') && (
                            <TooltipBoxStyle
                              textVerify={'Открыть в новом окне'}
                            >
                              <FontAwesomeIcon
                                icon={faExternalLinkSquareAlt}
                                onClick={() =>
                                  navigateToSupplyContract(
                                    claim.supplyContractGuid
                                  )
                                }
                              />
                            </TooltipBoxStyle>
                          )}
                          {!type.includes('internal') && (
                            <TooltipBoxStyle
                              textVerify={'Открыть в новом окне'}
                            >
                              <FontAwesomeIcon
                                icon={faExternalLinkSquareAlt}
                                onClick={() =>
                                  navigateToPurchaseContracts(
                                    claim.supplyContractGuid
                                  )
                                }
                              />
                            </TooltipBoxStyle>
                          )}
                        </SupplyContract>
                      ) : (
                        <SupplyContractNumber>
                          <p>{claim.supplyContractNumber}</p>
                          {type.includes('internal') && (
                            <p>не удалось связать с контрактом в базе</p>
                          )}
                        </SupplyContractNumber>
                      )}
                    </TDSupplyContract>
                    <td>
                      <SummContainer>
                        <div>{formSumStringThousands(claim.baseFullSumm)}</div>

                        {isEqual(
                          claim.baseVatSumm + claim.baseSumm,
                          claim.baseFullSumm
                        ) ? (
                          <SummDataContainer>
                            <div>
                              <p>{formSumStringThousands(claim.baseSumm)}</p>{' '}
                              <p>без НДС</p>
                            </div>
                            <div>
                              <p>{formSumStringThousands(claim.baseVatSumm)}</p>{' '}
                              <p>НДС</p>
                            </div>
                          </SummDataContainer>
                        ) : (
                          <NoDataSummContainer>
                            <div>
                              <p>нет данных</p> <p>без НДС</p>
                            </div>
                            <div>
                              <p>нет данных</p> <p>НДС</p>
                            </div>
                          </NoDataSummContainer>
                        )}
                      </SummContainer>
                    </td>
                    <td>
                      {new Date(claim.emergenceDate).toLocaleDateString()}
                    </td>
                    <td>{new Date(claim.maturityDate).toLocaleDateString()}</td>
                    <TDInvoiceDocumenContainer>
                      <div>
                        <div>
                          {claim.invoiceDocumentName}{' '}
                          {claim.invoiceDocumentNumber}
                        </div>
                        <InvoiceDocumentDate>
                          <p>
                            {new Date(
                              claim.invoiceDocumentDate
                            ).toLocaleDateString()}
                          </p>
                          {claim.invoiceDocsQty &&
                            claim.invoiceDocsQty > 1 && (
                              <p
                                onMouseEnter={() =>
                                  type.includes('scf') &&
                                  getByMonetaryClaimHint(claim.guid)
                                }
                                onMouseLeave={resetByMonetaryClaimHint}
                              >
                                и ещё {claim.invoiceDocsQty - 1}
                              </p>
                            )}
                        </InvoiceDocumentDate>
                      </div>
                      {type.includes('scf') &&
                        !!monetaryClaimsHint &&
                        claim.invoiceDocsQty &&
                        claim.invoiceDocsQty > 1 && (
                          <DocumentHint>
                            {!!monetaryClaimsHint &&
                              monetaryClaimsHint.map(hint => (
                                <div key={hint.guid}>
                                  <div>
                                    <div>
                                      {statusMonetaryClaimsHint ===
                                      REQUEST_STATUSES.REQUEST ? (
                                        <SkeletonWrapper
                                          width={'140px'}
                                          height={'20px'}
                                        />
                                      ) : (
                                        statusMonetaryClaimsHint ===
                                          REQUEST_STATUSES.GOT &&
                                        `${hint.documentName} ${
                                          hint.documentNumber
                                        }`
                                      )}
                                    </div>
                                    <div>
                                      {statusMonetaryClaimsHint ===
                                      REQUEST_STATUSES.REQUEST ? (
                                        <SkeletonWrapper
                                          width={'120px'}
                                          height={'20px'}
                                        />
                                      ) : (
                                        statusMonetaryClaimsHint ===
                                          REQUEST_STATUSES.GOT &&
                                        `${new Date(
                                          hint.documentDate
                                        ).toLocaleDateString()}`
                                      )}
                                    </div>
                                  </div>
                                  <div>
                                    {statusMonetaryClaimsHint ===
                                    REQUEST_STATUSES.REQUEST ? (
                                      <SkeletonWrapper
                                        width={'120px'}
                                        height={'20px'}
                                      />
                                    ) : (
                                      statusMonetaryClaimsHint ===
                                        REQUEST_STATUSES.GOT &&
                                      `${formSumStringThousands(
                                        hint.baseFullSumm
                                      )}`
                                    )}
                                  </div>
                                </div>
                              ))}
                            <div>
                              <p>ИТОГО</p>{' '}
                              <p>
                                {formSumStringThousands(claim.baseFullSumm)}
                              </p>
                            </div>
                          </DocumentHint>
                        )}
                    </TDInvoiceDocumenContainer>
                    <td>
                      <StatusContainer>
                        <Status status={claim.paidStatus}>
                          {claim.paidStatus === 10
                            ? 'Ждет оплаты в срок'
                            : claim.paidStatus === 11
                              ? 'Выбрана поставщиком к ранней оплате'
                              : claim.paidStatus === 20
                                ? 'Оплачена'
                                : claim.paidStatus === 15
                                  ? 'Недоступна'
                                  : 'Статус неизвестен'}
                        </Status>
                        {type.includes('debtor') &&
                          claim.auctionGuid && (
                            <StatusAuction
                              onMouseEnter={() =>
                                setIsHovered({ id: claim.guid, select: true })
                              }
                              onMouseLeave={() =>
                                setIsHovered({ id: null, select: false })
                              }
                            >
                              <p>Аукцион</p>
                              <TooltipBoxStyle
                                textVerify={'Открыть в новом окне'}
                              >
                                <FontAwesomeIcon
                                  icon={faExternalLinkSquareAlt}
                                  onClick={() =>
                                    navigateToDiscountAuctionsMonetaryClaims(
                                      claim.auctionGuid
                                    )
                                  }
                                />
                              </TooltipBoxStyle>
                              {isHovered.id === claim.guid &&
                                isHovered.select && (
                                  <TooltipBoxStyle
                                    textVerify={'Удалить из аукциона'}
                                  >
                                    <FontAwesomeIcon
                                      icon={faTimes}
                                      onClick={() =>
                                        putMonetaryClaimDeleteToAuction(
                                          claim.guid,
                                          claim.auctionGuid
                                        )
                                      }
                                    />
                                  </TooltipBoxStyle>
                                )}
                            </StatusAuction>
                          )}
                      </StatusContainer>
                    </td>
                  </TableRowStyled>
                ))}
              </tbody>
            </TableStyled>

            <Pagination list={monetaryClaims} />
          </>
        )}

      {monetaryClaims.items.length === 0 && (
        <NoData>
          <div>
            <h2>{noData.title}</h2>
            <p>{noData.text}</p>
          </div>
        </NoData>
      )}
    </>
  );
};

export default MonetaryClaims;