import styled from 'styled-components';
import { HeaderHeight } from 'Layouts/components/Header/styles';
import { gray, rr, blue, darkBlue, red } from 'shared/styled';
import { ButtonStyled } from 'shared/ui/Button/styled';

export const SCFPageStyled = styled.section`
  width: 100%;
  height: 100vh;
`;

export const PageStyled = styled.section`
  margin-top: ${HeaderHeight};
  width: 80vw;
  height: auto;
  margin-left: 50px;
  padding-top: 50px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h1 {
    font-family: ${rr};
    font-size: 1.3em;
  }
  h2 {
    font-family: ${rr};
    font-weight: 400;
  }
`;

export const PageNoMarginStyled = styled.section`
  width: auto;
  height: auto;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h1 {
    font-family: ${rr};
    font-size: 1.3em;
  }
`;

export const PageHeaderContainer = styled.section`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h1 {
    font-family: ${rr};
    font-size: 1.7em;
    margin-bottom: 25px;
  }
`;

export const BtnsContainer = styled.div`
  width: 800px;
  display: flex;
  align-items: start;
  justify-content: space-between;
`;

export const BtnSubBlock = styled.div`
  display: flex;
  align-items: start;
  gap: 20px;
`;

export const BackBtn = styled.div`
  cursor: pointer;
  border-radius: 5px;
  background-color: #d0e9ff;
  transition: 0.2s;
  &:hover {
    background-color: #c0ecff;
  }
`;

export const BackBtnText = styled.p`
  font-family: ${rr};
  font-size: 1.1em;
  padding: 8px 24px;
  color: #3f4e65;
  margin: 0;
  text-decoration: none;
`;

export const AddBtn = styled.div`
  margin-top: 40px;
  padding-left: 15px;
  width: 190px;
  height: auto;
  display: block;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${blue};
  transition: 0.2s;
  &:hover {
    background-color: rgba(1, 96, 182, 1);
  }
`;

export const AddBtnText = styled.p`
  font-family: ${rr};
  font-weight: 600;
  font-size: 1.1em;
  padding: 10px 10px;
  color: #ffffff;
`;

export const CreateBtn = styled.button`
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: ${blue};
  transition: 0.2s;
  &:hover {
    background-color: rgba(1, 96, 182, 1);
  }
`;

export const CreateBtnText = styled.p`
  font-family: ${rr};
  font-weight: 600;
  font-size: 1.1em;
  padding: 5px 10px;
  margin: 0;
  color: #ffffff;
`;

export const CreateBtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 150px;

  & > ${CreateBtn} {
    background-color: ${({ changed }) => (changed ? 'red' : '')};
  }

  & > p {
    color: ${({ changed }) => (changed ? 'red' : '')};
    text-align: center;
  }
`;

export const DisabledBtn = styled.div`
  border-radius: 5px;
  background-color: ${gray};
  transition: 0.2s;
  cursor: not-allowed;
  p {
    font-family: ${rr};
    font-size: 1.1em;
    padding: 5px 10px;
    margin: 0;
    color: white;
  }
`;

export const CertificatesPopup = styled.section`
  position: fixed;
  top: 0;
  margin-top: 190px;
  width: 70vw;
  min-height: 300px;
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
  transition: all 0.8s ease 0s;
  flex-direction: column;
  background-color: #fafafa;
  border-radius: 10px;
  border: 1px solid rgba(159, 166, 178, 0.3);

  h1 {
    font-family: ${rr};
    font-size: 1.6em;
    margin-left: 30px;
    margin-bottom: 20px;
  }
`;

export const CertificatesPopupDisplay = styled.section`
  position: fixed;
  top: 0;
  margin-top: 190px;
  width: 70vw;
  min-height: 300px;
  display: flex;
  transition: all 0.8s ease 0s;
  flex-direction: column;
  background-color: #fafafa;
  border-radius: 10px;
  border: 1px solid rgba(159, 166, 178, 0.3);
  h1 {
    font-family: ${rr};
    font-size: 1.6em;
    margin-left: 30px;
    margin-bottom: 20px;
  }
`;

export const PopupExit = styled.div`
  font-family: ${rr};
  width: 70vw;
  display: flex;
  justify-content: flex-end;

  p {
    padding: 10px 30px;
    font-family: ${rr};
    color: rgba(63, 78, 101, 1);
    font-size: 1.8em;
    cursor: pointer;
  }
`;

export const CertificatesPopupError = styled.section`
  position: fixed;
  top: 0;
  margin-left: 15vw;
  margin-top: 240px;
  width: 40vw;
  min-height: 100px;
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
  transition: all 0.8s ease 0s;

  flex-direction: column;
  background-color: #fafafa;
  border-radius: 10px;
  border: 1px solid rgba(159, 166, 178, 0.3);
  h1 {
    font-family: ${rr};
    color: #ff0000;
    font-size: 1.2em;
    line-height: 200%;
    width: 90%;
    margin-bottom: 50px;
    margin-left: 30px;
    padding-right: 30px;
  }
`;

export const PopupExitError = styled.div`
  font-family: ${rr};
  width: 40vw;
  display: flex;
  justify-content: flex-end;

  p {
    padding: 10px 30px;
    font-family: ${rr};
    color: rgba(63, 78, 101, 1);
    font-size: 1.8em;
    cursor: pointer;
  }
`;

export const NudgeArea = styled.div`
  width: 70vw;
  height: auto;
  display: flex;
  justify-content: flex-start;
  margin-left: 30px;
  margin-top: 20px;
  font-family: ${rr};
  margin-bottom: 30px;
  p {
    font-family: ${rr};
    font-size: 1.3em;
    margin-right: 30px;
  }
`;

export const Nudge = styled.p`
  font-family: ${rr};
  color: ${blue};
  display: flex;
  justify-content: center;
  transition: 0.2s;
  cursor: pointer;
  font-size: 1.3em;
  &:hover {
    color: rgba(1, 96, 182, 1);
  }
`;

export const CertificatesArea = styled.div`
  width: 65vw;
  height: auto;
  display: flex;
  justify-content: center;
  margin-left: 30px;
  margin-top: 20px;
  font-family: ${rr};
  margin-bottom: 30px;
`;

export const AcceptButton = styled(ButtonStyled)`
  width: fit-content;
  height: 40px;
  background-color: ${({ confirmedByDebtor }) =>
    !confirmedByDebtor && '#1CCA94'};
`;

export const DeclineButton = styled(ButtonStyled)`
  width: fit-content;
  height: 40px;
  background-color: ${({ confirmedByDebtor }) => !confirmedByDebtor && red};
`;

export const AcceptContract = styled.div`
  display: flex;
  align-items: center;
  column-gap: 15px;

  button {
    width: max-content;
  }
`;

export const BtnsBlock = styled.div`
  display: flex;
  align-items: end;
  flex-direction: column;
  gap: 15px;
`;

export const BtnsBlockSignBtn = styled.div`
  width: 240px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
  font-family: ${rr};
  border-radius: 5px;
  background-color: ${blue};
  transition: 0.3s;
  &:hover {
    background-color: ${darkBlue};
  }
`;

export const BtnsBlockDownloadDocument = styled.a`
  width: 240px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #3f4e65;
  font-family: ${rr};
  border-radius: 5px;
  background-color: #d0e9ff;
  transition: 0.2s;
  text-decoration: none;
  &:hover {
    background-color: #c0ecff;
  }
`;

export const InactiveButton = styled(BtnsBlockDownloadDocument)`
  background-color: rgba(228, 228, 228, 1);
  cursor: default;
  &:hover {
    background-color: rgba(228, 228, 228, 1);
  }
`;

export const BtnsBlockDownloadSignature = styled.a`
  width: 240px;
  min-height: 36px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  color: #3f4e65;
  font-family: ${rr};
  border-radius: 5px;
  background-color: #d0e9ff;
  transition: 0.2s;
  &:hover {
    background-color: #c0ecff;
  }
`;

// export const BtnsBlockDownloadSignatureTopContainer = styled.a`
//     width: 100%;
//     height: 36px;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     text-decoration: none;
//     p {
//       margin-left: 10px;
//     }
// `;

export const BtnsBlockDownloadSignatureTopContainer = styled.a`
  width: 100%;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
`;

export const BtnsBlockDownloadSignatureSubInfo = styled.div`
  display: none;
  width: 100%;
  height: auto;
  padding: 10px 10px 10px;
  line-height: 150%;
  color: #3f4e65;
  transition: all 0.3s ease;
  cursor: auto;
`;

export const CreateLimitsNudge = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 20px;

  h2 {
    font-family: ${rr};
    font-weight: 400;
  }
`;

export const PaginationWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  height: auto;
`;

export const CaretIcon = styled.div`
  display: flex;
  align-items: center;
  height: auto;
  width: 18px;
  height: 18px;
  padding: 10px;
  margin-right: 10px;
  background-size: contain;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.3s;
  rotate: 180deg;
  z-index: 100;
  background-image: url('data:image/svg+xml,
  <svg viewBox="0 0 20 12" xmlns="http://www.w3.org/2000/svg">
  <path fill="rgb(50, 50, 50)" d="M0 10C0 10.3385 0.123698 10.6315 0.371094 10.8789C0.61849 11.1263 0.911458 11.25 1.25 11.25H18.75C19.0885 11.25 19.3815 11.1263 19.6289 10.8789C19.8763 10.6315 20 10.3385 20 10C20 9.66146 19.8763 9.36849 19.6289 9.12109L10.8789 0.371094C10.6315 0.123698 10.3385 0 10 0C9.66146 0 9.36849 0.123698 9.12109 0.371094L0.371094 9.12109C0.123698 9.36849 0 9.66146 0 10Z" />
  </svg>
  ');
`;
