import styled from 'styled-components';
import { blue, gray, lighterBlue, rr } from 'shared/styled';

export const MonetaryClaimsTotalFullSum = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;

  & > div:first-of-type {
    font-size: 13px;
    text-decoration: line-through;
    color: ${gray};
  }
`;

export const AbsoluteDiscountRate = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;

  & > div {
    display: grid;
    align-items: center;
    grid-template-columns: auto 10px;
    column-gap: 5px;
    text-align: end;
    width: 60%;

    p {
      margin: 0;
    }
  }
`;

export const BaseDiscountRate = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;

  & > div {
    display: flex;
    align-items: center;
    column-gap: 10px;

    & > p {
      color: ${gray};
      margin: 0;
    }

    & > div {
      display: grid;
      align-items: center;
      grid-template-columns: auto 10px;
      width: 23%;

      p {
        text-align: start;
        margin: 0;
      }
    }
  }
`;

export const DiscountSortContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;

  & > div:last-of-type {
    display: flex;
    flex-direction: column;
    row-gap: 5px;

    div {
      display: grid;
      grid-template-columns: 80px auto;
      align-items: center;
      font-size: 10px;

      & > svg {
        cursor: pointer;
        color: ${({ color }) => color};
      }
    }
  }
`;

export const DiscountRateSortContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;

  & > div:last-of-type {
    display: flex;
    flex-direction: column;
    row-gap: 5px;

    div {
      display: grid;
      grid-template-columns: 80px auto;
      align-items: center;
      font-size: 10px;

      & > svg {
        cursor: pointer;
      }
    }
  }
`;

export const CheckContainer = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  background-color: rgba(28, 202, 148, 1);
  cursor: pointer;
  transition: 0.3s;
  margin-right: 10px;
  &:hover {
    background-color: #3de394;
  }
`;

export const DeclineContainer = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  background-color: rgba(245, 55, 55, 1);
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background-color: #b33737;
  }
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;

  & > div:first-of-type {
    height: 30px;
  }

  & > div {
    display: flex;
    align-items: center;
  }
`;

export const SupplierInn = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;

  & > div:first-of-type {
    color: ${gray};
  }
`;

export const FirstBasePaymentDate = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;

  & > div:first-of-type {
    font-size: 13px;
    text-decoration: line-through;
    color: ${gray};
  }
`;

export const GotBtn = styled.div`
  max-width: 150px;
  height: auto;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  background-color: #d0e9ff;
`;

export const GotBtnText = styled.p`
  font-family: ${rr};
  font-weight: 500;
  font-size: 0.9em;
  padding: 5px 10px;
  margin: 0;
  color: #3f4e65;
`;

export const DateFilterStyled = styled.div`
  width: 600px;
  margin-bottom: 30px;
  padding: 16px 20px 20px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  background-color: ${lighterBlue};
  p {
    font-family: ${rr};
    margin-right: 10px;
  }
`;

export const DatesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-right: 0px;
  p {
    font-family: ${rr};
    margin-right: 10px;
  }
`;

export const SignBtns = styled.div`
  width: 210px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SignBtn = styled.div`
  width: 100px;
  height: 25px;
  display: flex;

  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 17px;
  background-color: ${blue};
  transition: 0.2s;
  margin-bottom: 12px;
  &:hover {
    background-color: rgba(1, 96, 182, 1);
  }
`;

export const SignBtnText = styled.p`
  font-family: ${rr};
  font-weight: 500;
  font-size: 0.9em;
  padding: 0 10px;
  line-height: 10%;
  color: #ffffff;
`;

export const DatePopup = styled.div`
  padding: 30px 20px;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;

  position: fixed;
  left: 0;
  z-index: 30;
  margin-top: -15px;
  margin-left: 45vw;
  display: none;
  background-color: #ffffff;
  border: 1px solid ${lighterBlue};
`;

export const DatePopupBtns = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
`;

export const CreateBtn = styled.div`
  margin-top: 20px;
  width: 120px;
  height: auto;
  display: flex;

  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${blue};
  transition: 0.2s;
  &:hover {
    background-color: rgba(1, 96, 182, 1);
  }
`;

export const CreateBtnContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

export const CreateBtnText = styled.p`
  font-family: ${rr};
  font-weight: 600;
  font-size: 1.1em;
  padding: 8px 10px;
  color: #ffffff;
  margin: 0;
`;

export const CancelBtn = styled.div`
  margin-top: 20px;
  width: 90px;
  height: auto;
  display: flex;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: #f53737;
  transition: 0.2s;
  margin-left: 20px;
  &:hover {
    background-color: #c12323;
  }
`;

export const CancelBtnText = styled.p`
  font-family: ${rr};
  font-weight: 600;
  font-size: 1.1em;
  padding: 8px 10px;
  color: #ffffff;
  margin: 0;
`;

export const PaidBtn = styled.div`
  width: 100px;
  height: auto;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  background-color: #1cca94;
`;

export const PaidBtnText = styled.p`
  font-family: ${rr};
  font-weight: 500;
  font-size: 0.9em;
  padding: 5px 10px;
  margin: 0;
  color: #ffffff;
`;

export const CheckedIcon = styled.div`
  display: block;
  height: 20px;
  width: 20px;
  margin-top: 5px;
  background-size: contain;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml,
  <svg viewBox="0 0 20 16" xmlns="http://www.w3.org/2000/svg">
  <path fill="rgb(255, 255, 255)" d="M6.41256 11.0762L16.9955 0.493273C17.3244 0.164424 17.7429 0 18.2511 0C18.7593 0 19.1779 0.164424 19.5067 0.493273C19.8356 0.822122 20 1.24066 20 1.74888C20 2.2571 19.8356 2.67564 19.5067 3.00448L7.66816 14.843C7.30942 15.2018 6.89088 15.3812 6.41256 15.3812C5.93423 15.3812 5.51569 15.2018 5.15695 14.843L0.493273 10.1794C0.164424 9.85052 0 9.43199 0 8.92376C0 8.41554 0.164424 7.99701 0.493273 7.66816C0.822122 7.33931 1.24066 7.17489 1.74888 7.17489C2.2571 7.17489 2.67564 7.33931 3.00448 7.66816L6.41256 11.0762Z"  />
  </svg>
  ');
`;

export const CrossIcon = styled.div`
  display: block;
  height: 20px;
  width: 16px;
  margin-top: 5px;
  background-size: contain;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml,
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
  <path fill="rgb(255, 255, 255)" d="M19.6209 2.20773C19.741 2.08779 19.8363 1.94537 19.9013 1.7886C19.9664 1.63183 19.9999 1.46378 20 1.29405C20.0001 1.12432 19.9668 0.956236 19.9019 0.799388C19.8371 0.642539 19.742 0.5 19.622 0.37991C19.5021 0.25982 19.3596 0.16453 19.2029 0.0994814C19.0461 0.0344326 18.8781 0.000898753 18.7083 0.000794432C18.5386 0.000690112 18.3705 0.0340174 18.2137 0.0988734C18.0568 0.163729 17.9143 0.258844 17.7942 0.378786L9.99983 8.17314L2.20773 0.378786C1.9652 0.136253 1.63625 -2.5555e-09 1.29326 0C0.950265 2.5555e-09 0.621319 0.136253 0.378786 0.378786C0.136253 0.621319 2.5555e-09 0.950265 0 1.29326C-2.5555e-09 1.63625 0.136253 1.9652 0.378786 2.20773L8.17314 9.99983L0.378786 17.7919C0.258696 17.912 0.163435 18.0546 0.0984432 18.2115C0.0334509 18.3684 0 18.5366 0 18.7064C0 18.8762 0.0334509 19.0444 0.0984432 19.2013C0.163435 19.3582 0.258696 19.5008 0.378786 19.6209C0.621319 19.8634 0.950265 19.9997 1.29326 19.9997C1.46309 19.9997 1.63126 19.9662 1.78817 19.9012C1.94507 19.8362 2.08764 19.741 2.20773 19.6209L9.99983 11.8265L17.7942 19.6209C18.0367 19.8631 18.3655 19.9991 18.7083 19.9989C19.0511 19.9987 19.3798 19.8623 19.622 19.6198C19.8642 19.3772 20.0002 19.0484 20 18.7056C19.9998 18.3628 19.8634 18.0342 19.6209 17.7919L11.8265 9.99983L19.6209 2.20773Z"  />
  </svg>
  ');
`;