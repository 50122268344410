import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { addDays, format, isBefore, parse } from 'date-fns';
import { REQUEST_STATUSES, ResponseError, STORE } from 'src/globaltypes';
import MonetaryClaims from '../../../Suppliers/SupplierInfo/Tabs/MonetaryClaims/MonetaryClaims';
import { openModal, closeModal } from 'Modal/actions/toggleModal';
import { MODAL_NAMES } from 'src/features/Common/Modal/types';
import { OpenModalDataType } from 'Modal/reducers/toggleModal';
import { Modal } from 'src/features/Common';
import {
  req as putMonetaryClaimDeleteToAuction,
  reset as resetMonetaryClaimDeleteToAuction
} from 'src/features/SCF/actions/putMonetaryClaimDeleteToAuction';
import {
  req as getMonetaryClaims,
  reset as resetMonetaryClaims,
  RequestDataType as MonetaryClaimReq,
  ResponseDataType as MonetaryClaimRes
} from 'entities/SCF/Debtor/model/actions/getMonetaryClaimsSuppliesRole';
import { ResponseData as ResDiscountAuction } from 'src/features/SCF/actions/getOneDiscountAuction';
import {
  req as postAddedMonetaryClaimByDebtor,
  RequestData as ReqAddedMonetaryClaimByDebtor
} from 'src/features/SCF/actions/postAddedMonetaryClaimByDebtor';
import {
  ButtonContainer,
  ButtonSelected,
  ContainerFilter,
  ErrorDeleteDiscount,
  HeaderContainer,
  ModalContainerFilter,
  MonetaryClaimInfo,
  MonetaryRequirementsContainer,
  Spinner as SpinnerApi
} from './styled';
import { ModalMonetaryClaims } from './ModalMonetaryClaims';
import { Input } from 'shared/ui/Input';
import { Select } from 'shared/ui/Select';
import { InnPlaceholders } from 'shared/constants';
import { Button } from 'shared/ui/Button';
import { formSumStringThousands } from 'src/shared/utils/Utils';
import { InputDate } from 'src/shared/ui/InputDate';

interface StateToProps {
  monetaryClaims: MonetaryClaimRes;
  statusMonetaryClaims: REQUEST_STATUSES;
  statusDeleteAuction: REQUEST_STATUSES;
  errorDeleteAuction: ResponseError;
  discountAuction: ResDiscountAuction;
  modalIsActive: boolean;
  statusAddedMonetaryClaim: REQUEST_STATUSES;
}

interface DispatchToProps {
  getMonetaryClaims: (data: MonetaryClaimReq) => void;
  resetMonetaryClaims: () => void;
  putMonetaryClaimDeleteToAuction: (guid: string, auction_guid: string) => void;
  resetMonetaryClaimDeleteToAuction: () => void;
  openModal: (data: OpenModalDataType) => void;
  closeModal: () => void;
  postAddedMonetaryClaimByDebtor: (
    auctionGuid: string,
    data: ReqAddedMonetaryClaimByDebtor
  ) => void;
}

interface MatchParams {
  guid: string;
  tab: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

interface SelectedItems {
  id: string;
  baseFullSumm: number;
}

interface DeselectedItems extends SelectedItems {}

const StatusTypes = {
  DEFAULT: 'Показать все',
  AVAILABLE: 'Неоплаченные, доступные для ранней оплаты',
  CHOSEN: 'Неоплаченные, выбранные поставщиком для ранней оплаты',
  LOCKED: 'Неоплаченные, недоступные для ранней оплаты',
  UNPAID: 'Все неоплаченные',
  PAID: 'Оплаченные'
};

const MonetaryClaimsView: React.FC<Props> = ({
  getMonetaryClaims,
  resetMonetaryClaims,
  monetaryClaims,
  statusMonetaryClaims,
  putMonetaryClaimDeleteToAuction,
  resetMonetaryClaimDeleteToAuction,
  statusDeleteAuction,
  errorDeleteAuction,
  openModal,
  closeModal,
  discountAuction,
  modalIsActive,
  postAddedMonetaryClaimByDebtor,
  statusAddedMonetaryClaim,
  match,
  history
}) => {
  const [supplierInn, setSupplierInn] = React.useState('');
  const [supplierStatus, setSupplierStatus] = React.useState('');
  const [minBaseSum, setMinBaseSum] = React.useState('');
  const [maxBaseSum, setMaxBaseSum] = React.useState('');
  const [minMaturityDate, setMinMaturityDate] = React.useState('');
  const [maxMaturityDate, setMaxMaturityDate] = React.useState('');

  const [modalSupplierInn, setModalSupplierInn] = React.useState('');
  const [modalMinBaseSum, setModalMinBaseSum] = React.useState('');
  const [modalMaxBaseSum, setModalMaxBaseSum] = React.useState('');
  const [modalMinMaturityDate, setModalMinMaturityDate] = React.useState('');
  const [modalMaxMaturityDate, setModalMaxMaturityDate] = React.useState('');

  const [isFiltersVisible, setIsFiltersVisible] = React.useState(false);
  const [counterInitial, setCounterInitial] = React.useState(false);

  const [isAddedMonetaryClaim, setIsAddedMonetaryClaim] = React.useState(false);
  const [monetaryInfo, setMonetaryInfo] = React.useState({
    totalItems: 0,
    baseFullSum: 0
  });
  const [isAllSelected, setIsAllSelected] = React.useState(false);
  const [selectedItems, setSelectedItems] = React.useState<SelectedItems[]>([]);
  const [deselectedItems, setDeselectedItems] = React.useState<
    DeselectedItems[]
  >([]);
  const [isSummSort, setIsSummSort] = React.useState(null);
  const [isMaturityDateSort, setIsmaturityDateSort] = React.useState(null);
  const [modalIsSummSort, setModalIsSummSort] = React.useState(null);
  const [modalIsMaturityDateSort, setModalIsmaturityDateSort] = React.useState(
    null
  );

  React.useEffect(() => {
    return () => {
      resetMonetaryClaims();
      resetMonetaryClaimDeleteToAuction();
    };
  }, []);

  React.useEffect(
    () => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('page', '1');
      history.push({ search: searchParams.toString() });
      resetMonetaryClaims();
    },
    [modalIsActive]
  );

  React.useEffect(
    () => {
      if (
        isAddedMonetaryClaim &&
        statusMonetaryClaims === REQUEST_STATUSES.GOT
      ) {
        const filteredMonetaryClaim = monetaryClaims.items
          .filter(
            claim => !deselectedItems.some(item => claim.guid === item.id)
          )
          .map(item => item.guid);

        if (filteredMonetaryClaim.length <= 100) {
          postAddedMonetaryClaimByDebtor(match.params.guid, {
            Guids: filteredMonetaryClaim
          });
        } else if (filteredMonetaryClaim.length > 100) {
          const chunkArray = (array: string[], chunkSize: number) => {
            const result = [];
            for (let i = 0; i < array.length; i += chunkSize) {
              result.push(array.slice(i, i + chunkSize));
            }
            return result;
          };

          const callMonetaryClaimsAPI = chunks => {
            for (const chunk of chunks) {
              postAddedMonetaryClaimByDebtor(match.params.guid, {
                Guids: chunk
              });
            }
          };

          const chunkedMonetaryClaims = chunkArray(filteredMonetaryClaim, 100);
          callMonetaryClaimsAPI(chunkedMonetaryClaims);
        }
      }
    },
    [isAddedMonetaryClaim, statusMonetaryClaims]
  );

  React.useEffect(
    () => {
      if (statusAddedMonetaryClaim === REQUEST_STATUSES.GOT) {
        setIsAddedMonetaryClaim(false);
        closeModal();
        getMonetaryClaims({
          page: 1,
          pageSize: 20,
          auction: match.params.guid,
          withStats: true,
          ...(supplierInn && { supplierInn }),
          ...(supplierStatus &&
            supplierStatus !== 'DEFAULT' && { status: supplierStatus })
        });
        setCounterInitial(false);
      } else if (statusAddedMonetaryClaim === REQUEST_STATUSES.ERROR) {
        closeModal();
        setIsAddedMonetaryClaim(false);
      }
    },
    [statusAddedMonetaryClaim]
  );

  React.useEffect(
    () => {
      if (
        statusMonetaryClaims === REQUEST_STATUSES.GOT &&
        counterInitial === false
      ) {
        setCounterInitial(true);
        setMonetaryInfo({
          totalItems: monetaryClaims.totalItems,
          baseFullSum: monetaryClaims.baseFullSum
        });
        if (monetaryClaims && monetaryClaims.items.length > 0) {
          setIsFiltersVisible(true);
        } else {
          setIsFiltersVisible(false);
        }
      }
    },
    [statusMonetaryClaims, counterInitial]
  );

  React.useEffect(
    () => {
      if (modalIsActive) {
        const searchParams = new URLSearchParams(location.search);
        const page = +searchParams.get('page') || 1;

        getMonetaryClaims({
          page,
          pageSize: 20,
          ...(modalSupplierInn && { supplierInn: modalSupplierInn }),
          minMaturityDate: format(
            addDays(new Date(discountAuction.payDate), 1),
            'yyyy-MM-dd'
          ),
          ...(modalMinBaseSum && { minBaseSum: modalMinBaseSum }),
          ...(modalMaxBaseSum && { maxBaseSum: modalMaxBaseSum }),
          ...(modalMinMaturityDate && {
            minMaturityDate: format(
              parse(modalMinMaturityDate, 'dd/MM/yyyy', new Date()),
              'yyyy-MM-dd'
            )
          }),
          ...(modalMaxMaturityDate && {
            maxMaturityDate: format(
              parse(modalMaxMaturityDate, 'dd/MM/yyyy', new Date()),
              'yyyy-MM-dd'
            )
          }),
          status: 'AVAILABLE',
          withStats: true,
          supplierIdentified: true,
          auction: 'null',
          sortBy:
            modalIsSummSort === true
              ? 'summDesc'
              : modalIsSummSort === false
                ? 'summAsc'
                : modalIsMaturityDateSort === true
                  ? 'maturityDateDesc'
                  : modalIsMaturityDateSort === false && 'maturityDateAsc'
        });
      } else {
        const searchParams = new URLSearchParams(location.search);
        const page = +searchParams.get('page') || 1;
        const supplierInn = searchParams.get('supplierInn') || '';
        const supplierStatus = searchParams.get('supplierStatus') as any;
        const minBaseSum = searchParams.get('minBaseSum') || '';
        const maxBaseSum = searchParams.get('maxBaseSum') || '';
        const minMaturityDate = searchParams.get('minMaturityDate') || '';
        const maxMaturityDate = searchParams.get('maxMaturityDate') || '';

        setSupplierInn(supplierInn);
        setSupplierStatus(supplierStatus);
        setMinBaseSum(minBaseSum);
        setMaxBaseSum(maxBaseSum);
        setMinMaturityDate(minMaturityDate);
        setMaxMaturityDate(maxMaturityDate);
        setSelectedItems([]);

        getMonetaryClaims({
          page,
          pageSize: 20,
          auction: match.params.guid,
          withStats: true,
          ...((isSummSort !== null || isMaturityDateSort !== null) && {
            sortBy:
              isSummSort === true
                ? 'summDesc'
                : isSummSort === false
                  ? 'summAsc'
                  : isMaturityDateSort === true
                    ? 'maturityDateDesc'
                    : isMaturityDateSort === false && 'maturityDateAsc'
          }),
          ...(supplierInn && { supplierInn }),
          ...(supplierStatus &&
            supplierStatus !== 'DEFAULT' && { status: supplierStatus }),
          ...(minBaseSum && { minBaseSum }),
          ...(maxBaseSum && { maxBaseSum }),
          ...(minMaturityDate && {
            minMaturityDate: format(
              parse(minMaturityDate, 'dd/MM/yyyy', new Date()),
              'yyyy-MM-dd'
            )
          }),
          ...(maxMaturityDate && {
            maxMaturityDate: format(
              parse(maxMaturityDate, 'dd/MM/yyyy', new Date()),
              'yyyy-MM-dd'
            )
          })
        });
      }
    },
    [
      modalIsActive,
      modalSupplierInn,
      modalMinBaseSum,
      modalMaxBaseSum,
      modalMinMaturityDate,
      modalMaxMaturityDate,
      modalIsSummSort,
      modalIsMaturityDateSort,
      isSummSort,
      isMaturityDateSort,
      match.params.guid,
      history.location.search
    ]
  );

  React.useEffect(
    () => {
      if (statusDeleteAuction === REQUEST_STATUSES.GOT) {
        const searchParams = new URLSearchParams(history.location.search);
        const page = +searchParams.get('page') || 1;
        const supplierInn = searchParams.get('supplierInn') || '';
        const supplierStatus = searchParams.get('supplierStatus') || '';
        const minBaseSum = searchParams.get('minBaseSum') || '';
        const maxBaseSum = searchParams.get('maxBaseSum') || '';
        const minMaturityDate = searchParams.get('minMaturityDate') || '';
        const maxMaturityDate = searchParams.get('maxMaturityDate') || '';

        setSupplierInn(supplierInn);
        setSupplierStatus(supplierStatus);
        setMinBaseSum(minBaseSum);
        setMaxBaseSum(maxBaseSum);
        setMinMaturityDate(minMaturityDate);
        setMaxMaturityDate(maxMaturityDate);

        getMonetaryClaims({
          page,
          pageSize: 20,
          auction: match.params.guid,
          withStats: true,
          ...((isSummSort !== null || isMaturityDateSort !== null) && {
            sortBy:
              isSummSort === true
                ? 'summDesc'
                : isSummSort === false
                  ? 'summAsc'
                  : isMaturityDateSort === true
                    ? 'maturityDateDesc'
                    : isMaturityDateSort === false && 'maturityDateAsc'
          }),
          ...(supplierInn && { supplierInn }),
          ...(supplierStatus &&
            supplierStatus !== 'DEFAULT' && { status: supplierStatus }),
          ...(minBaseSum && { minBaseSum }),
          ...(maxBaseSum && { maxBaseSum }),
          ...(minMaturityDate && {
            minMaturityDate: format(
              parse(minMaturityDate, 'dd/MM/yyyy', new Date()),
              'yyyy-MM-dd'
            )
          }),
          ...(maxMaturityDate && {
            maxMaturityDate: format(
              parse(maxMaturityDate, 'dd/MM/yyyy', new Date()),
              'yyyy-MM-dd'
            )
          })
        });
      }
      if (statusDeleteAuction === REQUEST_STATUSES.ERROR) {
        openModal({ name: MODAL_NAMES.ERROR_DELETE_AUCTION });
      }
    },
    [statusDeleteAuction]
  );

  React.useEffect(
    () => {
      if (
        statusMonetaryClaims === REQUEST_STATUSES.GOT &&
        isAllSelected &&
        modalIsActive
      ) {
        setSelectedItems(prevState => {
          const newItems = monetaryClaims.items
            .map(item => ({ id: item.guid, baseFullSumm: item.baseFullSumm }))
            .filter(
              claim =>
                !deselectedItems.some(deselected => deselected.id === claim.id)
            )
            .filter(
              item => !prevState.some(selected => selected.id === item.id)
            );

          return [...prevState, ...newItems];
        });
      }
    },
    [isAllSelected, statusMonetaryClaims, deselectedItems, modalIsActive]
  );

  const onFilterChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget;

    const searchParams = new URLSearchParams(history.location.search);

    if (value) {
      searchParams.set(name, value);
    } else {
      searchParams.delete(name);
    }

    history.push({ search: searchParams.toString() });
  };

  const onDateChange = (name: string, date: string) => {
    const searchParams = new URLSearchParams(history.location.search);

    if (name === 'minMaturityDate' && date !== '') {
      searchParams.set(name, date);
    } else if (name === 'maxMaturityDate' && date !== '') {
      searchParams.set(name, date);
    } else {
      searchParams.delete(name);
    }

    history.push({ search: searchParams.toString() });
  };

  const handleCheckboxChange = (id: string, baseFullSumm: number) => {
    const isSelected = selectedItems.some(selected => selected.id === id);

    if (isSelected) {
      setDeselectedItems(prev => [...prev, { id, baseFullSumm }]);
      setSelectedItems(prev => prev.filter(selected => selected.id !== id));
    } else {
      setSelectedItems(prev => [...prev, { id, baseFullSumm }]);
      setDeselectedItems(prevState =>
        prevState.filter(selected => selected.id !== id)
      );
    }
  };

  const handleSelectAll = () => {
    if (isAllSelected) {
      setIsAllSelected(false);
      setSelectedItems([]);
    } else {
      setIsAllSelected(true);
      setDeselectedItems([]);
    }
  };

  const handleAddedMonetaryClaim = () => {
    if (calculateStyle() === 'onlyOneActive') {
      postAddedMonetaryClaimByDebtor(match.params.guid, {
        Guids: selectedItems.map(item => item.id)
      });
    } else if (
      calculateStyle() === 'allActive' ||
      calculateStyle() === 'almostAllActive'
    ) {
      setIsAddedMonetaryClaim(true);
      getMonetaryClaims({
        page: 1,
        pageSize: 20,
        minMaturityDate: format(
          addDays(new Date(discountAuction.payDate), 1),
          'yyyy-MM-dd'
        ),
        supplierIdentified: true,
        ...(supplierInn && { supplierInn }),
        ...(supplierStatus &&
          supplierStatus !== 'DEFAULT' && { status: supplierStatus }),
        allGuids: true,
        auction: 'null'
      });
    }
  };

  const calculateSum = () => {
    if (isAllSelected && !!selectedItems.length) {
      const deselectedSum = deselectedItems.reduce(
        (sum, item) => sum + item.baseFullSumm,
        0
      );

      return {
        count: monetaryClaims.totalItems - deselectedItems.length,
        sum: formSumStringThousands(monetaryClaims.baseFullSum - deselectedSum)
      };
    }

    if (selectedItems.length === 0) {
      return {
        count: '-',
        sum: '-'
      };
    }

    const selectedCount = selectedItems.length;
    const selectedSum = selectedItems.reduce(
      (sum, item) => sum + item.baseFullSumm,
      0
    );

    return {
      count: selectedCount,
      sum: formSumStringThousands(selectedSum)
    };
  };

  const calculateStyle = () => {
    if (isAllSelected && !!selectedItems.length) {
      if (selectedItems.length < monetaryClaims.pageSize) {
        return 'almostAllActive';
      }
      return 'allActive';
    }

    if (selectedItems.length > 0) {
      return 'onlyOneActive';
    }

    return 'allInActive';
  };

  const handleOpenMonetaryRequirements = () => {
    resetMonetaryClaims();
    openModal({ name: MODAL_NAMES.ADDING_MONETARY_REQUIREMENTS });
  };

  return (
    <>
      {isFiltersVisible && (
        <HeaderContainer>
          <ContainerFilter>
            <Input
              value={supplierInn}
              label="ИНН Поставщика"
              name="supplierInn"
              placeholder={InnPlaceholders.entity}
              onChange={onFilterChange}
            />
            <Select
              label="Статус"
              options={Object.keys(StatusTypes).map(type => ({
                id: type,
                name: StatusTypes[type]
              }))}
              name="supplierStatus"
              value={supplierStatus}
              onChange={onFilterChange}
            />
            <Input
              value={minBaseSum}
              label="Сумма денежного требования"
              name="minBaseSum"
              onChange={onFilterChange}
            />
            <span>-</span>
            <Input
              value={maxBaseSum}
              name="maxBaseSum"
              onChange={onFilterChange}
            />
            <InputDate
              dateValue={minMaturityDate}
              label="Дата плановой оплаты"
              name="minMaturityDate"
              onDateChange={date => onDateChange('minMaturityDate', date)}
            />
            <span>-</span>
            <InputDate
              dateValue={maxMaturityDate}
              name="maxMaturityDate"
              onDateChange={date => onDateChange('maxMaturityDate', date)}
            />
          </ContainerFilter>

          <MonetaryClaimInfo>
            <p>Денежные требования</p>

            <div>
              <div>
                <p>Добавлено в аукцион:</p>
                <div>
                  <div>
                    <p>{monetaryInfo.totalItems}</p>
                    <span>шт.</span>
                  </div>
                  <div>
                    <span>на сумму</span>
                    <p>{formSumStringThousands(monetaryInfo.baseFullSum)} </p>
                    <span>руб.</span>
                  </div>
                </div>
              </div>

              <div>
                <p>Соответствуют фильтрам:</p>
                <div>
                  <div>
                    <p>{monetaryClaims.totalItems}</p>
                    <span>шт.</span>
                  </div>
                  <div>
                    <span>на сумму</span>
                    <p>{formSumStringThousands(monetaryClaims.baseFullSum)}</p>
                    <span>руб.</span>
                  </div>
                </div>
              </div>
            </div>
          </MonetaryClaimInfo>
        </HeaderContainer>
      )}

      {isBefore(new Date(), new Date(discountAuction.resultsDateTime)) && (
        <Button
          w="fit-content"
          h="40px"
          label="+ Добавить"
          onClick={handleOpenMonetaryRequirements}
        />
      )}

      {!!Object.keys(errorDeleteAuction).length && (
        <Modal name={MODAL_NAMES.ERROR_DELETE_AUCTION}>
          <ErrorDeleteDiscount>
            <div>Ошибка: {errorDeleteAuction.code}</div>
            <div>{errorDeleteAuction.body}</div>
          </ErrorDeleteDiscount>
        </Modal>
      )}

      <Modal name={MODAL_NAMES.ADDING_MONETARY_REQUIREMENTS}>
        <MonetaryRequirementsContainer>
          <h3>Добавление денежных требований</h3>

          <HeaderContainer>
            <ModalContainerFilter>
              <Input
                value={modalSupplierInn}
                label="ИНН Поставщика"
                name="modalSupplierInn"
                placeholder={InnPlaceholders.entity}
                onChange={e => setModalSupplierInn(e.currentTarget.value)}
              />
              <Input
                value={modalMinBaseSum}
                label="Сумма денежного требования"
                name="modalMinBaseSum"
                onChange={e => setModalMinBaseSum(e.currentTarget.value)}
              />
              <span>-</span>
              <Input
                value={modalMaxBaseSum}
                name="modalMaxBaseSum"
                onChange={e => setModalMaxBaseSum(e.currentTarget.value)}
              />
              <InputDate
                dateValue={modalMinMaturityDate}
                label="Дата плановой оплаты"
                name="modalMinMaturityDate"
                onDateChange={date => setModalMinMaturityDate(date)}
              />
              <span>-</span>
              <InputDate
                dateValue={modalMaxMaturityDate}
                name="modalMaxMaturityDate"
                onDateChange={date => setModalMaxMaturityDate(date)}
              />
            </ModalContainerFilter>

            <MonetaryClaimInfo>
              <p>Денежные требования</p>

              <div>
                <div>
                  <p>Доступно:</p>

                  <div>
                    <div>
                      <p>{monetaryClaims.totalItems}</p>
                      <span>шт.</span>
                    </div>
                    <div>
                      <span>на сумму</span>
                      <p>
                        {formSumStringThousands(monetaryClaims.baseFullSum)}
                      </p>
                      <span>руб.</span>
                    </div>
                  </div>
                </div>

                <div>
                  <p>Выбрано:</p>

                  <div>
                    <div>
                      <p>{calculateSum().count}</p>
                      <span>шт.</span>
                    </div>

                    <div>
                      <span>на сумму</span>
                      <p>{calculateSum().sum}</p>
                      <span>руб.</span>
                    </div>
                  </div>
                </div>
              </div>
            </MonetaryClaimInfo>
          </HeaderContainer>

          <ButtonContainer onClick={handleSelectAll}>
            <ButtonSelected active={calculateStyle()} />
            <p>Выбрать всё</p>
          </ButtonContainer>

          <div>
            {statusMonetaryClaims === REQUEST_STATUSES.GOT && (
              <ModalMonetaryClaims
                monetaryClaims={monetaryClaims}
                status={statusMonetaryClaims}
                activeIds={selectedItems}
                toggleRecordState={handleCheckboxChange}
                isSummSort={modalIsSummSort}
                handleSortedSumm={setModalIsSummSort}
                isMaturityDate={modalIsMaturityDateSort}
                handleSortedMaturityDate={setModalIsmaturityDateSort}
              />
            )}
          </div>

          <div>
            <Button
              w="fit-content"
              h="40px"
              label="+ Добавить"
              disabled={calculateStyle() === 'allInActive'}
              onClick={handleAddedMonetaryClaim}
            />
            {statusMonetaryClaims === REQUEST_STATUSES.REQUEST && (
              <SpinnerApi icon={faSpinner} />
            )}
          </div>
        </MonetaryRequirementsContainer>
      </Modal>

      {!modalIsActive && (
        <MonetaryClaims
          monetaryClaims={monetaryClaims}
          status={statusMonetaryClaims}
          type={['scf', 'debtor']}
          putMonetaryClaimDeleteToAuction={putMonetaryClaimDeleteToAuction}
          isSummSort={isSummSort}
          handleSortedSumm={setIsSummSort}
          isMaturityDate={isMaturityDateSort}
          handleSortedMaturityDate={setIsmaturityDateSort}
          noData={
            !isFiltersVisible
              ? {
                  title: 'ПОСТАВКИ НЕ ДОБАВЛЕНЫ',
                  text: 'к этому аукциону еще не добавлена ни одна поставка'
                }
              : {
                  title: '',
                  text: ''
                }
          }
        />
      )}
    </>
  );
};

const mapStateToProps = ({ SCF, SCFDebtor, Modal }: STORE) => ({
  monetaryClaims: SCFDebtor.getMonetaryClaimsSuppliesRole.data,
  statusMonetaryClaims: SCFDebtor.getMonetaryClaimsSuppliesRole.status,
  statusDeleteAuction: SCF.putMonetaryClaimDeleteToAuction.status,
  errorDeleteAuction: SCF.putMonetaryClaimDeleteToAuction.error,
  discountAuction: SCF.getOneDiscountAuction.data,
  modalIsActive: Modal.toggleModal.isActive,
  statusAddedMonetaryClaim: SCF.postAddedMonetaryClaimByDebtor.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getMonetaryClaims,
      resetMonetaryClaims,
      putMonetaryClaimDeleteToAuction,
      resetMonetaryClaimDeleteToAuction,
      openModal,
      closeModal,
      postAddedMonetaryClaimByDebtor
    },
    dispatch
  );

const MonetaryClaimsViewConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(MonetaryClaimsView)
);

export { MonetaryClaimsViewConnect as MonetaryClaims };