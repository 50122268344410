import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { STORE } from 'src/globaltypes';
import { ResponseDataType as GetCertificateResponseDataType } from 'src/features/SCF/actions/getCertificate';
import { req as getCertificate } from 'src/features/SCF/actions/getCertificate';
import { Certificate as CertificateType } from 'src/features/SCF/types/certificate';
import { CertificatesTableView } from './CertificatesTableView/CertificatesTableView';
import {
  getCertificate as getCertificateCryptoPro,
  getAllUserCertificates,
  Certificate,
  addDetachedSignature,
  createHash,
  createDetachedSignature
} from 'crypto-pro-js';
import SCFApi from 'src/features/SCF/api';
import { req as sendSignature } from 'entities/SCF/Debtor/model/actions/sendSignature';
import { RequestDataType as SendSignatureRequestDataType } from 'entities/SCF/Debtor/model/reducers/sendSignature';

interface OwnProps {
  registryId: number;
  fileName: string;
}

interface StateToProps {
  certificate: GetCertificateResponseDataType;
}

interface DispatchToProps {
  getCertificate: () => void;
  sendSignature: (data: SendSignatureRequestDataType) => void;
}

interface State {
  validateInnError: any;
  certificates: Certificate[];
  certificatesError: Certificate[];
  certificate: Certificate;
  certificateDetails: any;
  detailsError: Certificate;
}

type Props = StateToProps & DispatchToProps & OwnProps;

class CertificatesList extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      validateInnError: null,
      certificates: [],
      certificatesError: [],
      certificate: null,
      certificateDetails: null,
      detailsError: null
    };
  }

  componentDidMount() {
    this.props.getCertificate();
  }

  getCertificateId = (index: number): number => {
    const item = this.props.certificate.items[index];
    return !!item && item.id;
  };

  async setUserCertificates() {
    (async () => {
      try {
        this.setState({ certificates: await getAllUserCertificates() });
      } catch (error) {
        this.setState({ certificatesError: error.message });
      }
    })();
  }

  async setSigUserCertificate() {
    try {
      var buffer: ArrayBuffer;

      await fetch(SCFApi.getDownloadRegistry(this.props.registryId))
        .then(result => result.arrayBuffer())
        .then(result => {
          buffer = result;
        });
      var hash = await createHash(buffer);
      var sig = await createDetachedSignature(
        this.state.certificate.thumbprint,
        hash
      );
      this.props.sendSignature({
        id: this.props.registryId,
        signature: sig,
        registryDocumentName: this.props.fileName
      });
      window.location.reload();
    } catch (error) {
      this.setState({ certificatesError: error.message });
    }
  }

  onCertificateClick = async (index: number): Promise<void> => {
    var id: number,
      id = this.getCertificateId(index);
    const dbCert: CertificateType = this.props.certificate.items.find(
      cert => cert.id === id
    );
    this.setUserCertificates();

    try {
      const selectedCertificate = await getCertificateCryptoPro(
        dbCert.serialNumber,
        false
      );
      this.setState({ certificate: selectedCertificate });
      this.setSigUserCertificate();
    } catch (error) {
      const signError = document.getElementById('certificatesPopupError');
      signError.style.display = 'flex';
    }
  };

  formCertificateData = (): CertificateType[] => {
    return this.props.certificate.items.map(this.certificateToView);
  };

  certificateToView = (certificate: CertificateType) => {
    const viewItem: CertificateType = {
      id: certificate.id,
      companyInn: certificate.companyInn,
      companyOgrnOrOgrnIP: certificate.companyOgrnOrOgrnIP,
      companyName_O: certificate.companyName_O,
      companyName_CN: certificate.companyName_CN,
      companyCountryC: certificate.companyCountryC,
      companyRegionS: certificate.companyRegionS,
      companyLocationL: certificate.companyLocationL,
      companyAddressStreet: certificate.companyAddressStreet,
      positionT: certificate.positionT,
      personSurNameSN: certificate.personSurNameSN,
      personNameG: certificate.personNameG,
      personInn: certificate.personInn,
      personEmailE: certificate.personEmailE,
      personSnils: certificate.personSnils,
      certValidFrom: certificate.certValidFrom,
      certValidUntil: certificate.certValidUntil,
      keyValidFrom: certificate.keyValidFrom,
      keyValidUntil: certificate.keyValidUntil,
      issuerInn: certificate.issuerInn,
      issuerOGRN: certificate.issuerOGRN,
      issuerName_O: certificate.issuerName_O,
      issuerName_CN: certificate.issuerName_CN,
      issuerTypeOU: certificate.issuerTypeOU,
      issuerCountryC: certificate.issuerCountryC,
      issuerRegionS: certificate.issuerRegionS,
      issuerLocationL: certificate.issuerLocationL,
      issuerAddressStreet: certificate.issuerAddressStreet,
      issuerEmailE: certificate.issuerEmailE,
      serialNumber: certificate.serialNumber,
      isDeleted: certificate.isDeleted,
      isAvailable: certificate.isAvailable
    };
    return viewItem;
  };

  render() {
    return (
      <React.Fragment>
        <CertificatesTableView
          list={this.formCertificateData()}
          onCertificateClick={this.onCertificateClick}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ SCF }: STORE) => ({
  certificate: SCF.getCertificate.data
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCertificate,
      sendSignature
    },
    dispatch
  );

const CertificatesListConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(CertificatesList);

export { CertificatesListConnect as CertificatesListPopup };
