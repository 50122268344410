import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import {
  reset as resetInternalApplication,
  ResponseData as ResGetInternalApplication
} from 'entities/Cabinet/InternalAgent/model/actions/getInternalApplication';
import {
  req as getAllOffers,
  reset as resetAllOffers,
  ResponseData as ResAllOffers
} from 'Application/actions/getAllOffers';
import {
  req as sendApplicationData,
  reset as resetApplicationData
} from 'Application/actions/sendApplicationData';
import { RequestDataType as sendApplicationDataReq } from 'Application/reducers/sendApplicationData';
import {
  req as getTenderByApplication,
  reset as resetTenderByApplication,
  ResponseData as ResTenderByApplication
} from 'Application/actions/getTenderByApplication';
import {
  req as getTenderTeledoc,
  ResponseData as TenderTeledocRes
} from 'src/features/Application/actions/getTenderTeledoc';
import {
  req as patchUpdateCompanyBlank,
  RequestData as patchUpdateCompanyBlankReq
} from 'Application/actions/patchUpdateCompanyBlank';
import { req as getCompany } from 'src/features/Companies/actions/getCompany';
import { req as getAddTenderByEruz } from 'src/features/Application/actions/getAddTenderByEruz';
import {
  req as postApplicationPrescoring,
  reset as resetApplicationPrescoring,
  RequestData as ReqPostApplicationPrescoring
} from 'src/features/Application/actions/postApplicationPrescoring';
import {
  req as postOfferSend,
  reset as resetOfferSend
} from 'Application/actions/postOfferSend';
import {
  req as postCreateTender,
  RequestData as ReqPostCreateTender
} from 'Application/actions/postCreateTender';
import { req as postAddTenderById } from 'Application/actions/postAddTenderById';
import {
  req as patchTenderUpdateByInternal,
  RequestData as ReqTenderUpdateByInternal
} from 'entities/Cabinet/InternalAgent/model/actions/patchTenderUpdateByInternal';
import {
  APPLICATION_TYPES,
  GUARANTEES_TYPE,
  TAX_TYPE,
  TENDER_SEGMENT,
  TENDER_TYPE
} from 'Application/types';
import {
  ApplicationTypes,
  InnPlaceholders,
  TaxSystemType
} from 'shared/constants';
import {
  formSumStringThousands,
  toFloatFormatter
} from 'src/shared/utils/Utils';
import { Checkbox } from 'shared/ui/Checkbox';
import { InputRange } from 'shared/ui/InputRange';
import { Input } from 'shared/ui/Input';
import { Textarea } from 'shared/ui/Textarea';
import { Select } from 'shared/ui/Select';
import { RANGE_SLIDER_TYPE } from 'src/shared/ui/InputRange/RangeSlider/RangeSlider';
import {
  CRM,
  REQUEST_STATUSES,
  ResponseError,
  STORE,
  USER_PERMISSIONS
} from 'globaltypes';
import { OpenModalDataType } from 'Modal/reducers/toggleModal';
import { MODAL_NAMES } from 'Modal/types';
import { TRANSITION_DIRECTIONS } from 'src/features/Common';
import { openModal } from 'src/features/Common/Modal/actions/toggleModal';
import { INPUT_RANGE_VALUE_TYPE } from 'shared/ui/InputRange';
import { Loader } from 'src/features/Layouts/components';
import { GuaranteesTerm, TERM_TYPE } from './GuaranteesTerm/GuaranteesTerm';
import { GuaranteesTypeRadioGroup } from './GuaranteesTypeRadioGroup/GuaranteesTypeRadioGroup';
import { createValidaton } from './validator';
import { ResponseDataType as GetApplicationFlagsResponseDataType } from 'Application/reducers/getApplicationFlags';
import {
  ResponseDataType,
  req as getCompanyByInn,
  RequestDataType,
  reset as resetStateCompanyByInn
} from 'src/features/SCF/actions/getCompanyByInnThirdParty';
import {
  ResponseDataType as CompanyByCustomerInnResData,
  req as getCompanyByCustomerInn,
  RequestDataType as CompanyByCustomerInnReqData
} from 'src/features/Application/actions/getCompanyByInn';

import { InnPopup } from 'src/features/Layouts/components/InnPopup/InnPopup';
import { PopupContainer } from 'src/features/Layouts/components/InnPopup/styled';
import {
  ApplicationForm,
  ApplicationFormContent,
  ApplicationFormStyled,
  ApplicationInputsColumn,
  ApplicationStepTitle,
  CloseApplicationBtn
} from '../styled';
import {
  ButtonContainer,
  ButtonStyle,
  EISContainer,
  FlexContainer,
  InputContainer,
  ModalContainer,
  ProductOffersSection,
  PurchaseContent,
  SendApi,
  TableRow,
  TaxSystemContainer,
  TeledocContainer,
  TitleTabContainer,
  ToggleButtonContainer,
  ToggleButtonNo,
  ToggleButtonYes,
  TooltipBoxStyle,
  Spinner as SpinnerApi,
  RejectionToggle,
  ButtonTeledocContainer,
  RefreshIcon
} from './styles';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import { TableThStyled } from 'src/features/Layouts/components/Table/TableRow/styles';
import { ClientsListType } from 'src/features/Clients/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faEye,
  faEyeSlash,
  faSpinner,
  faSyncAlt
} from '@fortawesome/free-solid-svg-icons';
import { Button } from 'shared/ui/Button';

interface OwnProps {
  path: string;
}

interface State {
  isGuaranteesTermValid: boolean;
  showTermErrors: boolean;
  isInfoPopupCompanyInn: boolean;
  isInfoPopupCustomerInn: boolean;
  clientsIsVisible: boolean;
  purchaseIsVisible: boolean;
  code: string;
  clientCompanyName: string;
  refinancingGuarantees: boolean;
  selectedRow: number;
  clientName: string;
  clientInn: string;
  errors: {};
  isTeledoc: boolean;
  isTaxSystem: boolean;
  isCode: boolean;
  isCustomerInn: boolean;
  isSectionClientInfo: boolean;
  isSectionGuarantees: boolean;
  isSectionContract: boolean;
  isSectionProductOffers: boolean;
  eis: string;
  amount: number;
  taxSystem: string;
  customerInn: string;
  customerName: string;
  term: number;
  termEndDate: string;
  termStartDate: string;
  startPrice: number;
  sumFinal: number;
  lotCode: number;
  subject: string;
  isClosedAuction: boolean;
  isSingleSupplier: boolean;
  tender: TENDER_TYPE;
  tax: TAX_TYPE;
  commercial: TENDER_TYPE;
  typeComment: string;
  tenderLink: string;
  guaranteesType: GUARANTEES_TYPE;
  financingType: APPLICATION_TYPES;
  desiredBanks: string;
  isPrescoring: boolean;
  isSendApiError: boolean;
  offerId: number;
  isApplicationPrescoring: boolean;
  isRejection: boolean;
  contractChanged: boolean;
}

interface StateToProps extends ResGetInternalApplication {
  applicationId: number;
  statusCreateEmpty: REQUEST_STATUSES;
  permissions: USER_PERMISSIONS[];
  uploadStatus: REQUEST_STATUSES;
  allowFlags: GetApplicationFlagsResponseDataType;
  statusInn: REQUEST_STATUSES;
  companyByInn: ResponseDataType;
  error: ResponseError;
  companyByCustomerInn: CompanyByCustomerInnResData;
  statusCompanyByCustomerInn: REQUEST_STATUSES;
  roles: string[];
  userCompanyInn: string;
  statusTaxSystem: REQUEST_STATUSES;
  companyTaxSystem: number;
  companyInn: string;
  companyShortName: string;
  statusCompanyTaxSystem: REQUEST_STATUSES;
  tenderTeledoc: TenderTeledocRes[];
  statusTenderTeledoc: REQUEST_STATUSES;
  statusAddTenderByEruz: REQUEST_STATUSES;
  statusGetInternalApplication: REQUEST_STATUSES;
  tenderByApplication: ResTenderByApplication[];
  statusTenderByApplication: REQUEST_STATUSES;
  allOffers: ResAllOffers[];
  statusAllOffers: REQUEST_STATUSES;
  statusSendApi: REQUEST_STATUSES;
  sendApiError: string;
  statusCreateTender: REQUEST_STATUSES;
  idCreateTender: number;
  statusSendApplication: REQUEST_STATUSES;
  statusPostApplicationPrescoring: REQUEST_STATUSES;
  errorPostApplicationPrescoring: ResponseError;
  statusPatchTenderUpdateByInternal: REQUEST_STATUSES;
}

interface DispatchToProps {
  sendApplicationData: (data: sendApplicationDataReq) => void;
  resetApplicationData: () => void;
  openModal: (data: OpenModalDataType) => void;
  getCompanyByInn: (data: RequestDataType) => void;
  getCompanyByCustomerInn: (data: CompanyByCustomerInnReqData) => void;
  resetStateCompanyByInn: () => void;
  patchUpdateCompanyBlank: (
    inn: string,
    data: patchUpdateCompanyBlankReq
  ) => void;
  getCompany: (inn: string) => void;
  getTenderTeledoc: (id: string, eis: string) => void;
  getAddTenderByEruz: (id: string, eruz: string) => void;
  getTenderByApplication: (id: string) => void;
  resetTenderByApplication: () => void;
  getAllOffers: (id: string) => void;
  postApplicationPrescoring: (data: ReqPostApplicationPrescoring) => void;
  postOfferSend: (id: number) => void;
  resetOfferSend: () => void;
  postCreateTender: (data: ReqPostCreateTender) => void;
  postAddTenderById: (id: string, tenderId: number) => void;
  resetApplicationPrescoring: () => void;
  resetAllOffers: () => void;
  patchTenderUpdateByInternal: (
    id: string,
    data: ReqTenderUpdateByInternal
  ) => void;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> &
  StateToProps &
  DispatchToProps &
  OwnProps;

// TODO refactor
// TODO extract base step class
class GuaranteesFormStep0 extends React.Component<Props, State> {
  Validator = createValidaton();

  state: State = {
    financingType: null,
    isGuaranteesTermValid: true,
    showTermErrors: false,
    isInfoPopupCompanyInn: false,
    isInfoPopupCustomerInn: false,
    clientsIsVisible: false,
    purchaseIsVisible: false,
    code: '',
    clientCompanyName: '',
    refinancingGuarantees: false,
    selectedRow: null,
    clientName: '',
    clientInn: '',
    amount: 0,
    desiredBanks: '',
    errors: {},
    isTeledoc: false,
    isTaxSystem: false,
    isCode: false,
    isCustomerInn: false,
    isSectionClientInfo: true,
    isSectionGuarantees: true,
    isSectionContract: true,
    isSectionProductOffers: true,
    eis: '',
    taxSystem: '',
    customerInn: '',
    customerName: '',
    term: 0,
    termEndDate: '',
    termStartDate: '',
    startPrice: 0,
    sumFinal: 0,
    lotCode: null,
    subject: '',
    isClosedAuction: null,
    isSingleSupplier: null,
    tender: null,
    tax: null,
    commercial: null,
    typeComment: '',
    tenderLink: '',
    guaranteesType: GUARANTEES_TYPE['44-FZ'],
    isPrescoring: true,
    isSendApiError: false,
    offerId: null,
    isApplicationPrescoring: false,
    isRejection: false,
    contractChanged: null
  };

  purchaseModalRef = React.createRef();
  sendApiErrorModalRef = React.createRef();
  applicationPrescoringErrorModalRef = React.createRef();

  componentDidMount() {
    if (this.props.statusGetInternalApplication === REQUEST_STATUSES.GOT) {
      if (!!this.props.code) {
        this.props.getCompany(this.props.code);
      }
      this.props.getTenderByApplication(this.props.match.params.id);
      this.props.getAllOffers(this.props.match.params.id);

      this.setState({
        financingType: this.props.financingType,
        code: this.props.code,
        clientCompanyName: this.props.clientCompanyName,
        amount: this.props.amount,
        guaranteesType: this.props.guaranteesType,
        tender: this.props.tender,
        commercial: this.props.commercial,
        tenderLink: this.props.purchaseLink,
        tax: this.props.tax,
        termStartDate: this.props.termStartDate,
        termEndDate: this.props.termEndDate,
        term: this.props.term,
        refinancingGuarantees: this.props.refinancing,
        desiredBanks: this.props.desiredBanks,
        customerInn: this.props.customerInn,
        customerName: this.props.customerName
      });
    }
    if (this.props.location.state && this.props.location.state.financingType) {
      this.setState({ financingType: this.props.location.state.financingType });
    }
    if (
      this.props.userCompanyInn &&
      this.props.permissions.includes(USER_PERMISSIONS.CLIENT)
    ) {
      this.setState({ code: this.props.userCompanyInn });
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.state.code !== prevState.code) {
      this.setState({ isInfoPopupCompanyInn: false });
    }
    if (this.state.customerInn !== prevState.customerInn) {
      this.setState({ isInfoPopupCustomerInn: false });
    }
    if (
      this.props.statusTenderByApplication !==
        prevProps.statusTenderByApplication &&
      this.props.statusTenderByApplication === REQUEST_STATUSES.GOT
    ) {
      this.setState({
        subject: this.props.tenderByApplication[0].tender.subject,
        lotCode: +this.props.tenderByApplication[0].tender.lotCode,
        sumFinal: this.props.tenderByApplication[0].tender.sumFinal,
        startPrice: this.props.tenderByApplication.reduce(
          (sum, item) => sum + item.tender.startPrice,
          0
        ),
        eis: this.props.tenderByApplication[0].tender.eruzId,
        customerName: this.props.tenderByApplication[0].tender.customerName,
        customerInn: this.props.tenderByApplication[0].tender.customerINN,
        tenderLink: this.props.tenderByApplication[0].tender.tenderLink,
        isClosedAuction: this.props.tenderByApplication[0].tender
          .isClosedAuction,
        isSingleSupplier: this.props.tenderByApplication[0].tender
          .isSingleSupplier,
        contractChanged: false
      });
    }
    if (
      this.props.statusCompanyTaxSystem !== prevProps.statusCompanyTaxSystem &&
      this.props.statusCompanyTaxSystem === REQUEST_STATUSES.GOT
    ) {
      this.setState({
        taxSystem: this.props.companyTaxSystem
          ? this.props.companyTaxSystem.toString()
          : '',
        clientCompanyName: this.props.companyInn
          ? this.props.companyShortName
          : '',
        code: this.props.companyShortName ? this.props.companyInn : ''
      });
    }
    if (this.state.code !== prevState.code && this.state.code.length >= 10) {
      this.props.getCompanyByInn({ inn: this.state.code });
    }
    if (
      prevState.customerInn !== this.state.customerInn &&
      this.state.customerInn &&
      this.state.customerInn.length >= 10
    ) {
      this.props.getCompanyByCustomerInn({ inn: this.state.customerInn });
    }
    if (this.props.statusTenderTeledoc !== prevProps.statusTenderTeledoc) {
      this.setState({ purchaseIsVisible: true });
    }
    if (
      this.props.statusAddTenderByEruz !== prevProps.statusAddTenderByEruz &&
      this.props.statusAddTenderByEruz === REQUEST_STATUSES.GOT
    ) {
      this.props.getTenderByApplication(this.props.match.params.id);
      this.setState({
        customerName: this.props.tenderTeledoc[0].customerName,
        customerInn: this.props.tenderTeledoc[0].customerINN,
        guaranteesType:
          this.props.tenderTeledoc[0].tenderSegment === 1
            ? GUARANTEES_TYPE['44-FZ']
            : this.props.tenderTeledoc[0].tenderSegment === 2
              ? GUARANTEES_TYPE['223-FZ']
              : this.props.tenderTeledoc[0].tenderSegment === 3
                ? GUARANTEES_TYPE['615-P']
                : GUARANTEES_TYPE['COMMERCIAL'],
        tenderLink: this.props.tenderTeledoc[0].tenderLink,
        startPrice: this.props.tenderTeledoc.reduce(
          (sum, item) => sum + item.startPrice,
          0
        ),
        sumFinal: this.props.tenderTeledoc[0].sumFinal,
        subject: this.props.tenderTeledoc[0].subject,
        lotCode: +this.props.tenderTeledoc[0].lotCode,
        isSingleSupplier: this.props.tenderTeledoc[0].isSingleSupplier,
        isClosedAuction: this.props.tenderTeledoc[0].isClosedAuction
      });
    }
    if (
      this.props.statusSendApi !== prevProps.statusSendApi &&
      this.props.statusSendApi === REQUEST_STATUSES.ERROR
    ) {
      this.setState({ isSendApiError: true });
    }
    if (
      this.props.statusCreateTender !== prevProps.statusCreateTender &&
      this.props.statusCreateTender === REQUEST_STATUSES.GOT
    ) {
      this.props.postAddTenderById(
        this.props.match.params.id,
        this.props.tenderTeledoc[0].id
      );
      this.props.getTenderByApplication(this.props.match.params.id);
    }
    if (
      this.props.statusPatchTenderUpdateByInternal !==
        prevProps.statusPatchTenderUpdateByInternal &&
      this.props.statusPatchTenderUpdateByInternal === REQUEST_STATUSES.GOT
    ) {
      this.props.postAddTenderById(
        this.props.match.params.id,
        this.props.tenderTeledoc[0].id
      );
      this.props.getTenderByApplication(this.props.match.params.id);
    }
    if (
      this.props.statusSendApplication !== prevProps.statusSendApplication &&
      this.props.statusSendApplication === REQUEST_STATUSES.GOT
    ) {
      this.props.resetApplicationData();
      if (this.state.offerId) {
        this.props.postOfferSend(this.state.offerId);
        this.setState({ offerId: null });
      }
      if (this.state.isPrescoring !== true) {
        this.props.postApplicationPrescoring({
          applicationID: +this.props.match.params.id
        });
      }
    }
    if (
      this.props.statusPostApplicationPrescoring !==
        prevProps.statusPostApplicationPrescoring &&
      this.props.statusPostApplicationPrescoring === REQUEST_STATUSES.GOT
    ) {
      this.props.getAllOffers(this.props.match.params.id);
    }
    if (
      this.props.statusPostApplicationPrescoring !==
        prevProps.statusPostApplicationPrescoring &&
      this.props.statusPostApplicationPrescoring === REQUEST_STATUSES.ERROR
    ) {
      this.setState({ isApplicationPrescoring: true });
    }
    if (
      this.props.statusSendApi !== prevProps.statusSendApi &&
      this.props.statusSendApi === REQUEST_STATUSES.GOT
    ) {
      this.props.getAllOffers(this.props.match.params.id);
    }
  }

  componentWillUnmount(): void {
    this.props.resetApplicationData();
    this.props.resetApplicationPrescoring();
    this.props.resetTenderByApplication();
    this.props.resetAllOffers();
    this.props.resetOfferSend();
    this.setState({
      financingType: null,
      isGuaranteesTermValid: true,
      showTermErrors: false,
      isInfoPopupCompanyInn: false,
      isInfoPopupCustomerInn: false,
      clientsIsVisible: false,
      purchaseIsVisible: false,
      code: '',
      clientCompanyName: '',
      refinancingGuarantees: false,
      selectedRow: null,
      clientName: '',
      clientInn: '',
      amount: 0,
      desiredBanks: '',
      errors: {},
      isTeledoc: false,
      isTaxSystem: false,
      isCode: false,
      isCustomerInn: false,
      isSectionClientInfo: true,
      isSectionGuarantees: true,
      isSectionContract: true,
      isSectionProductOffers: true,
      eis: '',
      taxSystem: '',
      customerInn: '',
      customerName: '',
      term: 0,
      termEndDate: '',
      termStartDate: '',
      startPrice: 0,
      sumFinal: 0,
      lotCode: null,
      subject: '',
      isClosedAuction: null,
      isSingleSupplier: null,
      tender: null,
      tax: null,
      commercial: null,
      typeComment: '',
      tenderLink: '',
      guaranteesType: GUARANTEES_TYPE['44-FZ'],
      isPrescoring: true,
      isSendApiError: false,
      offerId: null,
      isApplicationPrescoring: false
    });
  }

  navigateFromTo = (from: string, to: string) =>
    this.props.history.push({
      pathname: this.props.match.url.replace(from, to),
      state: TRANSITION_DIRECTIONS.FORWARD
    });

  onChangeBaseFields = (e: React.FormEvent<HTMLInputElement>) => {
    const { name } = e.currentTarget as { name: keyof State };
    const { value, type, checked } = e.currentTarget;
    const isToNumber =
      name === 'amount' ||
      name === 'term' ||
      name === 'startPrice' ||
      name === 'sumFinal';
    const isCheckbox = type === 'checkbox';

    let sanitizedValue = value;

    if (
      name === 'startPrice' ||
      name === 'sumFinal' ||
      name === 'lotCode' ||
      name === 'subject' ||
      name === 'isClosedAuction' ||
      name === 'isSingleSupplier' ||
      name === 'eis' ||
      name === 'guaranteesType' ||
      name === 'tenderLink' ||
      name === 'customerInn' ||
      name === 'customerName'
    ) {
      this.setState({ contractChanged: true });
    }

    if (name === 'code') {
      this.setState({ isCode: true });
    } else if (name === 'customerInn') {
      this.setState({ isCustomerInn: true });
    }

    if (
      (name === 'code' || name === 'customerInn') &&
      sanitizedValue.length > 12
    ) {
      return;
    } else if (
      (name === 'code' || name === 'customerInn') &&
      sanitizedValue.length <= 12
    ) {
      sanitizedValue = sanitizedValue.replace(/[^0-9]/g, '');
      const floated = toFloatFormatter(sanitizedValue);

      this.setState({
        [name]: isToNumber
          ? floated
          : name === 'code' || name === 'customerInn'
            ? sanitizedValue
            : value
      } as any);
    } else {
      const floated = toFloatFormatter(value);

      if (name === 'taxSystem') {
        this.setState({ isTaxSystem: true });
      }

      this.setState({
        [name]: isCheckbox ? checked : isToNumber ? floated : value
      } as any);
    }
  };

  onDateChange = (name: string, date: string) => {
    this.setState({ [name]: date } as any);
  };

  onTermTypeChange = (name: string, value: any) => {
    this.setState({ [name]: value } as any);
  };

  onGuaranteesTypeChange = (name: string, value: any) => {
    this.setState({ [name]: value } as any);
  };

  validateGuaranteesTerm = (isValid: boolean) => {
    this.setState({ isGuaranteesTermValid: isValid });
  };

  showTermErrors = () => {
    this.setState({ showTermErrors: true });
  };

  sendData = () => {
    this.props.sendApplicationData({
      id: +this.props.match.params.id,
      code: this.state.code,
      clientCompanyName: this.state.clientCompanyName,
      customerInn: this.state.customerInn,
      customerName: this.state.customerName,
      amount: this.state.amount,
      refinancing: this.state.refinancingGuarantees,
      term: this.state.term,
      termStartDate: this.state.termStartDate,
      termEndDate: this.state.termEndDate,
      guarantees: {
        isClosedAuction: this.state.isClosedAuction,
        isSingleSupplier: this.state.isSingleSupplier,
        purchaseLink: this.state.tenderLink,
        lotCode: this.state.lotCode && this.state.lotCode.toString(),
        sumFinal: +this.state.sumFinal,
        subject: this.state.subject,
        taxSystem: this.state.taxSystem,
        startPrice: this.state.startPrice,
        commercial: this.state.commercial,
        guaranteesType: this.state.guaranteesType,
        tax: this.state.tax,
        tender: this.state.tender,
        desiredBanks: this.state.desiredBanks,
        tenderComment: this.props.tenderComment,
        typeComment: this.state.typeComment
      }
    });
  };

  onSubmit = () => {
    this.Validator.showAllErrors();
    this.showTermErrors();
    if (!this.isFormValid()) return;

    this.props.resetStateCompanyByInn();
    this.sendData();

    this.navigateFromTo(this.props.path, 'documents');
  };

  sendContract = () => {
    if (this.props.statusTenderByApplication !== REQUEST_STATUSES.GOT) {
      this.props.postCreateTender({
        applicationId: +this.props.match.params.id,
        supplierinn: this.state.code,
        TenderSegment:
          this.state.guaranteesType === GUARANTEES_TYPE['44-FZ']
            ? 1
            : this.state.guaranteesType === GUARANTEES_TYPE['223-FZ']
              ? 2
              : this.state.guaranteesType === GUARANTEES_TYPE['615-P']
                ? 3
                : this.state.guaranteesType === GUARANTEES_TYPE['COMMERCIAL'] ||
                  this.state.guaranteesType === GUARANTEES_TYPE['OTHER']
                  ? 5
                  : null,
        sumFinal: +this.state.sumFinal,
        amount: this.state.amount,
        clientCompanyName: this.state.clientCompanyName,
        customerInn: this.state.customerInn,
        customerName: this.state.customerName,
        isClosedAuction: this.state.isClosedAuction,
        isSingleSupplier: this.state.isSingleSupplier,
        lotCode: this.state.lotCode.toString(),
        subject: this.state.subject,
        refinancing: this.state.refinancingGuarantees,
        taxSystem: this.state.taxSystem,
        term: this.state.term,
        termStartDate: this.state.termStartDate,
        termEndDate: this.state.termEndDate,
        startPrice: this.state.startPrice,
        commercial: this.state.commercial,
        guaranteesType: this.state.guaranteesType,
        tax: this.state.tax,
        tender: this.state.tender,
        desiredBanks: this.state.desiredBanks,
        tenderComment: this.props.tenderComment,
        typeComment: this.state.typeComment,
        tenderLink: this.state.tenderLink
      });
    } else {
      this.props.patchTenderUpdateByInternal(
        this.props.tenderByApplication[0].tender.id.toString(),
        {
          CustomerINN: this.state.customerInn,
          StartPrice: this.state.startPrice,
          TenderLink: this.state.tenderLink,
          Subject: this.state.subject,
          IsClosedAuction: this.state.isClosedAuction,
          IsSingleSupplier: this.state.isSingleSupplier,
          SumFinal: this.state.sumFinal,
          lotCode: this.state.lotCode.toString()
        }
      );
    }
  };

  onPopupClick = (name: string) => {
    if (name === 'isInfoPopupCompanyInn') {
      this.setState({ isInfoPopupCompanyInn: true, isCode: false });

      if (this.props.permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT)) {
        this.props.getCompany(this.state.code);
      }

      this.setState({
        clientCompanyName: this.props.companyByInn.companyShortName
      });
    } else if (name === 'isInfoPopupCustomerInn') {
      this.setState({ isInfoPopupCustomerInn: true, isCustomerInn: false });
      this.setState({
        customerName: this.props.companyByCustomerInn.companyShortName
      });
    }
  };

  handleOutsideClick = (
    event: MouseEvent,
    targetName: React.RefObject<unknown>
  ) => {
    const target = event.target as Element;

    if (
      targetName.current &&
      !(targetName.current as Element).contains(target)
    ) {
      this.setState({ clientsIsVisible: false });
      this.setState({ purchaseIsVisible: false });
      this.setState({ isSendApiError: false });
      this.setState({ isApplicationPrescoring: false });
    }
  };

  handleSelectClient = (client: ClientsListType, index: number) => {
    this.setState({
      selectedRow: index,
      code: client.companyINN,
      clientCompanyName: client.companyName
    });
  };

  handleFilter = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    this.setState({ [name]: value } as any);
  };

  renderToggleButton = (buttonName: keyof State, description?: string) => {
    return (
      <ToggleButtonContainer>
        <ToggleButtonYes
          type="button"
          onClick={() => this.setState({ [buttonName]: true } as any)}
          active={this.state[buttonName]}
        >
          да
        </ToggleButtonYes>
        <ToggleButtonNo
          type="button"
          onClick={() => this.setState({ [buttonName]: false } as any)}
          active={this.state[buttonName]}
        >
          нет
        </ToggleButtonNo>
        <p>{description}</p>
      </ToggleButtonContainer>
    );
  };

  isFormValid = (): boolean => {
    const {
      code,
      clientCompanyName,
      amount,
      guaranteesType,
      tender,
      tax,
      commercial,
      term,
      termStartDate,
      termEndDate,
      tenderLink,
      taxSystem,
      startPrice,
      sumFinal,
      lotCode,
      subject,
      isClosedAuction,
      isSingleSupplier
    } = this.state;

    const { financingType } = this.props;

    const isGuaranteesType = [
      GUARANTEES_TYPE['223-FZ'],
      GUARANTEES_TYPE['44-FZ'],
      GUARANTEES_TYPE['615-P']
    ].includes(guaranteesType);

    const isTermValid =
      (TERM_TYPE.DATES && termStartDate && termEndDate) ||
      (TERM_TYPE.PERIOD && term);

    const isTenderLinkValid = isGuaranteesType
      ? !!tenderLink
      : !isGuaranteesType;

    const isTenderTypeValid =
      tender !== null || tax !== null || commercial !== null
        ? true
        : guaranteesType === GUARANTEES_TYPE.LIMIT && true;

    return (
      financingType &&
      code &&
      clientCompanyName &&
      amount &&
      (this.state.isTeledoc
        ? !!taxSystem &&
          !!startPrice &&
          !!sumFinal &&
          !!lotCode &&
          !!subject &&
          isClosedAuction !== null &&
          isSingleSupplier !== null
        : true) &&
      isTermValid &&
      isTenderLinkValid &&
      isTenderTypeValid
    );
  };

  isPrescoringValid = (): boolean => {
    return (
      !!this.state.taxSystem &&
      !!this.state.termStartDate &&
      !!this.state.termEndDate &&
      !!this.state.startPrice &&
      !!this.state.sumFinal &&
      !!this.state.lotCode &&
      !!this.state.subject &&
      !!this.state.isClosedAuction !== null &&
      !!this.state.isSingleSupplier !== null
    );
  };

  render() {
    const {
      openModal,
      companyByInn,
      statusInn,
      financingType,
      allowFlags: { allowBlankFields },
      statusCompanyByCustomerInn,
      companyByCustomerInn,
      code: inn
    } = this.props;

    const {
      code,
      clientCompanyName,
      isTeledoc,
      isSectionClientInfo,
      isCode,
      isInfoPopupCompanyInn,
      isSectionGuarantees,
      eis,
      refinancingGuarantees,
      isSectionContract,
      isSectionProductOffers,
      clientsIsVisible,
      clientName,
      clientInn,
      selectedRow,
      purchaseIsVisible,
      amount,
      taxSystem,
      isTaxSystem,
      customerName,
      customerInn,
      isCustomerInn,
      startPrice,
      sumFinal,
      lotCode,
      subject,
      term,
      termStartDate,
      termEndDate,
      tender,
      tax,
      commercial,
      tenderLink,
      typeComment,
      guaranteesType,
      desiredBanks,
      isPrescoring,
      isRejection,
      isSendApiError,
      isApplicationPrescoring
    } = this.state;

    const amountRange = {
      min: 0,
      max: 100000000000
    };

    const { errors } = this.Validator.insertArgs({
      amountRange: [amountRange.min, amountRange.max],
      guaranteesType: [{ guaranteesType, tender, tax, commercial }]
    }).validate({ ...this.state });

    const filteredOffers = this.props.allOffers.filter(offer => {
      if (!isRejection && offer.offerBankStatus === 1) {
        return false;
      }
      return true;
    });

    return (
      <ApplicationFormStyled>
        <CloseApplicationBtn
          onClick={() =>
            openModal({ name: MODAL_NAMES.CLOSE_APPLICATION_PROCESS })
          }
        />

        {!!inn ? (
          <ApplicationStepTitle>Редактирование заявки</ApplicationStepTitle>
        ) : (
          <ApplicationStepTitle>Оформление заявки</ApplicationStepTitle>
        )}

        {this.props.statusCreateEmpty === REQUEST_STATUSES.REQUEST && (
          <Loader />
        )}

        <ApplicationFormContent>
          <ApplicationInputsColumn>
            <ApplicationForm
              onSubmit={this.onSubmit}
              name="initialize-application-form"
            >
              <FlexContainer>
                <Select
                  options={Object.keys(ApplicationTypes).map(type => ({
                    id: type,
                    name: ApplicationTypes[type]
                  }))}
                  value={financingType}
                  label="Тип продукта"
                  name="financingType"
                  placeholder="Выберите тип продукта"
                  required={true}
                  disabled={true}
                  error={errors.financingType}
                  onChange={this.onChangeBaseFields}
                />

                {this.props.permissions.includes(
                  USER_PERMISSIONS.INTERNAL_AGENT
                ) && (
                  <TeledocContainer
                    isTeledoc={isTeledoc}
                    onClick={() =>
                      this.setState(prevState => ({
                        ...prevState,
                        isTeledoc: !prevState.isTeledoc
                      }))
                    }
                  >
                    {isTeledoc ? (
                      <FontAwesomeIcon icon={faEye} />
                    ) : (
                      <FontAwesomeIcon icon={faEyeSlash} />
                    )}
                    <p>Отображать поля, необходимые для внешнего API TELEDOC</p>
                  </TeledocContainer>
                )}
              </FlexContainer>

              <TitleTabContainer>
                <p>Информация о клиенте (Принципале)</p>
                <FontAwesomeIcon
                  icon={isSectionClientInfo ? faChevronUp : faChevronDown}
                  onClick={() =>
                    this.setState(prevState => ({
                      ...prevState,
                      isSectionClientInfo: !prevState.isSectionClientInfo
                    }))
                  }
                />
              </TitleTabContainer>

              {isSectionClientInfo && (
                <>
                  <PopupContainer>
                    <InputContainer>
                      <Input
                        value={code}
                        label="ИНН принципала (клиента)"
                        name="code"
                        placeholder={InnPlaceholders.entity}
                        required={true}
                        warning={errors.code}
                        onChange={this.onChangeBaseFields}
                      />

                      {statusInn === REQUEST_STATUSES.REQUEST && (
                        <SpinnerApi icon={faSpinner} size={20} />
                      )}
                    </InputContainer>

                    {statusInn === REQUEST_STATUSES.GOT &&
                      isCode &&
                      code &&
                      code.length >= 10 &&
                      !isInfoPopupCompanyInn && (
                        <InnPopup
                          companyInfo={companyByInn}
                          onPopupClick={this.onPopupClick}
                          name="isInfoPopupCompanyInn"
                        />
                      )}
                  </PopupContainer>

                  <Input
                    value={clientCompanyName}
                    label="Наименование принципала (клиента)"
                    name="clientCompanyName"
                    placeholder="Введите крактое наименование клиента"
                    required={true}
                    error={errors.clientCompanyName}
                    onChange={this.onChangeBaseFields}
                  />

                  {isTeledoc && (
                    <TaxSystemContainer>
                      <Select
                        options={Object.keys(TaxSystemType).map(type => ({
                          id: type,
                          name: TaxSystemType[type]
                        }))}
                        value={taxSystem}
                        label="Система налогообложения"
                        name="taxSystem"
                        placeholder="не заполнено"
                        required={this.state.isTeledoc}
                        error={errors.taxSystem}
                        onChange={this.onChangeBaseFields}
                      />

                      {isTaxSystem &&
                        code && (
                          <Button
                            label="Сохранить"
                            onClick={() => {
                              this.setState({ isTaxSystem: false });
                              this.props.patchUpdateCompanyBlank(code, {
                                taxSystem: +taxSystem
                              });
                            }}
                          />
                        )}
                    </TaxSystemContainer>
                  )}
                </>
              )}

              <TitleTabContainer>
                <p>Информация о банковской гарантии</p>
                <FontAwesomeIcon
                  icon={isSectionGuarantees ? faChevronUp : faChevronDown}
                  onClick={() =>
                    this.setState(prevState => ({
                      ...prevState,
                      isSectionGuarantees: !prevState.isSectionGuarantees
                    }))
                  }
                />
              </TitleTabContainer>

              {isSectionGuarantees && (
                <>
                  <EISContainer>
                    {this.props.statusTenderByApplication ===
                      REQUEST_STATUSES.GOT &&
                    this.props.tenderByApplication[0].tender.eruzId === null ? (
                      <></>
                    ) : (
                      <Input
                        name="eis"
                        label="Номер ЕИС(не ссылка)"
                        placeholder="не заполнено"
                        value={eis}
                        onChange={e =>
                          this.setState({ eis: e.currentTarget.value })
                        }
                      />
                    )}

                    {this.props.statusTenderByApplication !==
                      REQUEST_STATUSES.GOT && (
                      <Button
                        label="Найти в ЕИС"
                        onClick={() => this.props.getTenderTeledoc('7', eis)}
                        disabled={!eis}
                      />
                    )}
                  </EISContainer>

                  <InputRange
                    value={amount.toFixed(2)}
                    label="Сумма"
                    name="amount"
                    placeholder="Введите сумму"
                    min={amountRange.min}
                    max={amountRange.max}
                    step={1000}
                    sliderType={RANGE_SLIDER_TYPE.SUM}
                    valueType={INPUT_RANGE_VALUE_TYPE.SUM}
                    disableSlider={true}
                    required={true}
                    error={errors.amount}
                    onChange={this.onChangeBaseFields}
                  />

                  <GuaranteesTypeRadioGroup
                    required={true}
                    errors={errors}
                    commercial={commercial}
                    tax={tax}
                    guaranteesType={guaranteesType}
                    tender={tender}
                    typeComment={typeComment}
                    tenderLink={tenderLink}
                    onChange={this.onChangeBaseFields}
                    isTeledoc={this.state.isTeledoc}
                    tenderLinkRequired={[
                      GUARANTEES_TYPE['223-FZ'],
                      GUARANTEES_TYPE['44-FZ'],
                      GUARANTEES_TYPE['615-P']
                    ].includes(guaranteesType)}
                  />

                  {[
                    GUARANTEES_TYPE['223-FZ'],
                    GUARANTEES_TYPE['44-FZ'],
                    GUARANTEES_TYPE['615-P'],
                    GUARANTEES_TYPE['COMMERCIAL']
                  ].includes(guaranteesType) && (
                    <>
                      <PopupContainer>
                        <InputContainer>
                          <Input
                            value={customerInn}
                            label="ИНН бенефициара (заказчика)"
                            name="customerInn"
                            placeholder={InnPlaceholders.entity}
                            warning={errors.customerInn}
                            onChange={this.onChangeBaseFields}
                          />

                          {statusCompanyByCustomerInn ===
                            REQUEST_STATUSES.GOT &&
                            isCustomerInn &&
                            customerInn &&
                            customerInn.length >= 10 &&
                            !this.state.isInfoPopupCustomerInn && (
                              <InnPopup
                                companyInfo={companyByCustomerInn}
                                onPopupClick={this.onPopupClick}
                                name="isInfoPopupCustomerInn"
                              />
                            )}
                        </InputContainer>
                      </PopupContainer>

                      <Input
                        label="Наименование бенефициара (заказчика)"
                        name="customerName"
                        value={customerName}
                        placeholder="Введите краткое наименование бенефициара (заказчика)"
                        onChange={this.onChangeBaseFields}
                      />
                    </>
                  )}

                  <GuaranteesTerm
                    errors={errors}
                    term={term}
                    termStartDate={termStartDate}
                    termEndDate={termEndDate}
                    onChangeBaseFields={this.onChangeBaseFields}
                    onDateChange={this.onDateChange}
                    onTermTypeChange={this.onTermTypeChange}
                    required={true}
                    isTeledoc={this.state.isTeledoc}
                  />

                  {!this.props.permissions.includes(
                    USER_PERMISSIONS.INTERNAL_AGENT
                  ) && (
                    <Textarea
                      label="Пожелания по банкам"
                      name="desiredBanks"
                      value={desiredBanks}
                      onChange={this.onChangeBaseFields}
                      required={!allowBlankFields}
                      placeholder="Напр., “любые банки” или “строго только банк XXX”"
                    />
                  )}

                  <Checkbox
                    name="refinancing"
                    label="Переобеспечение"
                    checked={refinancingGuarantees}
                    onChange={() =>
                      this.setState(prevState => ({
                        refinancingGuarantees: !prevState.refinancingGuarantees
                      }))
                    }
                  />
                </>
              )}

              {isTeledoc && (
                <>
                  <TitleTabContainer>
                    <p>Информация о контракте</p>
                    <FontAwesomeIcon
                      icon={isSectionContract ? faChevronUp : faChevronDown}
                      onClick={() =>
                        this.setState(prevState => ({
                          ...prevState,
                          isSectionContract: !prevState.isSectionContract
                        }))
                      }
                    />
                  </TitleTabContainer>

                  {isSectionContract && (
                    <>
                      <InputRange
                        value={startPrice.toFixed(2)}
                        step={1000}
                        min={amountRange.min}
                        max={amountRange.max}
                        sliderType={RANGE_SLIDER_TYPE.SUM}
                        valueType={INPUT_RANGE_VALUE_TYPE.SUM}
                        disableSlider={true}
                        required
                        name="startPrice"
                        label="Начальная цена контракта"
                        onChange={this.onChangeBaseFields}
                      />

                      <InputRange
                        value={sumFinal.toFixed(2)}
                        step={1000}
                        min={amountRange.min}
                        max={amountRange.max}
                        sliderType={RANGE_SLIDER_TYPE.SUM}
                        valueType={INPUT_RANGE_VALUE_TYPE.SUM}
                        disableSlider={true}
                        required
                        name="sumFinal"
                        label="Итоговая цена контракта"
                        onChange={this.onChangeBaseFields}
                      />

                      <Input
                        required
                        name="lotCode"
                        label="Номер лота"
                        value={lotCode}
                        onChange={this.onChangeBaseFields}
                      />

                      <Input
                        required
                        name="subject"
                        label="Предмет контракта"
                        value={subject}
                        placeholder="Введите предмет контракта"
                        onChange={this.onChangeBaseFields}
                      />

                      {this.renderToggleButton(
                        'isClosedAuction',
                        'Закрытый аукцион'
                      )}
                      {this.renderToggleButton(
                        'isSingleSupplier',
                        'Закупка у единственного поставщика'
                      )}

                      {this.state.guaranteesType !==
                        GUARANTEES_TYPE['LIMIT'] && (
                        <Button
                          label="Сохранить контракт"
                          onClick={this.sendContract}
                          disabled={
                            !this.isFormValid() ||
                            this.state.contractChanged === false
                          }
                        />
                      )}
                    </>
                  )}
                </>
              )}

              <TitleTabContainer>
                <div>
                  <p>Продуктовые предложения</p>
                  <div>
                    {isRejection ? (
                      <RejectionToggle
                        onClick={() => this.setState({ isRejection: false })}
                      >
                        <FontAwesomeIcon icon={faEye} />
                        <p>Отказы показаны</p>
                      </RejectionToggle>
                    ) : (
                      <RejectionToggle
                        onClick={() => this.setState({ isRejection: true })}
                      >
                        <FontAwesomeIcon icon={faEyeSlash} />
                        <p>Отказы скрыты</p>
                      </RejectionToggle>
                    )}
                  </div>

                  <RejectionToggle
                    onClick={() =>
                      this.props.getAllOffers(this.props.match.params.id)
                    }
                  >
                    <RefreshIcon
                      icon={faSyncAlt}
                      refresh={
                        this.props.statusAllOffers === REQUEST_STATUSES.REQUEST
                      }
                    />
                    <p>Обновить</p>
                  </RejectionToggle>
                </div>

                <FontAwesomeIcon
                  icon={isSectionProductOffers ? faChevronUp : faChevronDown}
                  onClick={() =>
                    this.setState(prevState => ({
                      ...prevState,
                      isSectionProductOffers: !prevState.isSectionProductOffers
                    }))
                  }
                />
              </TitleTabContainer>

              {isSectionProductOffers && (
                <ProductOffersSection>
                  {!this.props.allOffers.some(
                    offer => offer.apiName === 'Teledoc'
                  ) &&
                    this.props.statusPostApplicationPrescoring !==
                      REQUEST_STATUSES.GOT && (
                      <ButtonTeledocContainer>
                        <Button
                          label="Отправить на скоринг TELEDOC"
                          disabled={
                            !this.isPrescoringValid() ||
                            this.props.tenderByApplication.length === 0 ||
                            this.props.statusSendApplication ===
                              REQUEST_STATUSES.REQUEST ||
                            this.props.statusPostApplicationPrescoring ===
                              REQUEST_STATUSES.REQUEST
                          }
                          onClick={() => {
                            this.setState({ isPrescoring: false });
                            this.sendData();
                          }}
                        />
                        {(this.props.statusSendApplication ===
                          REQUEST_STATUSES.REQUEST ||
                          this.props.statusPostApplicationPrescoring ===
                            REQUEST_STATUSES.REQUEST) && (
                          <SpinnerApi icon={faSpinner} />
                        )}
                      </ButtonTeledocContainer>
                    )}

                  {!!this.props.allOffers.length && (
                    <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                      <TableHeaderStyled>
                        <tr>
                          <TableThStyled width="15%">Банк</TableThStyled>
                          <TableThStyled width="15%">API</TableThStyled>
                          <TableThStyled width="15%">Ставка</TableThStyled>
                          <TableThStyled width="15%">КВ Банка</TableThStyled>
                          <TableThStyled width="20%">
                            Статус Банка
                          </TableThStyled>
                          <TableThStyled width="20%">
                            Отправка API
                          </TableThStyled>
                        </tr>
                      </TableHeaderStyled>
                      <tbody>
                        {filteredOffers.map(offer => (
                          <TableRow key={offer.id}>
                            <td>
                              <div>{offer.bankName}</div>
                              <div>{offer.bankInn}</div>
                            </td>
                            <td>{offer.apiName}</td>
                            <td>
                              <div>{offer.rate.toFixed(2)} % годовых</div>
                              <div>
                                {formSumStringThousands(offer.price)} руб.
                              </div>
                            </td>
                            <td>
                              <div>{offer.bankCommissionRateToUs} %</div>
                              <div>
                                {formSumStringThousands(
                                  offer.bankCommissionToUs
                                )}{' '}
                                руб.
                              </div>
                            </td>
                            <td>
                              {offer.offerBankStatus === 1
                                ? 'Отказ банка'
                                : offer.offerBankStatus === 2
                                  ? 'Прошел прескоринг'
                                  : offer.offerBankStatus === 3
                                    ? 'Одобрен'
                                    : offer.offerBankStatus === 4 &&
                                      'Сделка заключена'}
                            </td>
                            <td>
                              {offer.offerApi === '7' &&
                              offer.offerExternalParam2 === null ? (
                                <SendApi
                                  onClick={() => {
                                    this.setState({ offerId: offer.id });
                                    this.sendData();
                                  }}
                                >
                                  <span>Отправить заявку</span>
                                  {(this.props.statusSendApplication ===
                                    REQUEST_STATUSES.REQUEST ||
                                    this.props.statusSendApi ===
                                      REQUEST_STATUSES.REQUEST) &&
                                    this.state.offerId === offer.id && (
                                      <SpinnerApi icon={faSpinner} />
                                    )}
                                </SendApi>
                              ) : offer.offerExternalParam2 !== null &&
                              offer.apiName === 'Teledoc' &&
                              offer.offerExternalParam3 !== null ? (
                                <SendApi>
                                  <p>Отправлена по API</p>{' '}
                                </SendApi>
                              ) : (
                                ''
                              )}
                            </td>
                          </TableRow>
                        ))}
                      </tbody>
                    </TableStyled>
                  )}
                </ProductOffersSection>
              )}

              <ButtonContainer>
                <TooltipBoxStyle
                  isWarning={!this.isFormValid()}
                  text="не заполнены все обязательные поля"
                >
                  <ButtonStyle
                    notValid={!this.isFormValid()}
                    type="submit"
                    label="Настроить параметры заявки"
                    template="nextBtn"
                  />
                </TooltipBoxStyle>
              </ButtonContainer>
            </ApplicationForm>
          </ApplicationInputsColumn>
        </ApplicationFormContent>

        <ModalContainer
          visible={purchaseIsVisible}
          onClick={e => this.handleOutsideClick(e, this.purchaseModalRef)}
        >
          <PurchaseContent ref={this.purchaseModalRef}>
            {this.props.statusTenderTeledoc === REQUEST_STATUSES.GOT ? (
              <>
                <h2>Найдена закупка</h2>

                <p>
                  Тип:{' '}
                  {!!this.props.tenderTeledoc.length &&
                    TENDER_SEGMENT[this.props.tenderTeledoc[0].tenderSegment]}
                </p>
                <div>
                  <p>Предмет контракта:</p>
                  <p>
                    {!!this.props.tenderTeledoc.length &&
                      this.props.tenderTeledoc[0].subject}
                  </p>
                </div>
                <div>
                  <p>Заказчик:</p>
                  <p>
                    {!!this.props.tenderTeledoc.length &&
                      this.props.tenderTeledoc[0].customerName}
                  </p>
                </div>

                <Button
                  label="Добавить в заявку"
                  onClick={() => {
                    this.setState({ purchaseIsVisible: false });
                    this.props.getAddTenderByEruz(
                      this.props.id.toString(),
                      !!this.props.tenderTeledoc.length &&
                        this.props.tenderTeledoc[0].eruzId
                    );
                  }}
                />
              </>
            ) : (
              this.props.statusTenderTeledoc === REQUEST_STATUSES.ERROR && (
                <>
                  <h2>Ошибка</h2>
                  <p>Закупка не найдена</p>
                </>
              )
            )}
          </PurchaseContent>
        </ModalContainer>

        <ModalContainer
          visible={isSendApiError}
          onClick={e => this.handleOutsideClick(e, this.sendApiErrorModalRef)}
        >
          <PurchaseContent ref={this.sendApiErrorModalRef}>
            <p>{this.props.sendApiError}</p>
          </PurchaseContent>
        </ModalContainer>

        <ModalContainer
          visible={isApplicationPrescoring}
          onClick={e =>
            this.handleOutsideClick(e, this.applicationPrescoringErrorModalRef)
          }
        >
          <PurchaseContent ref={this.applicationPrescoringErrorModalRef}>
            <p>{this.props.errorPostApplicationPrescoring}</p>
          </PurchaseContent>
        </ModalContainer>
      </ApplicationFormStyled>
    );
  }
}

// TODO loader on submit
const mapStateToProps = ({
  Application,
  InternalAgentRole,
  User,
  SCF,
  Companies
}: STORE & CRM) => ({
  applicationId: Application.createApplication.id,
  statusCreateEmpty: Application.createApplication.status,
  companyByInn: SCF.getCompanyByInnThirdParty.data,
  statusInn: SCF.getCompanyByInnThirdParty.status,
  error: SCF.getCompanyByInnThirdParty.error,
  companyByCustomerInn: Application.getCompanyByInn.data,
  statusCompanyByCustomerInn: Application.getCompanyByInn.status,
  uploadStatus: Application.uploadDocuments.status,
  permissions: User.getUserData.data.permissions,
  roles: User.getUserData.data.roles,
  userCompanyInn: User.getUserData.data.companyInn,
  allowFlags: Application.getApplicationFlags.data,
  statusTaxSystem: Application.patchUpdateCompanyBlank.status,
  companyInn: Companies.getCompany.company.inn,
  companyShortName: Companies.getCompany.company.companyShortName,
  companyTaxSystem: Companies.getCompany.company.taxSystem,
  statusCompanyTaxSystem: Companies.getCompany.status,
  tenderTeledoc: Application.getTenderTeledoc.data,
  statusTenderTeledoc: Application.getTenderTeledoc.status,
  statusAddTenderByEruz: Application.getAddTenderByEruz.status,
  ...InternalAgentRole.getInternalApplication.data,
  statusGetInternalApplication: InternalAgentRole.getInternalApplication.status,
  tenderByApplication: Application.getTenderByApplication.data,
  statusTenderByApplication: Application.getTenderByApplication.status,
  allOffers: Application.getAllOffers.data,
  statusAllOffers: Application.getAllOffers.status,
  statusSendApi: Application.postOfferSend.status,
  sendApiError: Application.postOfferSend.error.body,
  idCreateTender: Application.postCreateTender.id,
  statusCreateTender: Application.postCreateTender.status,
  statusSendApplication: Application.sendApplicationData.status,
  statusPostApplicationPrescoring: Application.postApplicationPrescoring.status,
  errorPostApplicationPrescoring:
    Application.postApplicationPrescoring.error.body,
  statusPatchTenderUpdateByInternal:
    InternalAgentRole.patchTenderUpdateByInternal.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      sendApplicationData,
      resetApplicationData,
      openModal,
      getCompanyByInn,
      getCompanyByCustomerInn,
      resetStateCompanyByInn,
      patchUpdateCompanyBlank,
      getCompany,
      getTenderTeledoc,
      getAddTenderByEruz,
      getTenderByApplication,
      resetTenderByApplication,
      getAllOffers,
      postApplicationPrescoring,
      postOfferSend,
      resetOfferSend,
      postCreateTender,
      postAddTenderById,
      resetApplicationPrescoring,
      resetAllOffers,
      patchTenderUpdateByInternal
    },
    dispatch
  );

const GuaranteesFormStep0Connect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(GuaranteesFormStep0)
);

export { GuaranteesFormStep0Connect as GuaranteesFormStep0 };