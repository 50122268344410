import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { differenceInDays, format } from 'date-fns';

import { CertificatesListPopup } from './CertificatesListPopup/CertificatesListPopup';
import { EconomyEffect } from './EconomyEffect';

import { Loader } from 'Layouts/components';
import {
  req as getMonetaryClaimsDebtorEarlyPaymentApplications,
  RequestDataType,
  Calc as ResponseDataType
} from 'entities/SCF/Debtor/model/actions/getMonetaryClaimsDebtorEarlyPaymentApplicationsByGuid';
import {
  req as getInvestorOneDebtor,
  RequestDataType as OneDebtorRequestDataType,
  ResponseDataType as OneDebtorResponseDataType
} from 'entities/SCF/Debtor/model/actions/getOneDebtorRoleByGuid';
import { RequestDataType as PutEarlyPaymentApplicationDeclineDataType } from 'entities/SCF/Debtor/model/reducers/putEarlyPaymentApplicationDecline';
import { req as putEarlyPaymentApplicationDecline } from 'entities/SCF/Debtor/model/actions/putEarlyPaymentApplicationDecline';
import {
  req as approveEarlyPaymentApplication,
  reset as approveEarlyPaymentApplicationReset
} from 'entities/SCF/Debtor/model/actions/approveEarlyPaymentApplicationDebtorRole';
import {
  req as getByMonetaryClaimHint,
  ResponseData as ResponseMonetaryClaimHint,
  reset as resetByMonetaryClaimHint
} from 'entities/SCF/Debtor/model/actions/getByMonetaryClaimDebtor';
import { RequestDataType as ApproveEarlyPaymentApplicationRequestDataType } from 'entities/SCF/Debtor/model/reducers/approveEarlyPaymentApplicationDebtorRole';
import {
  CreateLimitsNudge,
  BtnsBlock,
  CreateBtn,
  CreateBtnText,
  ListSection,
  FullList,
  ListSectionOne,
  FullListOne,
  BackBtn,
  BackBtnText,
  ConfirmSection,
  ConfirmSectionRightPart,
  DiscountItem,
  SignSection,
  DatePopup,
  DatePopupBtns,
  DatesContainer,
  CancelBtn,
  CancelBtnText,
  CreateBtnPopup,
  CreateBtnContainer,
  DownloadLink,
  ListAndEffect,
  LeftContainer,
  ApprovedMessages,
  ApprovedMessagesContainer,
  TDInvoiceDocumenContainer,
  InvoiceDocumentDate,
  DocumentHint,
  SkeletonWrapper,
  CopyBlock,
  Status
} from './styles';
import {
  CertificatesArea,
  CertificatesPopup,
  CertificatesPopupError,
  Nudge,
  NudgeArea,
  PopupExit,
  PopupExitError,
  PageNoMarginStyled
} from 'src/features/SCF/UI/ScfStyles';
import {
  STORE,
  REQUEST_STATUSES,
  ResponseError,
  USER_PERMISSIONS
} from 'globaltypes';
import {
  TableHeaderCellStyled,
  TableHeaderCellCenteredStyled,
  TableStyled
} from 'src/features/SCF/UI/Table/styles';
import {
  TableRowStyled,
  TdCentred
} from 'src/features/SCF/UI/Table/TableRow/styles';
import { TableHeaderStyled } from 'src/features/SCF/UI/Table/TableHeader/styles';
import SCFDebtorApi from 'entities/SCF/Debtor/api';
import { Textarea } from 'shared/ui/Textarea';
import { formSumStringThousands } from 'src/shared/utils/Utils';

interface StateToProps {
  status: REQUEST_STATUSES;
  error: ResponseError;
  monetaryClaimsEarlyPaymentApplications: ResponseDataType[];
  investorOneDebtor: OneDebtorResponseDataType;
  approveStatus: REQUEST_STATUSES;
  approveError: ResponseError;
  permissions: USER_PERMISSIONS[];
  monetaryClaimsHint: ResponseMonetaryClaimHint[];
  statusMonetaryClaimsHint: REQUEST_STATUSES;
}

interface DispatchToProps {
  getMonetaryClaimsDebtorEarlyPaymentApplications: (
    data: RequestDataType
  ) => void;
  getInvestorOneDebtor: (data: OneDebtorRequestDataType) => void;
  putEarlyPaymentApplicationDecline: (
    data: PutEarlyPaymentApplicationDeclineDataType
  ) => void;
  approveEarlyPaymentApplication: (
    data: ApproveEarlyPaymentApplicationRequestDataType
  ) => void;
  getByMonetaryClaimHint: (guid: string) => void;
  resetByMonetaryClaimHint: () => void;
}

interface State {
  commentState: string;
  updateChecker: number;
  debtorInspectorCommentState: string;
  isCopied: boolean;
}

interface MatchParams {
  guid: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

class ApplicationInfo extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    {
      this.state = {
        commentState: '',
        debtorInspectorCommentState: '',
        updateChecker: 0,
        isCopied: false
      };
      this.handleCommentStateChange = this.handleCommentStateChange.bind(this);
      this.handleDebtorInspectorCommentStateChange = this.handleDebtorInspectorCommentStateChange.bind(
        this
      );
      this.handleUpdateCheckerChange = this.handleUpdateCheckerChange.bind(
        this
      );
    }
  }

  handleCommentStateChange(event) {
    this.setState({ commentState: event.target.value });
  }

  handleDebtorInspectorCommentStateChange(event) {
    this.setState({ debtorInspectorCommentState: event.target.value });
  }

  handleUpdateCheckerChange() {
    this.setState({ updateChecker: this.state.updateChecker + 1 });
  }

  initFetch = () => {
    const {
      getInvestorOneDebtor,
      getMonetaryClaimsDebtorEarlyPaymentApplications,
      match
    } = this.props;
    getInvestorOneDebtor({ guid: match.params.guid });
    getMonetaryClaimsDebtorEarlyPaymentApplications({
      guid: match.params.guid
    });
  };

  componentDidMount() {
    this.initFetch();

    const base64ToBlob = (base64, type = 'application/octet-stream') => {
      const binStr = atob(base64);
      const len = binStr.length;
      const arr = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt(i);
      }
      return new Blob([arr], { type: type });
    };

    const arrayBufferToBase64 = buffer => {
      let binary = '';
      const bytes = new Uint8Array(buffer);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    };

    try {
      fetch(
        `/api/early_payment_applications/debtor_role/${
          this.props.match.params.guid
        }/get_agreement_html_file`
      )
        .then(res => res.arrayBuffer())
        .then(data => {
          var base64Str = arrayBufferToBase64(data);
          const blob = base64ToBlob(base64Str, 'application/pdf');
          const url = URL.createObjectURL(blob);
          var outputObject = document.createElement('iframe');
          var outputText = document.createElement('p');
          outputText.style.margin = '30px 0 8px 0';
          outputText.style.fontFamily = 'Roboto-Regular';
          outputText.innerHTML = '* Превью договора';
          outputObject.src = url + '#toolbar=0';
          outputObject.style.width = '1200px';
          outputObject.style.height = '1000px';
          outputObject.style.background = '#262B41';
          document.getElementById('documentObject').appendChild(outputText);
          document.getElementById('documentObject').appendChild(outputObject);
        });
    } catch (error) {
      console.error(error);
    }
  }

  componentDidUpdate(prevProps, prevState: State) {
    if (this.state.updateChecker !== prevState.updateChecker) {
      this.initFetch();
    }
    if (
      this.props.approveStatus === REQUEST_STATUSES.GOT &&
      prevProps.approveStatus !== REQUEST_STATUSES.GOT
    ) {
      approveEarlyPaymentApplicationReset();
      this.initFetch();
    }
  }

  onBackClick = () => {
    this.props.history.push(`/debtor/discount_applications?page=1`);
  };

  onCancelClick = () => {
    let datePopup = document.getElementById('datePopup');
    datePopup.style.display = 'none';
    this.setState({ commentState: '' });
  };

  onCancelApproveClick = () => {
    let approvePopup = document.getElementById('approvePopup');
    approvePopup.style.display = 'none';
    this.setState({ debtorInspectorCommentState: '' });
  };

  onDeclineClick = () => {
    let datePopup = document.getElementById('datePopup');
    datePopup.style.display = 'flex';
  };

  onCloseIconClick = () => {
    const sign = document.getElementById('certificatesPopup');
    sign.style.display = 'none';
    window.location.reload();
  };

  onSignClick = () => {
    const sign = document.getElementById('certificatesPopup');
    sign.style.display = 'flex';
  };

  onApproveClick = () => {
    const approve = document.getElementById('approvePopup');
    approve.style.display = 'flex';
  };

  onCloseErrorIconClick = () => {
    const sign = document.getElementById('certificatesPopupError');
    sign.style.display = 'none';
  };

  onNudgeClick = () => {
    this.props.history.push({ pathname: `/debtor/settings/certificates/edit` });
  };

  delay = (ms: number) => new Promise(res => setTimeout(res, ms));

  onDeclineBtnClick = async () => {
    let datePopup = document.getElementById('datePopup');
    await this.delay(700);
    datePopup.style.display = 'none';
    this.setState({ commentState: '' });
    this.handleUpdateCheckerChange();
  };

  onApproveBtnClick = () => {
    this.props.approveEarlyPaymentApplication({
      guid: this.props.match.params.guid,
      body: {
        debtorInspectorComment: this.state.debtorInspectorCommentState
      }
    });
    let approvePopup = document.getElementById('approvePopup');
    approvePopup.style.display = 'none';
    this.setState({ commentState: '' });
    this.handleUpdateCheckerChange();
  };

  intermediateDiscount(): number {
    if (this.props.monetaryClaimsEarlyPaymentApplications[0].baseSumm > 0) {
      return (
        Math.round(this.props.investorOneDebtor.baseDiscountRate * 1.2 * 1000) /
        1000
      );
    }
    return (
      Math.round(this.props.investorOneDebtor.baseDiscountRate * 1000) / 1000
    );
  }

  fullRate(i: number): number {
    return (
      Math.round(
        this.intermediateDiscount() *
          (differenceInDays(
            new Date(
              this.props.monetaryClaimsEarlyPaymentApplications[i].maturityDate
            ),
            new Date(this.props.investorOneDebtor.payDate)
          ) /
            365) *
          10000
      ) /
      10000 /
      100
    );
  }

  summToGet(i: number): number {
    return (
      this.props.monetaryClaimsEarlyPaymentApplications[i].baseFullSumm *
      (1 - this.fullRate(i))
    );
  }

  DiscountCounter() {
    let discountCounter: number;
    discountCounter = 0;
    for (
      let i = 0;
      i < this.props.monetaryClaimsEarlyPaymentApplications.length;
      i++
    ) {
      discountCounter += this.props.monetaryClaimsEarlyPaymentApplications[i]
        .baseFullSumm;
    }
    return discountCounter;
  }

  index(): number {
    if (this.props.monetaryClaimsEarlyPaymentApplications[0].baseSumm === 0) {
      return 1;
    }
    return 1.2;
  }

  DiscountCounterToGet() {
    let discountCounterToGet: number;
    discountCounterToGet = 0;
    for (
      let i = 0;
      i < this.props.monetaryClaimsEarlyPaymentApplications.length;
      i++
    ) {
      discountCounterToGet += this.summToGet(i);
    }
    return discountCounterToGet;
  }

  handleCopyClick = (text: string) => {
    navigator.clipboard.writeText(text);
    this.setState({ isCopied: true });
  };

  render() {
    const {
      status,
      monetaryClaimsEarlyPaymentApplications,
      investorOneDebtor,
      approveStatus,
      permissions,
      getByMonetaryClaimHint,
      resetByMonetaryClaimHint,
      monetaryClaimsHint,
      statusMonetaryClaimsHint
    } = this.props;
    {
      approveStatus === REQUEST_STATUSES.REQUEST && <Loader />;
    }
    return (
      <PageNoMarginStyled>
        <CreateLimitsNudge>
          <CopyBlock
            text={
              investorOneDebtor.guid && !this.state.isCopied
                ? 'Скопировать'
                : this.state.isCopied && 'Скопировано!'
            }
            onClick={() => this.handleCopyClick(investorOneDebtor.guid)}
            onMouseLeave={() => this.setState({ isCopied: false })}
          >
            <h2>
              Заявка #
              {investorOneDebtor.guid
                .substring(investorOneDebtor.guid.length - 5)
                .toLocaleUpperCase()}
            </h2>
          </CopyBlock>
          <BackBtn onClick={() => this.onBackClick()}>
            <BackBtnText>Назад</BackBtnText>
          </BackBtn>
        </CreateLimitsNudge>

        <Status
          status={
            investorOneDebtor.status === 12
              ? 'Ожидает согласования'
              : investorOneDebtor.status === 13
                ? 'Ожидает подписания'
                : investorOneDebtor.status === 14
                  ? 'Отклонена'
                  : investorOneDebtor.status === 16 && 'Подписана'
          }
        >
          {investorOneDebtor.status === 12
            ? 'Ожидает согласования'
            : investorOneDebtor.status === 13
              ? 'Ожидает подписания'
              : investorOneDebtor.status === 14
                ? 'Отклонена'
                : investorOneDebtor.status === 16 && 'Подписана'}
        </Status>

        <ListSectionOne>
          <FullListOne>
            <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
              <TableHeaderStyled>
                <tr>
                  <TableHeaderCellStyled width="50%">
                    ПОКУПАТЕЛЬ
                  </TableHeaderCellStyled>
                  <TableHeaderCellStyled width="50%">
                    ПОСТАВЩИК
                  </TableHeaderCellStyled>
                </tr>
              </TableHeaderStyled>
              <tbody>
                <TableRowStyled style={{ height: '20px' }}>
                  <td style={{ fontFamily: 'Roboto-Regular' }}>
                    {investorOneDebtor.debtorName}
                  </td>
                  <td style={{ fontFamily: 'Roboto-Regular' }}>
                    {investorOneDebtor.supplierName}
                  </td>
                </TableRowStyled>
              </tbody>
            </TableStyled>
          </FullListOne>
        </ListSectionOne>
        <ListAndEffect>
          <LeftContainer>
            <ListSection>
              <FullList>
                {status === REQUEST_STATUSES.REQUEST && <Loader />}
                {status === REQUEST_STATUSES.GOT && (
                  <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
                    <TableHeaderStyled>
                      <tr>
                        <TableHeaderCellCenteredStyled width="5%">
                          ID
                        </TableHeaderCellCenteredStyled>
                        <TableHeaderCellCenteredStyled width="20%">
                          СУММА
                        </TableHeaderCellCenteredStyled>
                        <TableHeaderCellCenteredStyled width="15%">
                          ДОКУМЕНТЫ
                        </TableHeaderCellCenteredStyled>
                        <TableHeaderCellCenteredStyled width="12%">
                          ДАТА ОПЛАТЫ
                        </TableHeaderCellCenteredStyled>
                        <TableHeaderCellCenteredStyled width="16%">
                          СРОК ДИСКОНТА ДН.
                        </TableHeaderCellCenteredStyled>
                        <TableHeaderCellStyled
                          width="16%"
                          style={{ paddingTop: '13.5px' }}
                        >
                          СТАВКА ДИСКОНТА
                          <br />
                          %годовых
                        </TableHeaderCellStyled>
                        <TableHeaderCellCenteredStyled width="10%">
                          ДИСКОНТ
                        </TableHeaderCellCenteredStyled>
                        <TableHeaderCellCenteredStyled width="15%">
                          СУММА К ПОЛУЧЕНИЮ
                        </TableHeaderCellCenteredStyled>
                      </tr>
                    </TableHeaderStyled>
                    <tbody>
                      {monetaryClaimsEarlyPaymentApplications.map(
                        (item, key) => (
                          <TableRowStyled key={key}>
                            <TdCentred style={{ fontFamily: 'Roboto-Regular' }}>
                              {item.guid.substring(item.guid.length - 5)}
                            </TdCentred>
                            <TdCentred>
                              {item.baseFullSumm.toLocaleString('ru', {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2
                              })}
                            </TdCentred>
                            <TDInvoiceDocumenContainer>
                              <div>
                                <div>
                                  <p>
                                    {item.invoiceDocumentName}{' '}
                                    {item.invoiceDocumentNumber}
                                  </p>
                                </div>
                                <InvoiceDocumentDate>
                                  <p>
                                    {new Date(
                                      item.invoiceDocumentDate
                                    ).toLocaleDateString()}
                                  </p>
                                  {item.invoiceDocsQty &&
                                    item.invoiceDocsQty > 1 && (
                                      <p
                                        onMouseEnter={() =>
                                          getByMonetaryClaimHint(item.guid)
                                        }
                                        onMouseLeave={resetByMonetaryClaimHint}
                                      >
                                        и ещё {item.invoiceDocsQty - 1}
                                      </p>
                                    )}
                                </InvoiceDocumentDate>
                              </div>
                              {!!monetaryClaimsHint &&
                                item.invoiceDocsQty &&
                                item.invoiceDocsQty > 1 && (
                                  <DocumentHint>
                                    {monetaryClaimsHint.map(hint => (
                                      <div key={hint.guid}>
                                        <div>
                                          <div>
                                            {statusMonetaryClaimsHint ===
                                            REQUEST_STATUSES.REQUEST ? (
                                              <SkeletonWrapper
                                                width={'140px'}
                                                height={'20px'}
                                              />
                                            ) : (
                                              statusMonetaryClaimsHint ===
                                                REQUEST_STATUSES.GOT &&
                                              `${hint.documentName} ${
                                                hint.documentNumber
                                              }`
                                            )}
                                          </div>
                                          <div>
                                            {statusMonetaryClaimsHint ===
                                            REQUEST_STATUSES.REQUEST ? (
                                              <SkeletonWrapper
                                                width={'120px'}
                                                height={'20px'}
                                              />
                                            ) : (
                                              statusMonetaryClaimsHint ===
                                                REQUEST_STATUSES.GOT &&
                                              `${new Date(
                                                hint.documentDate
                                              ).toLocaleDateString()}`
                                            )}
                                          </div>
                                        </div>
                                        <div>
                                          {statusMonetaryClaimsHint ===
                                          REQUEST_STATUSES.REQUEST ? (
                                            <SkeletonWrapper
                                              width={'120px'}
                                              height={'20px'}
                                            />
                                          ) : (
                                            statusMonetaryClaimsHint ===
                                              REQUEST_STATUSES.GOT &&
                                            `${formSumStringThousands(
                                              hint.baseFullSumm
                                            )}`
                                          )}
                                        </div>
                                      </div>
                                    ))}
                                    <div>
                                      <p>ИТОГО</p>{' '}
                                      <p>
                                        {formSumStringThousands(
                                          item.baseFullSumm
                                        )}
                                      </p>
                                    </div>
                                  </DocumentHint>
                                )}
                            </TDInvoiceDocumenContainer>
                            <TdCentred>
                              <p
                                style={{
                                  textDecoration: 'line-through',
                                  color: '#9FA6B2',
                                  fontSize: '12px',
                                  textAlign: 'center',
                                  margin: '0',
                                  width: 'auto'
                                }}
                              >
                                {format(
                                  new Date(item.maturityDate),
                                  'dd.MM.yyyy'
                                )}
                              </p>
                              {format(
                                new Date(investorOneDebtor.payDate),
                                'dd.MM.yyyy'
                              )}
                            </TdCentred>
                            <TdCentred>
                              {differenceInDays(
                                new Date(item.maturityDate),
                                new Date(investorOneDebtor.payDate)
                              )}
                            </TdCentred>

                            {item.baseVatSumm !== 0 &&
                            item.baseVatSumm !== null ? (
                              <TdCentred>
                                <p
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: '5px'
                                  }}
                                >
                                  {investorOneDebtor.baseDiscountRate.toFixed(
                                    3
                                  )}
                                  %{' '}
                                  <p
                                    style={{
                                      color: '#9FA6B2',
                                      fontSize: '14px',
                                      margin: '0',
                                      width: 'auto'
                                    }}
                                  >
                                    без НДС
                                  </p>
                                </p>
                                <p
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: '5px'
                                  }}
                                >
                                  {(
                                    investorOneDebtor.baseDiscountRate * 1.2
                                  ).toFixed(3)}
                                  %{' '}
                                  <p
                                    style={{
                                      color: '#9FA6B2',
                                      fontSize: '14px',
                                      margin: '0',
                                      width: 'auto'
                                    }}
                                  >
                                    с НДС
                                  </p>
                                </p>
                              </TdCentred>
                            ) : (
                              <td style={{ paddingLeft: '20px' }}>
                                {investorOneDebtor.baseDiscountRate.toFixed(3)}%{' '}
                              </td>
                            )}
                            <TdCentred>
                              {(this.fullRate(key) * 100).toLocaleString('ru', {
                                maximumFractionDigits: 4,
                                minimumFractionDigits: 4
                              })}
                              %
                            </TdCentred>
                            <TdCentred>
                              {this.summToGet(key).toLocaleString('ru', {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2
                              })}
                            </TdCentred>
                          </TableRowStyled>
                        )
                      )}
                    </tbody>
                  </TableStyled>
                )}
              </FullList>
            </ListSection>
            {this.props.status == REQUEST_STATUSES.GOT && (
              <ConfirmSection style={{ marginTop: '10px' }}>
                <ConfirmSectionRightPart>
                  <DiscountItem>
                    <p style={{ color: '#9FA6B2', fontSize: '14px' }}>
                      Сумма требований без дисконта
                    </p>
                    <p
                      className="discount-1"
                      id="discount-1"
                      style={{ color: '#9FA6B2', fontSize: '14px' }}
                    >
                      {this.DiscountCounter().toLocaleString('ru', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}{' '}
                      руб.
                    </p>
                  </DiscountItem>
                  <DiscountItem>
                    <p style={{ color: '#9FA6B2', fontSize: '14px' }}>
                      Дисконт за раннюю оплату
                    </p>
                    <p style={{ color: '#9FA6B2', fontSize: '14px' }}>
                      {(
                        this.DiscountCounter() - this.DiscountCounterToGet()
                      ).toLocaleString('ru', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}{' '}
                      руб.
                    </p>
                  </DiscountItem>
                  <DiscountItem>
                    <p style={{ fontSize: '16px' }}>Сумма к получению</p>
                    <p style={{ fontSize: '16px' }}>
                      {this.DiscountCounterToGet().toLocaleString('ru', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}{' '}
                      руб.
                    </p>
                  </DiscountItem>
                </ConfirmSectionRightPart>
              </ConfirmSection>
            )}
            {investorOneDebtor.statusDebtor == 'На рассмотрении' ? (
              <ConfirmSection>
                <ConfirmSectionRightPart>
                  <BtnsBlock>
                    <CreateBtn
                      style={{ marginRight: '15px' }}
                      onClick={() => this.onApproveClick()}
                    >
                      <CreateBtnText>Согласовать</CreateBtnText>
                    </CreateBtn>
                    <BackBtn
                      style={{ width: '150px' }}
                      onClick={() => this.onDeclineClick()}
                    >
                      <BackBtnText>Отклонить</BackBtnText>
                    </BackBtn>
                  </BtnsBlock>
                </ConfirmSectionRightPart>
              </ConfirmSection>
            ) : investorOneDebtor.statusDebtor == 'Ждет подписания' ? (
              <ConfirmSection>
                <ConfirmSectionRightPart>
                  <BtnsBlock>
                    {permissions &&
                      permissions.includes(USER_PERMISSIONS.DEBTOR_SIGN) && (
                        <CreateBtn
                          style={{ marginRight: '15px' }}
                          onClick={() => this.onSignClick()}
                        >
                          <CreateBtnText>Одобрить</CreateBtnText>
                        </CreateBtn>
                      )}

                    <BackBtn
                      style={{ width: '150px' }}
                      onClick={() => this.onDeclineClick()}
                    >
                      <BackBtnText>Отклонить</BackBtnText>
                    </BackBtn>
                  </BtnsBlock>
                  <ApprovedMessagesContainer>
                    {investorOneDebtor.debtorSigneeName && (
                      <ApprovedMessages>
                        {investorOneDebtor.debtorSigneeName}: согласовано
                      </ApprovedMessages>
                    )}
                    {investorOneDebtor.debtorInspectorComment ? (
                      <ApprovedMessages>
                        Комментарий: {investorOneDebtor.debtorInspectorComment}
                      </ApprovedMessages>
                    ) : (
                      <ApprovedMessages>
                        Комментарий: нет комментария
                      </ApprovedMessages>
                    )}
                  </ApprovedMessagesContainer>
                </ConfirmSectionRightPart>
              </ConfirmSection>
            ) : (
              ''
            )}

            {investorOneDebtor.status !== 14 && (
              <ConfirmSection style={{ marginTop: '-100px', width: '400px' }}>
                <SignSection>
                  {investorOneDebtor.archiveFileIdentifier !== null && (
                    <DownloadLink
                      href={SCFDebtorApi.downloadArchiveFileDebtor(
                        this.props.match.params.guid
                      )}
                    >
                      <BackBtnText>Скачать архив</BackBtnText>
                    </DownloadLink>
                  )}

                  {investorOneDebtor.agreementFileIdentifier !== null && (
                    <DownloadLink
                      href={SCFDebtorApi.downloadDebtorAgreementFile(
                        this.props.match.params.guid
                      )}
                    >
                      <BackBtnText>Скачать документ</BackBtnText>
                    </DownloadLink>
                  )}

                  {investorOneDebtor.supplierSignatureFileIdentifier !==
                    null && (
                    <DownloadLink
                      href={SCFDebtorApi.downloadSupplierSignatureFile(
                        this.props.match.params.guid
                      )}
                    >
                      <BackBtnText>Скачать подпись поставщика</BackBtnText>
                    </DownloadLink>
                  )}

                  {investorOneDebtor.debtorSignatureFileIdentifier !== null && (
                    <DownloadLink
                      href={SCFDebtorApi.downloadDebtorSignatureFile(
                        this.props.match.params.guid
                      )}
                    >
                      <BackBtnText>Скачать мою подпись</BackBtnText>
                    </DownloadLink>
                  )}
                </SignSection>
              </ConfirmSection>
            )}
          </LeftContainer>

          <EconomyEffect
            title="Экономический эффект"
            keyRate={
              this.props.investorOneDebtor.keyRate
                ? this.props.investorOneDebtor.keyRate.toLocaleString('ru', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })
                : ''
            }
            keyRateIncome={
              this.props.investorOneDebtor.keyRateIncome
                ? this.props.investorOneDebtor.keyRateIncome.toLocaleString(
                    'ru',
                    {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    }
                  )
                : ''
            }
            platformIncome={
              this.props.investorOneDebtor.platformFee
                ? this.props.investorOneDebtor.platformFee.toLocaleString(
                    'ru',
                    {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    }
                  )
                : ''
            }
            summToGet={this.DiscountCounterToGet().toLocaleString('ru', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            })}
            earlyPaymentDiscont={(
              this.DiscountCounter() - this.DiscountCounterToGet()
            ).toLocaleString('ru', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            })}
          />
        </ListAndEffect>

        <div id="documentObject" style={{ width: '900px', height: '1000px' }} />
        <CertificatesPopup id="certificatesPopup">
          <PopupExit>
            <p onClick={this.onCloseIconClick}>X</p>
          </PopupExit>
          <h1>Список сертификатов в системе</h1>
          <CertificatesArea>
            <CertificatesListPopup guid={this.props.match.params.guid} />
          </CertificatesArea>
          <NudgeArea>
            <p>Нет нужного сертификата? </p>
            <Nudge onClick={this.onNudgeClick}>
              Перейдите в настройки добаления
            </Nudge>
          </NudgeArea>
        </CertificatesPopup>
        <CertificatesPopupError id="certificatesPopupError">
          <PopupExitError>
            <p onClick={this.onCloseErrorIconClick}>X</p>
          </PopupExitError>
          <h1>
            Невозможно подписать выбранным сертификатом
            <br />
            (не найден на устройстве)
          </h1>
        </CertificatesPopupError>
        <DatePopup id={'datePopup'}>
          <DatesContainer>
            <Textarea
              value={this.state.commentState}
              name="rejectReason"
              onChange={this.handleCommentStateChange}
              placeholder="Ваша причина отказа"
            />
          </DatesContainer>
          <DatePopupBtns>
            {this.state.commentState.length < 10 ? (
              <CreateBtnPopup
                style={{
                  backgroundColor: '#9FA6B2',
                  cursor: 'not-allowed'
                }}
              >
                <CancelBtnText>Отправить</CancelBtnText>
              </CreateBtnPopup>
            ) : (
              <CreateBtnPopup
                onClick={() =>
                  this.props.putEarlyPaymentApplicationDecline({
                    guid: this.props.match.params.guid,
                    body: {
                      rejectReason: this.state.commentState
                    }
                  })
                }
              >
                <CreateBtnContainer onClick={() => this.onDeclineBtnClick()}>
                  <CancelBtnText>Отправить</CancelBtnText>
                </CreateBtnContainer>
              </CreateBtnPopup>
            )}
            <CancelBtn onClick={() => this.onCancelClick()}>
              <CancelBtnText>Отменить</CancelBtnText>
            </CancelBtn>
          </DatePopupBtns>
        </DatePopup>
        <DatePopup id={'approvePopup'}>
          <DatesContainer>
            <Textarea
              value={this.state.debtorInspectorCommentState}
              name="approveComment"
              onChange={this.handleDebtorInspectorCommentStateChange}
              placeholder="Комментарий"
            />
          </DatesContainer>
          <DatePopupBtns>
            <CreateBtnPopup>
              <CreateBtnContainer onClick={() => this.onApproveBtnClick()}>
                <CancelBtnText>Отправить</CancelBtnText>
              </CreateBtnContainer>
            </CreateBtnPopup>
            <CancelBtn onClick={() => this.onCancelApproveClick()}>
              <CancelBtnText>Отменить</CancelBtnText>
            </CancelBtn>
          </DatePopupBtns>
        </DatePopup>
      </PageNoMarginStyled>
    );
  }
}

const mapStateToProps = ({ SCFDebtor, User }: STORE) => ({
  monetaryClaimsEarlyPaymentApplications:
    SCFDebtor.getMonetaryClaimsDebtorEarlyPaymentApplicationsByGuid.data,
  status:
    SCFDebtor.getMonetaryClaimsDebtorEarlyPaymentApplicationsByGuid.status,
  error: SCFDebtor.getMonetaryClaimsDebtorEarlyPaymentApplicationsByGuid.error,
  investorOneDebtor: SCFDebtor.getOneDebtorRoleByGuid.data,
  approveStatus: SCFDebtor.approveEarlyPaymentApplicationDebtorRole.status,
  approveError: SCFDebtor.approveEarlyPaymentApplicationDebtorRole.error,
  permissions: User.getUserData.data.permissions,
  monetaryClaimsHint: SCFDebtor.getByMonetaryClaimDebtor.data,
  statusMonetaryClaimsHint: SCFDebtor.getByMonetaryClaimDebtor.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getInvestorOneDebtor,
      getMonetaryClaimsDebtorEarlyPaymentApplications,
      putEarlyPaymentApplicationDecline,
      approveEarlyPaymentApplication,
      getByMonetaryClaimHint,
      resetByMonetaryClaimHint
    },
    dispatch
  );

const ApplicationInfoConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ApplicationInfo)
);

export { ApplicationInfoConnect as ApplicationInfo };
