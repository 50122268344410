import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSortAmountDownAlt,
  faSortAmountUpAlt
} from '@fortawesome/free-solid-svg-icons';
import {
  format,
  isAfter,
  isEqual,
  parse,
  parseISO,
  startOfDay
} from 'date-fns';

import { InputDate } from 'shared/ui/InputDate';
import { Loader, Pagination } from 'Layouts/components';
import {
  req as getMonetaryClaims,
  RequestDataType,
  ResponseDataType,
  MonataryClaim
} from 'entities/SCF/Supplier/model/actions/getMonetaryClaimsSupplierRole';
import {
  req as getByMonetaryClaimHint,
  reset as resetByMonetaryClaimHint,
  ResponseData as ResponseMonetaryClaimSupplier
} from 'entities/SCF/Supplier/model/actions/getByMonetaryClaimSupplier';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import {
  ClaimsPageStyled,
  ListSection,
  SignBtn,
  SignBtnText,
  StatusAccept,
  StatusPaid,
  HeaderActionSection,
  HeaderSection,
  HeaderActionSectionTop,
  HeaderActionSectionBottom,
  HeaderFilters,
  HeaderActionSectionBottomItem,
  PlusContainer,
  CheckIcon,
  SkeletonWrapper,
  TDInvoiceDocumenContainer,
  DocumentHint,
  InvoiceDocumentDate,
  Status,
  StatusUnavailable,
  StatuseEarlyPayment,
  StatusAuction,
  DebtorInfo,
  MaturityDateContainer,
  ContainerFilter,
  SortContainer,
  FilterArea
} from './styles';
import { CreateLimitsNudge } from 'src/features/SCF/UI/ScfStyles';
import { STORE, REQUEST_STATUSES, ResponseError } from 'globaltypes';
import {
  req as postEarlyPaymentApplication,
  reset as resetEarlyPaymentApplication
} from 'entities/SCF/Supplier/model/actions/postEarlyPaymentApplicationSupplierRole';
import { RequestDataType as PostEarlyPaymentApplicationDataType } from 'entities/SCF/Supplier/model/reducers/postEarlyPaymentApplicationSupplierRole';
import { formSumStringThousands } from 'shared/utils/Utils';
import { Input } from 'shared/ui/Input';
import { InnPlaceholders } from 'shared/constants';
import { navyBlue } from 'src/shared/styled';
import { Select } from 'shared/ui/Select';

interface StateToProps {
  status: REQUEST_STATUSES;
  error: ResponseError;
  monetaryClaims: ResponseDataType;
  monetaryClaimHint: ResponseMonetaryClaimSupplier[];
  statusMonetaryClaimHint: REQUEST_STATUSES;
  statusPostEarlyPaymentApplication: REQUEST_STATUSES;
}

interface DispatchToProps {
  getMonetaryClaims: (data: RequestDataType) => void;
  postEarlyPaymentApplication: (
    data: PostEarlyPaymentApplicationDataType
  ) => void;
  resetEarlyPaymentApplication: () => void;
  getByMonetaryClaimHint: (guid: string) => void;
  resetByMonetaryClaimHint: () => void;
}

type Props = RouteComponentProps & StateToProps & DispatchToProps;

export const StatusTypes = {
  AVAILABLE: 'Неоплаченные, доступные для ранней оплаты',
  CHOSEN: 'Неоплаченные, выбранные поставщиком для ранней оплаты',
  LOCKED: 'Неоплаченные, недоступные для ранней оплаты',
  UNPAID: 'Все неоплаченные',
  PAID: 'Оплаченные'
};

const ClaimsPage: React.FC<Props> = ({
  getMonetaryClaims,
  monetaryClaims,
  status,
  getByMonetaryClaimHint,
  resetByMonetaryClaimHint,
  monetaryClaimHint,
  statusMonetaryClaimHint,
  postEarlyPaymentApplication,
  resetEarlyPaymentApplication,
  statusPostEarlyPaymentApplication,
  history
}) => {
  const [debtorInn, setDebtorInn] = React.useState('');
  const [supplierStatus, setSupplierStatus] = React.useState('');
  const [minBaseSum, setMinBaseSum] = React.useState('');
  const [maxBaseSum, setMaxBaseSum] = React.useState('');
  const [minMaturityDate, setMinMaturityDate] = React.useState('');
  const [maxMaturityDate, setMaxMaturityDate] = React.useState('');

  const [isSummSort, setIsSummSort] = React.useState(null);
  const [isMaturityDateSort, setIsMaturityDateSort] = React.useState(null);

  const [guids, setGuids] = React.useState([]);

  React.useEffect(
    () => {
      const searchParams = new URLSearchParams(location.search);
      const page = +searchParams.get('page') || 1;
      const debtorInn = searchParams.get('debtorInn') || '';
      const minBaseSum = searchParams.get('minBaseSum') || '';
      const maxBaseSum = searchParams.get('maxBaseSum') || '';
      const minMaturityDate = searchParams.get('minMaturityDate') || '';
      const maxMaturityDate = searchParams.get('maxMaturityDate') || '';
      const supplierStatus = searchParams.get('supplierStatus') || '';

      setDebtorInn(debtorInn);
      setMinBaseSum(minBaseSum);
      setMaxBaseSum(maxBaseSum);
      setMinMaturityDate(minMaturityDate);
      setMaxMaturityDate(maxMaturityDate);
      setSupplierStatus(supplierStatus);

      getMonetaryClaims({
        page,
        pageSize: 20,
        ...(supplierStatus && { status: supplierStatus }),
        ...(debtorInn && { DebtorInn: debtorInn }),
        ...(minBaseSum && { minBaseSum }),
        ...(maxBaseSum && { maxBaseSum }),
        ...(minMaturityDate && {
          minMaturityDate: format(
            parse(minMaturityDate, 'dd/MM/yyyy', new Date()),
            'yyyy-MM-dd'
          )
        }),
        ...(maxMaturityDate && {
          maxMaturityDate: format(
            parse(maxMaturityDate, 'dd/MM/yyyy', new Date()),
            'yyyy-MM-dd'
          )
        }),
        ...((isSummSort !== null || isMaturityDateSort !== null) && {
          sortBy:
            isSummSort === true
              ? 'summDesc'
              : isSummSort === false
                ? 'summAsc'
                : isMaturityDateSort === true
                  ? 'maturityDateDesc'
                  : isMaturityDateSort === false && 'maturityDateAsc'
        })
      });
    },
    [supplierStatus, isSummSort, isMaturityDateSort, history.location.search]
  );

  React.useEffect(
    () => {
      if (statusPostEarlyPaymentApplication === REQUEST_STATUSES.GOT) {
        history.push('/supplier/early_payment_applications?page=1');
        resetEarlyPaymentApplication();
      }
    },
    [statusPostEarlyPaymentApplication]
  );

  const onEalyGetClick = () => {
    postEarlyPaymentApplication({ createRequests: guids });
    setGuids([]);
  };

  const handleAddGuid = (guid: string) => {
    setGuids(
      prevGuids => (prevGuids.includes(guid) ? prevGuids : [...prevGuids, guid])
    );
  };

  const handleDeleteGuid = (guid: string) => {
    setGuids(prevGuids => prevGuids.filter(item => item !== guid));
  };

  const isDateInRange = (
    date: string,
    checkType: 'available' | 'unavailable'
  ): boolean => {
    const givenDate = new Date(date);
    const currentDate = new Date();

    const threeDaysLater = new Date(currentDate);
    threeDaysLater.setDate(currentDate.getDate() + 3);

    if (checkType === 'available') {
      return givenDate <= threeDaysLater;
    }

    return givenDate > threeDaysLater;
  };

  const onFilterChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget;

    const searchParams = new URLSearchParams(history.location.search);

    if (value) {
      searchParams.set(name, value);
    } else {
      searchParams.delete(name);
    }

    history.push({ search: searchParams.toString() });
  };

  const onDateChange = (name: string, date: string) => {
    const searchParams = new URLSearchParams(history.location.search);

    if (name === 'minMaturityDate' && date !== '') {
      searchParams.set(name, date);
    } else if (name === 'maxMaturityDate' && date !== '') {
      searchParams.set(name, date);
    } else {
      searchParams.delete(name);
    }

    history.push({ search: searchParams.toString() });
  };

  const isValidItem = (item: MonataryClaim) => {
    if (!(item.paidStatus === 0 || item.paidStatus === 10)) return false;
    if (!isDateInRange(item.maturityDate, 'unavailable')) return false;

    if (item.auctionGuid) {
      const auctionDate = startOfDay(parseISO(item.auctionResultsDateTime));
      const today = startOfDay(new Date());

      if (!(isAfter(auctionDate, today) || isEqual(auctionDate, today))) {
        return false;
      }
    }

    return true;
  };

  return (
    <ClaimsPageStyled>
      <CreateLimitsNudge>
        <h2>Мои денежные требования</h2>
      </CreateLimitsNudge>
      <HeaderSection>
        <HeaderFilters>
          <FilterArea>
            <Select
              options={Object.keys(StatusTypes).map(type => ({
                id: type,
                name: StatusTypes[type]
              }))}
              value={supplierStatus}
              label="Статус"
              name="supplierStatus"
              placeholder="Показать все"
              onChange={onFilterChange}
            />
          </FilterArea>

          <HeaderActionSection>
            <HeaderActionSectionTop>
              <p>
                Для досрочной оплаты выберите неоплаченные денежные требования
              </p>
              {guids.length === 0 ? (
                <SignBtn
                  style={{ backgroundColor: '#9FA6B2', cursor: 'not-allowed' }}
                >
                  <SignBtnText>Получить досрочно</SignBtnText>
                </SignBtn>
              ) : (
                <SignBtn onClick={onEalyGetClick}>
                  <SignBtnText>Получить досрочно</SignBtnText>
                </SignBtn>
              )}
            </HeaderActionSectionTop>
            <HeaderActionSectionBottom>
              <HeaderActionSectionBottomItem>
                <p>Доступно</p>
                <p>{monetaryClaims.availableChecks}</p>
              </HeaderActionSectionBottomItem>
              <HeaderActionSectionBottomItem>
                <p>счета на сумму</p>
                <p>{monetaryClaims.fullSum.toLocaleString('ru')} руб.</p>
              </HeaderActionSectionBottomItem>
            </HeaderActionSectionBottom>
          </HeaderActionSection>
        </HeaderFilters>

        <ContainerFilter>
          <Input
            value={debtorInn}
            label="ИНН Дебитора"
            name="debtorInn"
            placeholder={InnPlaceholders.entity}
            onChange={onFilterChange}
          />
          <Input
            value={minBaseSum}
            label="Сумма денежного требования"
            name="minBaseSum"
            onChange={onFilterChange}
          />
          <span>-</span>
          <Input
            value={maxBaseSum}
            name="maxBaseSum"
            onChange={onFilterChange}
          />
          <InputDate
            dateValue={minMaturityDate}
            label="Дата плановой оплаты"
            name="minMaturityDate"
            onDateChange={date => onDateChange('minMaturityDate', date)}
          />
          <span>-</span>
          <InputDate
            dateValue={maxMaturityDate}
            name="maxMaturityDate"
            onDateChange={date => onDateChange('maxMaturityDate', date)}
          />
        </ContainerFilter>
      </HeaderSection>

      <ListSection>
        {status === REQUEST_STATUSES.REQUEST && <Loader />}
        {status === REQUEST_STATUSES.GOT && (
          <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
            <TableHeaderStyled>
              <tr>
                <TableThStyled width="8%">ID</TableThStyled>
                <TableThStyled width="25%">Дебитор</TableThStyled>
                <TableThStyled width="15%">Документы</TableThStyled>
                <TableThStyled width="10%">
                  <SortContainer>
                    <div>Сумма</div>
                    <FontAwesomeIcon
                      icon={
                        isSummSort ? faSortAmountUpAlt : faSortAmountDownAlt
                      }
                      color={isSummSort !== null && navyBlue}
                      onClick={() => {
                        setIsMaturityDateSort(null);
                        setIsSummSort(prevState => !prevState);
                      }}
                    />
                  </SortContainer>
                </TableThStyled>
                <TableThStyled width="10%">Дата счета</TableThStyled>
                <TableThStyled width="10%">
                  <SortContainer>
                    <div>Дата оплаты</div>
                    <FontAwesomeIcon
                      icon={
                        isMaturityDateSort
                          ? faSortAmountUpAlt
                          : faSortAmountDownAlt
                      }
                      color={isMaturityDateSort !== null && navyBlue}
                      onClick={() => {
                        setIsSummSort(null);
                        setIsMaturityDateSort(prevState => !prevState);
                      }}
                    />
                  </SortContainer>
                </TableThStyled>
                <TableThStyled width="10%">Статус</TableThStyled>
                <TableThStyled width="20%">
                  Ставка ранней оплаты, годовых
                </TableThStyled>
              </tr>
            </TableHeaderStyled>
            <tbody>
              {monetaryClaims.items.map(item => (
                <TableRowStyled
                  key={item.guid}
                  style={{
                    backgroundColor: isValidItem(item) ? '#DAF4FF' : '#FFFFFF'
                  }}
                >
                  <td>{item.guid.substring(item.guid.length - 5)}</td>
                  <td>
                    <DebtorInfo>
                      {item.auctionGuid && (
                        <StatusAuction
                          onClick={() =>
                            window.open(
                              `/supplier/discount_auctions/${item.auctionGuid}`,
                              '_blank'
                            )
                          }
                        >
                          <p>Аукцион</p>
                        </StatusAuction>
                      )}
                      <div>{item.debtorName}</div>
                    </DebtorInfo>
                  </td>

                  <TDInvoiceDocumenContainer>
                    <div>
                      <div>
                        {item.invoiceDocumentName} {item.invoiceDocumentNumber}
                      </div>
                      <InvoiceDocumentDate>
                        <p>
                          {new Date(
                            item.invoiceDocumentDate
                          ).toLocaleDateString()}
                        </p>
                        {item.invoiceDocsQty &&
                          item.invoiceDocsQty > 1 && (
                            <p
                              onMouseEnter={() =>
                                getByMonetaryClaimHint(item.guid)
                              }
                              onMouseLeave={resetByMonetaryClaimHint}
                            >
                              и ещё {item.invoiceDocsQty - 1}
                            </p>
                          )}
                      </InvoiceDocumentDate>
                    </div>
                    {!!monetaryClaimHint &&
                      item.invoiceDocsQty &&
                      item.invoiceDocsQty > 1 && (
                        <DocumentHint>
                          {!!monetaryClaimHint &&
                            monetaryClaimHint.map(hint => (
                              <div key={hint.guid}>
                                <div>
                                  <div>
                                    {statusMonetaryClaimHint ===
                                    REQUEST_STATUSES.REQUEST ? (
                                      <SkeletonWrapper
                                        width={'140px'}
                                        height={'20px'}
                                      />
                                    ) : (
                                      statusMonetaryClaimHint ===
                                        REQUEST_STATUSES.GOT &&
                                      `${hint.documentName} ${
                                        hint.documentNumber
                                      }`
                                    )}
                                  </div>
                                  <div>
                                    {statusMonetaryClaimHint ===
                                    REQUEST_STATUSES.REQUEST ? (
                                      <SkeletonWrapper
                                        width={'120px'}
                                        height={'20px'}
                                      />
                                    ) : (
                                      statusMonetaryClaimHint ===
                                        REQUEST_STATUSES.GOT &&
                                      `${new Date(
                                        hint.documentDate
                                      ).toLocaleDateString()}`
                                    )}
                                  </div>
                                </div>
                                <div>
                                  {statusMonetaryClaimHint ===
                                  REQUEST_STATUSES.REQUEST ? (
                                    <SkeletonWrapper
                                      width={'120px'}
                                      height={'20px'}
                                    />
                                  ) : (
                                    statusMonetaryClaimHint ===
                                      REQUEST_STATUSES.GOT &&
                                    `${formSumStringThousands(
                                      hint.baseFullSumm
                                    )}`
                                  )}
                                </div>
                              </div>
                            ))}
                          <div>
                            <p>ИТОГО</p>{' '}
                            <p>{formSumStringThousands(item.baseFullSumm)}</p>
                          </div>
                        </DocumentHint>
                      )}
                  </TDInvoiceDocumenContainer>

                  <td>{formSumStringThousands(item.baseFullSumm)}</td>

                  <td>
                    {format(new Date(item.createdDateTime), 'dd.MM.yyyy')}
                  </td>

                  <td>{format(new Date(item.maturityDate), 'dd.MM.yyyy')}</td>

                  {(item.paidStatus === 0 || item.paidStatus === 10) &&
                  isDateInRange(item.maturityDate, 'unavailable') ? (
                    <td>
                      <Status color="#D0E9FF">
                        <StatusAccept>Доступна для ранней оплаты</StatusAccept>
                      </Status>
                    </td>
                  ) : (item.paidStatus === 0 || item.paidStatus === 10) &&
                  isDateInRange(item.maturityDate, 'available') ? (
                    <td>
                      <Status color="#D0E9FF">
                        <StatusUnavailable>Недоступна</StatusUnavailable>
                      </Status>
                    </td>
                  ) : item.paidStatus === 11 ? (
                    <td>
                      <Status color="#faf0e0">
                        <StatuseEarlyPayment>
                          Выбрана для ранней оплаты
                        </StatuseEarlyPayment>
                      </Status>
                    </td>
                  ) : (
                    item.paidStatus === 20 && (
                      <td>
                        <Status color="#1cca94">
                          <StatusPaid>Оплачен</StatusPaid>
                        </Status>
                      </td>
                    )
                  )}

                  {isValidItem(item) ? (
                    <td>
                      {guids.includes(item.guid) ? (
                        <MaturityDateContainer
                          onClick={() => handleDeleteGuid(item.guid)}
                        >
                          <CheckIcon />
                          <p>Добавлено</p>
                        </MaturityDateContainer>
                      ) : (
                        <PlusContainer onClick={() => handleAddGuid(item.guid)}>
                          +
                        </PlusContainer>
                      )}
                    </td>
                  ) : (
                    <td />
                  )}
                </TableRowStyled>
              ))}
            </tbody>
          </TableStyled>
        )}
      </ListSection>

      <Pagination list={monetaryClaims} />
    </ClaimsPageStyled>
  );
};

const mapStateToProps = ({ SCFSupplier }: STORE) => ({
  monetaryClaims: SCFSupplier.getMonetaryClaimsSupplierRole.data,
  status: SCFSupplier.getMonetaryClaimsSupplierRole.status,
  error: SCFSupplier.getMonetaryClaimsSupplierRole.error,
  monetaryClaimHint: SCFSupplier.getByMonetaryClaimSupplier.data,
  statusMonetaryClaimHint: SCFSupplier.getByMonetaryClaimSupplier.status,
  statusPostEarlyPaymentApplication:
    SCFSupplier.postEarlyPaymentApplicationSupplierRole.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getMonetaryClaims,
      postEarlyPaymentApplication,
      resetEarlyPaymentApplication,
      getByMonetaryClaimHint,
      resetByMonetaryClaimHint
    },
    dispatch
  );

const ClaimsPageConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(ClaimsPage)
);

export { ClaimsPageConnect as ClaimsPage };