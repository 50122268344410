import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { req as getUserData } from 'User/actions/getUserData';
import { clearSession as exit } from 'App/actions/cached';
import { reset as resetUserData } from 'User/actions/getUserData';
import {
  STORE,
  REQUEST_STATUSES,
  ResponseError,
  USER_PERMISSIONS
} from 'globaltypes';

import {
  Loader,
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE
} from 'Layouts/components';
import { InternalCabinet } from 'pages/SCF/Internal/components';

import { SCFPageStyled } from 'src/features/SCF/UI/ScfStyles';

interface StateToProps {
  permissions: USER_PERMISSIONS[];
  status: REQUEST_STATUSES;
  error: ResponseError;
}

interface DispatchToProps {
  getUserData: () => void;
  exit: () => void;
  resetUserData: () => void;
}

type Props = StateToProps & DispatchToProps;

const InternalPage: React.FC<Props> = ({
  getUserData,
  status,
  error,
  exit,
  permissions
}) => {
  React.useEffect(() => {
    getUserData();
  }, []);

  React.useEffect(
    () => {
      if (status === REQUEST_STATUSES.ERROR) {
        exit();
        resetUserData();
      }
    },
    [status]
  );

  const renderCabinet = () => {
    if (permissions.includes(USER_PERMISSIONS.INTERNAL_AGENT)) {
      return <InternalCabinet />;
    }
  };

  return (
    <>
      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <SCFPageStyled>{renderCabinet()}</SCFPageStyled>
      )}

      {status === REQUEST_STATUSES.ERROR &&
        error.code === 401 && (
          <ActionResponseBlock
            showIn={status === REQUEST_STATUSES.ERROR}
            template={ACTION_RESPONSE_TEMPLATE.SESSION_ERROR}
          />
        )}

      <ActionResponseBlock
        showIn={status === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </>
  );
};

const mapStateToProps = ({ User }: STORE) => ({
  permissions: User.getUserData.data.permissions,
  status: User.getUserData.status,
  error: User.getUserData.error
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getUserData, exit, resetUserData }, dispatch);

const InternalPageConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(InternalPage);

export { InternalPageConnect as InternalPage };