import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { REQUEST_STATUSES, STORE } from 'src/globaltypes';
import { ResponseDataType as GetCertificateResponseDataType } from 'src/features/SCF/actions/getCertificate';
import { req as getCertificate } from 'src/features/SCF/actions/getCertificate';
import { Certificate as CertificateType } from 'src/features/SCF/types/certificate';
import { CertificatesTableView } from './CertificatesTableView/CertificatesTableView';
import {
  getCertificate as getCertificateCryptoPro,
  getAllUserCertificates,
  Certificate,
  createHash,
  createDetachedSignature
} from 'crypto-pro-js';
import SCFApi from 'entities/SCF/Supplier/api';
import {
  req as sendSignature,
  reset as resetSendSignature
} from 'entities/SCF/Supplier/model/actions/postAgreementSignatureSupplierRole';
import { ResponseDataType as OneDebtorResponseDataType } from 'entities/SCF/Supplier/model/actions/getInvestorOneDebtorSupplierRoleByGuid';
import { RequestDataType as SendSignatureRequestDataType } from 'entities/SCF/Supplier/model/reducers/postAgreementSignatureSupplierRole';

interface OwnProps {
  setIsCertificates: (isActive: boolean) => void;
  setIsCertificatesError: (isActive: boolean) => void;
  setIsCertificatesLoading: (isLoading: boolean) => void;
}

interface StateToProps {
  certificate: GetCertificateResponseDataType;
  investorOneDebtor: OneDebtorResponseDataType;
  statusPostAgreementSignature: REQUEST_STATUSES;
}

interface DispatchToProps {
  getCertificate: () => void;
  sendSignature: (data: SendSignatureRequestDataType) => void;
  resetSendSignature: () => void;
}

interface State {
  certificates: Certificate[];
  certificate: Certificate;
}

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams> &
  StateToProps &
  DispatchToProps &
  OwnProps;

class CertificatesList extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      certificates: [],
      certificate: null
    };
  }

  componentDidMount() {
    this.props.getCertificate();
  }

  componentWillUnmount(): void {
    resetSendSignature();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      this.props.statusPostAgreementSignature === REQUEST_STATUSES.GOT &&
      this.props.statusPostAgreementSignature !==
        prevProps.statusPostAgreementSignature
    ) {
      this.props.history.push(
        `/supplier/early_payment_applications/${this.props.match.params.id}`
      );
    }
  }

  getCertificateId = (index: number): number => {
    const item = this.props.certificate.items.find(item => item.id === index);
    return !!item && item.id;
  };

  async setUserCertificates() {
    (async () => {
      try {
        this.setState({ certificates: await getAllUserCertificates() });
      } catch (error) {
        console.log(error);
      }
    })();
  }

  async setSigUserCertificate() {
    try {
      let buffer: ArrayBuffer;

      await fetch(
        SCFApi.downloadAgreementFileSupplierRole(this.props.match.params.id)
      )
        .then(result => result.arrayBuffer())
        .then(result => {
          buffer = result;
        });
      const hash = await createHash(buffer);
      const sig = await createDetachedSignature(
        this.state.certificate.thumbprint,
        hash
      );
      this.props.sendSignature({
        guid: this.props.match.params.id,
        signature: sig,
        serial: this.state.certificate.thumbprint
      });
    } catch (error) {
      console.log(error);
    }
  }

  onCertificateClick = async (index: number): Promise<void> => {
    this.props.setIsCertificatesLoading(true);
    this.props.setIsCertificates(false);
    const id = this.getCertificateId(index);
    const dbCert: CertificateType = this.props.certificate.items.find(
      cert => cert.id === id
    );
    this.setUserCertificates();

    try {
      const selectedCertificate = await getCertificateCryptoPro(
        dbCert.serialNumber,
        false
      );
      this.setState({ certificate: selectedCertificate });
      await this.setSigUserCertificate();
      this.props.setIsCertificatesLoading(false);
    } catch (error) {
      this.props.setIsCertificatesLoading(false);
      this.props.setIsCertificatesError(true);
    }
  };

  formCertificateData = (): CertificateType[] => {
    const { certificate, investorOneDebtor } = this.props;

    return certificate.items
      .map(this.certificateToView)
      .filter(item => item.guid === investorOneDebtor.supplierSigneeGuid);
  };

  certificateToView = (certificate: CertificateType) => {
    const viewItem: CertificateType = { ...certificate };
    return viewItem;
  };

  render() {
    return (
      <CertificatesTableView
        list={this.formCertificateData()}
        onCertificateClick={this.onCertificateClick}
      />
    );
  }
}

const mapStateToProps = ({ SCF, SCFSupplier }: STORE) => ({
  certificate: SCF.getCertificate.data,
  investorOneDebtor: SCFSupplier.getInvestorOneDebtorSupplierRoleByGuid.data,
  statusPostAgreementSignature:
    SCFSupplier.postAgreementSignatureSupplierRole.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { getCertificate, sendSignature, resetSendSignature },
    dispatch
  );

const CertificatesListConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(CertificatesList)
);

export { CertificatesListConnect as CertificatesListPopup };