import { GET_INVESTOR_ONE_DEBTOR_SUPPLIER_ROLE_BY_GUID } from 'entities/SCF/Supplier/model/reducers/getInvestorOneDebtorSupplierRoleByGuid';

export interface ResponseDataType {
  guid: string;
  auctionGuid: string
  absoluteDiscount: number
  createdDateTime: Date;
  debtorInn: string;
  debtorName: string;
  supplierName: string;
  payerInn: string;
  payerName: string;
  payDate: Date;
  monetaryClaimsTotalBaseSum: number;
  monetaryClaimsTotalVatSum: number;
  monetaryClaimsTotalFullSum: number;
  absoluteDiscount: number;
  firstBasePaymentDate: Date;
  lastBasePaymentDate: Date;
  baseDiscountRate: number;
  firstPaymentShare: number;
  payerType: number;
  statusSupplier: string;
  agreementFileIdentifier: string;
  archiveFileIdentifyier?: string;
  agreementFilename: string;
  supplierSignatureFileIdentifier: string;
  supplierSignatureFilename: string;
  debtorSignatureFileIdentifier: string;
  supplierSigneeGuid: string;
  debtorSignatureFilename: string;
  rejectSubject: number;
  rejectComment: string;
}

export interface RequestDataType {
  guid: string;
}

export const req = (data: RequestDataType) => ({
  type: GET_INVESTOR_ONE_DEBTOR_SUPPLIER_ROLE_BY_GUID.REQ,
  data
});

export const got = (data: ResponseDataType) => ({
  type: GET_INVESTOR_ONE_DEBTOR_SUPPLIER_ROLE_BY_GUID.GOT,
  data
});

export const err = (error: {}) => ({
  type: GET_INVESTOR_ONE_DEBTOR_SUPPLIER_ROLE_BY_GUID.ERR,
  error
});

export const reset = () => ({
  type: GET_INVESTOR_ONE_DEBTOR_SUPPLIER_ROLE_BY_GUID.RESET
});
