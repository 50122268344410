import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { STORE } from 'src/globaltypes';
import { ResponseDataType as GetCertificateResponseDataType } from 'src/features/SCF/actions/getCertificate';
import { req as getCertificate } from 'src/features/SCF/actions/getCertificate';
import { Certificate as CertificateType } from 'src/features/SCF/types/certificate';
import { CertificatesTableView } from './CertificatesTableView/CertificatesTableView';
import { getCertificate as getCertificateCryptoPro, getAllUserCertificates, Certificate, addDetachedSignature, createHash, createDetachedSignature } from 'crypto-pro-js';
import SCFApi from 'src/features/SCF/api';
import { req as sendSubSignature } from 'entities/SCF/Debtor/model/actions/sendSubSignature';
import { RequestDataType as SendSubSignatureRequestDataType } from 'entities/SCF/Debtor/model/reducers/sendSubSignature';

interface OwnProps {
    registryIdSub: string[];
}

interface StateToProps {
    certificate: GetCertificateResponseDataType;
}

interface DispatchToProps {
    getCertificate: () => void;
    sendSubSignature: (data: SendSubSignatureRequestDataType) => void;
}

interface State {
    validateInnError: any,
    certificates: Certificate[],
    certificatesError: Certificate[],
    certificate: Certificate,
    certificateDetails: any,
    detailsError: Certificate
}

type Props = StateToProps & DispatchToProps & OwnProps;

class CertificatesListForSubRegistries extends React.Component<Props, State> {

    constructor(props) {

        super(props);
        this.state = {
            validateInnError: null,
            certificates: [],
            certificatesError: [],
            certificate: null,
            certificateDetails: null,
            detailsError: null
        }
    }

    componentDidMount() {
        this.props.getCertificate();
    }


    getCertificateId = (index: number): number => {
        const item = this.props.certificate.items[index];
        return !!item && item.id;
    };


    async setUserCertificates() {
        (async () => {
            try {
                this.setState({ certificates: await getAllUserCertificates() });
            } catch (error) {
                this.setState({ certificatesError: error.message });
            }
        })();
    }

    async setSigUserCertificate() {
        try {
            var buffer: ArrayBuffer
            for (let i = 0; i < this.props.registryIdSub.length; i++) {
                await fetch(SCFApi.downloadDebtorSubRegistry(this.props.registryIdSub[i]))
                    .then(result => result.arrayBuffer()).then(result => {
                        buffer = result;
                    });
                var hash = await createHash(buffer);
                var sig = await createDetachedSignature(this.state.certificate.thumbprint, hash);
                await this.props.sendSubSignature({
                    id: this.props.registryIdSub[i],
                    signature: sig,
                    thumbprint: this.state.certificate.thumbprint
                });
            }
            window.location.reload();
        }
        catch (error) {
            this.setState({ certificatesError: error.message });
        }
    };

    onCertificateClick = async (index: number): Promise<void> => {
        var id: number,
            id = this.getCertificateId(index)
        const dbCert: CertificateType = this.props.certificate.items.find(cert => cert.id === id);
        this.setUserCertificates();

        try {
            const selectedCertificate = await getCertificateCryptoPro(dbCert.serialNumber, false);
            this.setState({ certificate: selectedCertificate });
            this.setSigUserCertificate();
        }
        catch (error) {
            const signError = document.getElementById('certificatesPopupError');
            signError.style.display = 'flex';
        }
    };

    formCertificateData = (): CertificateType[] => {
        return this.props.certificate.items.map(this.certificateToView);
    };

    certificateToView = (certificate: CertificateType) => {
        const viewItem: CertificateType = {
            id: certificate.id,
            companyInn: certificate.companyInn,
            companyOgrnOrOgrnIP: certificate.companyOgrnOrOgrnIP,
            companyName_O: certificate.companyName_O,
            companyName_CN: certificate.companyName_CN,
            companyCountryC: certificate.companyCountryC,
            companyRegionS: certificate.companyRegionS,
            companyLocationL: certificate.companyLocationL,
            companyAddressStreet: certificate.companyAddressStreet,
            positionT: certificate.positionT,
            personSurNameSN: certificate.personSurNameSN,
            personNameG: certificate.personNameG,
            personInn: certificate.personInn,
            personEmailE: certificate.personEmailE,
            personSnils: certificate.personSnils,
            certValidFrom: certificate.certValidFrom,
            certValidUntil: certificate.certValidUntil,
            keyValidFrom: certificate.keyValidFrom,
            keyValidUntil: certificate.keyValidUntil,
            issuerInn: certificate.issuerInn,
            issuerOGRN: certificate.issuerOGRN,
            issuerName_O: certificate.issuerName_O,
            issuerName_CN: certificate.issuerName_CN,
            issuerTypeOU: certificate.issuerTypeOU,
            issuerCountryC: certificate.issuerCountryC,
            issuerRegionS: certificate.issuerRegionS,
            issuerLocationL: certificate.issuerLocationL,
            issuerAddressStreet: certificate.issuerAddressStreet,
            issuerEmailE: certificate.issuerEmailE,
            serialNumber: certificate.serialNumber,
            isDeleted: certificate.isDeleted,
            isAvailable: certificate.isAvailable
        }
        return viewItem;
    }

    render() {
        return (
            <React.Fragment>
                <CertificatesTableView
                    list={this.formCertificateData()}
                    onCertificateClick={this.onCertificateClick}>
                </CertificatesTableView>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ SCF }: STORE) => ({
    certificate: SCF.getCertificate.data
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            getCertificate,
            sendSubSignature
        },
        dispatch
    );


const CertificatesListForSubRegistriesConnect = (
    connect<StateToProps, DispatchToProps>(
        mapStateToProps,
        mapDispatchToProps
    )(CertificatesListForSubRegistries));


export { CertificatesListForSubRegistriesConnect as CertificatesListForSubRegistriesPopup };

