import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { Loader } from 'src/features/Layouts/components';
import { TableStyled } from 'src/features/Layouts/components/Table/styles';
import { TableHeaderStyled } from 'src/features/Layouts/components/Table/TableHeader/styles';
import {
  TableRowStyled,
  TableThStyled
} from 'src/features/Layouts/components/Table/TableRow/styles';
import {
  req as getSupplierAuction,
  ResponseData,
  RequestData
} from 'src/features/SCF/actions/getSupplierAuction';
import {
  req as postAddToAuction,
  RequestData as ReqAddToAuction
} from 'src/features/SCF/actions/postAddToAuction';
import { req as deleteFromAuction } from 'src/features/SCF/actions/deleteFromAuction';
import { REQUEST_STATUSES, STORE } from 'src/globaltypes';
import { ActionAuction, Status } from './styled';

interface StateToProps {
  suppliers: ResponseData;
  status: REQUEST_STATUSES;
  statusAddToAuction: REQUEST_STATUSES;
  statusDeleteFromAuction: REQUEST_STATUSES;
}

interface DispatchToProps {
  getSupplierAuction: (guid: string, data?: RequestData) => void;
  postAddToAuction: (guid: string, data: ReqAddToAuction) => void;
  deleteFromAuction: (guid: string, inn: string) => void;
}

interface MatchParams {
  guid: string;
  tab?: string;
}

type Props = RouteComponentProps<MatchParams> & StateToProps & DispatchToProps;

const AuctionSupplier: React.FC<Props> = ({
  getSupplierAuction,
  suppliers,
  status,
  postAddToAuction,
  statusAddToAuction,
  deleteFromAuction,
  statusDeleteFromAuction,
  match
}) => {
  React.useEffect(
    () => {
      if (match.params.guid) {
        getSupplierAuction(match.params.guid);
      }
    },
    [match.params.guid]
  );

  React.useEffect(
    () => {
      if (statusAddToAuction === REQUEST_STATUSES.GOT) {
        getSupplierAuction(match.params.guid);
      }
    },
    [statusAddToAuction]
  );

  React.useEffect(
    () => {
      if (statusDeleteFromAuction === REQUEST_STATUSES.GOT) {
        getSupplierAuction(match.params.guid);
      }
    },
    [statusDeleteFromAuction]
  );

  return (
    <>
      {status === REQUEST_STATUSES.REQUEST && <Loader />}
      {status === REQUEST_STATUSES.GOT && (
        <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
          <TableHeaderStyled>
            <tr>
              <TableThStyled width="15%">Поставщик</TableThStyled>
              <TableThStyled width="15%">ИНН</TableThStyled>
              <TableThStyled width="10%">Статус</TableThStyled>
              <TableThStyled width="10%" />
            </tr>
          </TableHeaderStyled>
          <tbody>
            {suppliers.items.map(user => (
              <TableRowStyled key={user.guid}>
                <td>{user.supplierName}</td>
                <td>{user.supplierInn}</td>
                <td>
                  <Status status={user.supplierStatus}>
                    {user.supplierStatus === 10
                      ? 'Участвует'
                      : user.supplierStatus === 1 && 'Исключен'}
                  </Status>
                </td>
                <td>
                  {user.supplierStatus === 1 ? (
                    <ActionAuction
                      onClick={() =>
                        postAddToAuction(match.params.guid, {
                          SupplierInn: user.supplierInn
                        })
                      }
                    >
                      Вернуть в аукцион
                    </ActionAuction>
                  ) : (
                    user.supplierStatus === 10 && (
                      <ActionAuction
                        onClick={() =>
                          deleteFromAuction(match.params.guid, user.supplierInn)
                        }
                      >
                        Удалить из аукциона
                      </ActionAuction>
                    )
                  )}
                </td>
              </TableRowStyled>
            ))}
          </tbody>
        </TableStyled>
      )}
    </>
  );
};

const mapStateToProps = ({ SCF }: STORE) => ({
  suppliers: SCF.getSuppluerAuction.data,
  status: SCF.getSuppluerAuction.status,
  statusAddToAuction: SCF.postAddToAuction.status,
  statusDeleteFromAuction: SCF.deleteFromAuction.status
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getSupplierAuction,
      postAddToAuction,
      deleteFromAuction
    },
    dispatch
  );

const AuctionSupplierConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(AuctionSupplier)
);

export { AuctionSupplierConnect as AuctionSupplier };
