import styled from 'styled-components';
import { lightBlue, rl } from 'shared/styled';

export const Status = styled.div`
  width: 70%;
  padding: 15px 10px;
  margin: 10px 0;
  border-radius: 5px;
  text-align: center;
  white-space: pre-wrap;
  background-color: ${({ status }) =>
    status === 1
      ? 'rgba(253, 241, 224, 1)'
      : status === 10 && 'rgba(213, 232, 253, 1)'};
`;

export const ActionAuction = styled.p`
  font-family: ${rl};
  margin: 0;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: ${lightBlue};
  }
`;
