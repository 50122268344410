import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  UsersIcon,
  ClipboardIcon,
  LinkIcon,
  InboxIcon,
  GearIcon,
  FactoringIndicator,
  DiscountingIndicator,
  Popup,
  BarLinkCustom,
  TruckIcon,
  ArchiveIcon,
  GavelIcon,
  CounterBoxStyle,
  CounterBoxGreen
} from './styles';
import {
  BarSectionStyled,
  BarLinks,
  BarTitleArea,
  BarTitle,
  CounterBox,
  BarLinkText
} from 'src/features/SCF/UI/BarSection';

import { ResponseData } from 'entities/SCF/Debtor/model/reducers/getDebtorCounterSidebar';
import { REQUEST_STATUSES, STORE, USER_PERMISSIONS } from 'src/globaltypes';
import { Spinner } from 'Layouts/components';
import { NavigationLinkStyled } from 'src/features/Layouts/components/Sidebar/NavigationLink/styles';
import './styles/style.css';

interface StateToProps {
  counter: ResponseData;
  status: REQUEST_STATUSES;
  permissions: USER_PERMISSIONS[];
}

type Props = RouteComponentProps & StateToProps;

const LabelInfo = ({ info }) => !!info && <CounterBox>{info}</CounterBox>;

const BarSection: React.FC<Props> = ({ counter, status, permissions }) => {
  function showQuantity(key: string) {
    return status === REQUEST_STATUSES.REQUEST ? (
      <Spinner size={22} />
    ) : (
      counter[key]
    );
  }

  return (
    <BarSectionStyled>
      <BarLinks>
        <BarLinkCustom className="link-area">
          <NavigationLinkStyled
            to={{
              pathname: '/debtor/registries',
              search: '?page=1'
            }}
          >
            <ArchiveIcon />
            <BarLinkText className="link-text">Реестры</BarLinkText>
            {showQuantity('registriesQty') > 0 && (
              <CounterBoxStyle>{showQuantity('registriesQty')}</CounterBoxStyle>
            )}
          </NavigationLinkStyled>
        </BarLinkCustom>
        <BarLinkCustom className="link-area">
          <NavigationLinkStyled
            to={{
              pathname: '/debtor/supplies',
              search: '?page=1'
            }}
          >
            <TruckIcon />
            <BarLinkText className="link-text">Поставки</BarLinkText>
          </NavigationLinkStyled>
        </BarLinkCustom>
        <BarLinkCustom className="link-area">
          <NavigationLinkStyled
            to={{
              pathname: '/debtor/suppliers',
              search: '?page=1'
            }}
          >
            <UsersIcon />
            <BarLinkText className="link-text">Мои поставщики</BarLinkText>
            {showQuantity('newSuppliersQty') > 0 ? (
              <LabelInfo info={showQuantity('newSuppliersQty')} />
            ) : (
              showQuantity('suppliersQty') > 0 && (
                <CounterBoxStyle>
                  {showQuantity('suppliersQty')}
                </CounterBoxStyle>
              )
            )}
          </NavigationLinkStyled>
        </BarLinkCustom>
        <BarLinkCustom className="link-area">
          <NavigationLinkStyled
            to={{
              pathname: '/debtor/purchase_contracts',
              search: '?page=1'
            }}
          >
            <ClipboardIcon />
            <BarLinkText className="link-text">Договоры поставки</BarLinkText>
            {showQuantity('newSupplyContractsQty') ? (
              <LabelInfo info={showQuantity('newSupplyContractsQty')} />
            ) : (
              showQuantity('supplyContractsQty') > 0 && (
                <CounterBoxStyle>
                  {showQuantity('supplyContractsQty')}
                </CounterBoxStyle>
              )
            )}
          </NavigationLinkStyled>
        </BarLinkCustom>
      </BarLinks>
      {counter.factoring && !counter.discounting ? (
        <>
          <BarTitleArea>
            <FactoringIndicator
              className="icon-to-show-popup"
              factoring={counter.factoring}
            >
              {counter.factoring ? (
                <Popup className="icon-popup">
                  <p>Факторинг включен</p>
                </Popup>
              ) : (
                <Popup className="icon-popup">
                  <p>Факторинг выключен</p>
                </Popup>
              )}
            </FactoringIndicator>
            <BarTitle>Факторинг</BarTitle>
          </BarTitleArea>
          <BarLinks>
            <BarLinkCustom className="link-area">
              <NavigationLinkStyled
                to={{
                  pathname: '/debtor/links'
                }}
              >
                <LinkIcon />
                <BarLinkText className="link-text">Мои связки</BarLinkText>
              </NavigationLinkStyled>
            </BarLinkCustom>
            <BarLinkCustom className="link-area">
              <NavigationLinkStyled
                to={{
                  pathname: '/debtor/factoring_contracts',
                  search: '?page=1'
                }}
              >
                <ClipboardIcon />
                <BarLinkText className="link-text">
                  Договоры факторинга
                </BarLinkText>
              </NavigationLinkStyled>
            </BarLinkCustom>
          </BarLinks>
          <BarTitleArea>
            <DiscountingIndicator
              className="icon-to-show-popup"
              discounting={counter.discounting}
              discountLimit={counter.discountLimit}
            >
              {counter.discounting && counter.discountLimit >= 30000000 ? (
                <Popup className="icon-popup">
                  <p>Дисконтирование включено</p>
                </Popup>
              ) : counter.discounting && counter.discountLimit < 30000000 ? (
                <Popup className="icon-popup">
                  <p>
                    Дисконтирование включено. Остаток лимита ниже критической
                    отметки. Увеличьте лимит в настройках
                  </p>
                </Popup>
              ) : (
                <Popup className="icon-popup">
                  <p>
                    Дисконтирование выключено. Включить дисконтирование можно в
                    настройках
                  </p>
                </Popup>
              )}
            </DiscountingIndicator>
            <BarTitle>Динамический дисконт</BarTitle>
          </BarTitleArea>
          <BarLinks>
            <BarLinkCustom className="link-area">
              <NavigationLinkStyled
                to={{
                  pathname: '/debtor/discount_applications',
                  search: '?page=1'
                }}
              >
                <InboxIcon />
                <BarLinkText className="link-text">
                  Заявки ранней оплаты
                </BarLinkText>
                <LabelInfo
                  info={
                    permissions.includes(USER_PERMISSIONS.DEBTOR_SIGN)
                      ? showQuantity('epaInspectedQty') ||
                        showQuantity('epaNewQty')
                      : showQuantity('epaNewQty') ||
                        showQuantity('epaInspectedQty')
                  }
                />
              </NavigationLinkStyled>
            </BarLinkCustom>
            <BarLinkCustom className="link-area">
              <NavigationLinkStyled
                to={{
                  pathname: '/debtor/discount_auctions',
                  search: '?page=1'
                }}
              >
                <GavelIcon className="link-icon-gavel" />
                <BarLinkText className="link-text">Мои аукционы</BarLinkText>
                {showQuantity('emptyAuctionsQty') > 0 ? (
                  <LabelInfo info={showQuantity('emptyAuctionsQty')} />
                ) : showQuantity('activeAuctionsQty') > 0 ? (
                  <CounterBoxGreen>
                    {showQuantity('activeAuctionsQty')}
                  </CounterBoxGreen>
                ) : (
                  showQuantity('auctionsQty') > 0 && (
                    <CounterBoxStyle>
                      {showQuantity('auctionsQty')}
                    </CounterBoxStyle>
                  )
                )}
              </NavigationLinkStyled>
            </BarLinkCustom>
            <BarLinkCustom className="link-area">
              <NavigationLinkStyled
                to={{
                  pathname: '/debtor/discount_auctions',
                  search: '?page=1'
                }}
              >
                <GearIcon />
                <BarLinkText className="link-text">Настройки</BarLinkText>
              </NavigationLinkStyled>
            </BarLinkCustom>
          </BarLinks>
        </>
      ) : (
        <>
          <BarTitleArea>
            <DiscountingIndicator
              className="icon-to-show-popup"
              discounting={counter.discounting}
              discountLimit={counter.discountLimit}
            >
              {counter.discounting && counter.discountLimit >= 30000000 ? (
                <Popup className="icon-popup">
                  <p>Дисконтирование включено</p>
                </Popup>
              ) : counter.discounting && counter.discountLimit < 30000000 ? (
                <Popup className="icon-popup">
                  <p>
                    Дисконтирование включено. Остаток лимита ниже критической
                    отметки. Увеличьте лимит в настройках
                  </p>
                </Popup>
              ) : (
                <Popup className="icon-popup">
                  <p>
                    Дисконтирование выключено. Включить дисконтирование можно в
                    настройках
                  </p>
                </Popup>
              )}
            </DiscountingIndicator>
            <BarTitle>Динамический дисконт</BarTitle>
          </BarTitleArea>
          <BarLinks>
            <BarLinkCustom className="link-area">
              <NavigationLinkStyled
                to={{
                  pathname: '/debtor/discount_applications',
                  search: '?page=1'
                }}
              >
                <InboxIcon />
                <BarLinkText className="link-text">
                  Заявки ранней оплаты
                </BarLinkText>
                <LabelInfo
                  info={
                    permissions.includes(USER_PERMISSIONS.DEBTOR_SIGN)
                      ? showQuantity('epaInspectedQty') ||
                        showQuantity('epaNewQty')
                      : showQuantity('epaNewQty') ||
                        showQuantity('epaInspectedQty')
                  }
                />
              </NavigationLinkStyled>
            </BarLinkCustom>
            <BarLinkCustom className="link-area">
              <NavigationLinkStyled
                to={{
                  pathname: '/debtor/discount_auctions',
                  search: '?page=1'
                }}
              >
                <GavelIcon className="link-icon-gavel" />
                <BarLinkText className="link-text">Мои аукционы</BarLinkText>
                {showQuantity('emptyAuctionsQty') > 0 ? (
                  <LabelInfo info={showQuantity('emptyAuctionsQty')} />
                ) : showQuantity('activeAuctionsQty') > 0 ? (
                  <CounterBoxGreen>
                    {showQuantity('activeAuctionsQty')}
                  </CounterBoxGreen>
                ) : (
                  showQuantity('auctionsQty') > 0 && (
                    <CounterBoxStyle>
                      {showQuantity('auctionsQty')}
                    </CounterBoxStyle>
                  )
                )}
              </NavigationLinkStyled>
            </BarLinkCustom>
            <BarLinkCustom className="link-area">
              <NavigationLinkStyled
                to={{
                  pathname: '/debtor/settings/discounting'
                }}
              >
                <GearIcon />
                <BarLinkText className="link-text">Настройки</BarLinkText>
              </NavigationLinkStyled>
            </BarLinkCustom>
          </BarLinks>
          <BarTitleArea>
            <FactoringIndicator
              className="icon-to-show-popup"
              factoring={counter.factoring}
            >
              {counter.factoring ? (
                <Popup className="icon-popup">
                  <p>Факторинг включен</p>
                </Popup>
              ) : (
                <Popup className="icon-popup">
                  <p>Факторинг выключен</p>
                </Popup>
              )}
            </FactoringIndicator>
            <BarTitle>Факторинг</BarTitle>
          </BarTitleArea>
          <BarLinks>
            <BarLinkCustom className="link-area">
              <NavigationLinkStyled
                to={{
                  pathname: '/debtor/links'
                }}
              >
                <LinkIcon />
                <BarLinkText className="link-text">Мои связки</BarLinkText>
              </NavigationLinkStyled>
            </BarLinkCustom>
            <BarLinkCustom className="link-area">
              <NavigationLinkStyled
                to={{
                  pathname: '/debtor/factoring_contracts',
                  search: '?page=1'
                }}
              >
                <ClipboardIcon />
                <BarLinkText className="link-text">
                  Договоры факторинга
                </BarLinkText>
              </NavigationLinkStyled>
            </BarLinkCustom>
          </BarLinks>
        </>
      )}
    </BarSectionStyled>
  );
};

const mapStateToProps = ({ User, SCFDebtor }: STORE) => ({
  counter: SCFDebtor.getDebtorCounterSidebar.data,
  status: SCFDebtor.getDebtorCounterSidebar.status,
  permissions: User.getUserData.data.permissions
});

const BarSectionConnect = withRouter(
  connect<StateToProps>(mapStateToProps)(BarSection)
);

export { BarSectionConnect as BarSection };
