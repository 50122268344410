import styled, { keyframes } from 'styled-components';
import { HeaderHeight } from 'Layouts/components/Header/styles';
import { lighterBlue, rr, blue } from 'shared/styled';

export const ClaimsPageStyled = styled.section`
  margin-top: ${HeaderHeight};
  width: 80vw;
  height: auto;
  margin-left: 50px;
  padding-top: 50px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h2 {
    font-family: ${rr};
  }
`;

export const HeaderSection = styled.div`
  margin-top: 50px;
`;

export const HeaderFilters = styled.div`
  display: flex;
  column-gap: 100px;
  margin-bottom: 20px;
`;

export const HeaderActionSection = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  padding: 10px;
`;

export const HeaderActionSectionTop = styled.div`
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 10px;
  p {
    font-family: ${rr};
    font-size: 1em;
    margin: 0;
    width: 190px;
  }
`;

export const HeaderActionSectionBottom = styled.div`
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  p {
    font-family: ${rr};
    font-size: 1em;
    margin: 0;
  }
`;

export const HeaderActionSectionBottomItem = styled.div`
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  margin-right: 20px;
  p {
    font-family: ${rr};
    font-size: 1em;
    margin: 0;
    margin-right: 10px;
  }
`;

export const FilterArea = styled.div`
  padding: 20px 10px 20px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  background-color: ${lighterBlue};
`;

export const InnFilter = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  p {
    font-family: ${rr};
    font-size: 1em;
    margin: 0;
    margin-bottom: 5px;
  }
`;

export const InnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10px;
  margin-top: 5px;
  input {
    padding: 10px 10px;
    height: 40px;
    width: auto;
    border-radius: 5px;
    border: 1px solid #9fa6b2;
    font-family: ${rr};
  }
`;

export const ListSection = styled.section`
  margin-top: 30px;
`;

export const PeriodFilter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;
`;

export const PeriodFilterItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 30px;
  margin-bottom: 15px;
  background-color: ${({ active }) => (active ? '#f1f8ff' : 'transparent')};
  border-radius: 5px;
  margin-right: 20px;
  cursor: pointer;

  p {
    font-family: ${rr};
    font-size: 1em;
    margin: 0;
  }
`;

export const MaturityDateContainer = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 15px;
  cursor: pointer;

  & > div {
    margin: 0;
  }
`;

export const PaidFilter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const PaidFilterItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 30px;
  margin-bottom: 15px;
  background-color: ${({ active }) => (active ? '#f1f8ff' : 'transparent')};
  border-radius: 5px;
  margin-right: 20px;
  cursor: pointer;

  p {
    font-family: ${rr};
    font-size: 1em;
    margin: 0;
  }
`;

export const DatesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 0px;
  p {
    font-family: ${rr};
    margin-right: 10px;
  }
  input {
    padding: 5px 10px;
    height: 30px;
    width: auto;
    border-radius: 5px;
    border: 1px solid #9fa6b2;
    font-family: ${rr};
  }
`;

export const SignBtns = styled.div`
  width: 210px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SignBtn = styled.div`
  margin-left: 30px;
  width: 155px;
  height: auto;
  display: flex;

  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${blue};
  transition: 0.2s;
  &:hover {
    background-color: rgba(1, 96, 182, 1);
  }
`;

export const SignBtnText = styled.p`
  font-family: ${rr};
  font-weight: 500;
  font-size: 0.9em;
  padding: 15px 12px;
  line-height: 10%;
  color: #ffffff;
`;

export const Status = styled.div`
  width: auto;
  height: auto;
  text-align: center;
  border-radius: 5px;
  background-color: ${({ color }) => color};

  & > p {
    white-space: pre-wrap;
    font-family: ${rr};
    font-weight: 500;
    font-size: 0.9em;
    padding: 5px 10px;
    margin: 0;
  }
`;

export const StatusAccept = styled.p`
  color: #3f4e65;
`;

export const StatusPaid = styled.p`
  color: #ffffff;
`;

export const StatusUnavailable = styled.p`
  color: #3f4e65;
`;

export const StatuseEarlyPayment = styled.p`
  color: #3f4e65;
`;

export const DatePopup = styled.div`
  padding: 50px 20px;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;

  position: fixed;
  left: 0;
  z-index: 30;
  margin-top: -15px;
  margin-left: 45vw;
  display: none;
  background-color: #ffffff;
  border: 1px solid ${lighterBlue};
`;

export const DatePopupBtns = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
`;

export const CreateBtn = styled.div`
  margin-top: 20px;
  width: 120px;
  height: auto;
  display: flex;

  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${blue};
  transition: 0.2s;
  &:hover {
    background-color: rgba(1, 96, 182, 1);
  }
`;

export const CreateBtnText = styled.p`
  font-family: ${rr};
  font-weight: 600;
  font-size: 1.1em;
  padding: 8px 10px;
  color: #ffffff;
  margin: 0;
`;

export const CancelBtn = styled.div`
  margin-top: 20px;
  width: 90px;
  height: auto;
  display: flex;

  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: #f53737;
  transition: 0.2s;
  margin-left: 20px;
  &:hover {
    background-color: #c12323;
  }
`;

export const CancelBtnText = styled.p`
  font-family: ${rr};
  font-weight: 600;
  font-size: 1.1em;
  padding: 8px 10px;
  color: #ffffff;
  margin: 0;
`;

export const SummHint = styled.div`
  min-width: 250px;
  position: absolute;
  display: none;
  flex-direction: column;
  row-gap: 10px;
  top: 0;
  left: 50%;
  background-color: rgba(213, 232, 253, 1);
  padding: 15px;
  border-radius: 6px;
  z-index: 1;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      column-gap: 10px;
      border-bottom: 1px solid #bdd5fb;
      padding-bottom: 10px;
    }

    &:last-child {
      p {
        margin: 0;
      }
    }
  }
`;

export const TDSummContainer = styled.td`
  position: relative;
  overflow: visible !important;
  cursor: pointer;

  &:hover ${SummHint} {
    display: flex;
  }
`;

const loadingAnimation = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

export const SkeletonWrapper = styled.div`
  display: inline-block;
  line-height: 1;
  width: ${props => props.width || '100%'};
  height: ${props => props.height || '1em'};
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200px 100%;
  border-radius: 5px;
  animation: ${loadingAnimation} 1.5s infinite linear;
`;

export const DocumentHint = styled.div`
  min-width: 250px;
  position: absolute;
  display: none;
  flex-direction: column;
  row-gap: 10px;
  bottom: 0;
  left: 65%;
  background-color: rgba(213, 232, 253, 1);
  padding: 15px;
  border-radius: 6px;
  z-index: 1;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      column-gap: 10px;
      border-bottom: 1px solid #bdd5fb;
      padding-bottom: 10px;
    }

    &:last-child {
      p {
        margin: 0;
      }
    }
  }
`;

export const TDInvoiceDocumenContainer = styled.td`
  position: relative;
  overflow: visible !important;

  & > div:first-of-type {
    display: flex;
    flex-direction: column;
    row-gap: 5px;

    p {
      width: fit-content;
    }
  }

  &:hover ${DocumentHint} {
    display: flex;
  }
`;

export const InvoiceDocumentDate = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;

  p {
    margin: 0;
  }

  p:nth-of-type(2) {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const ContainerFilter = styled.div`
  display: flex;
  align-items: end;
  padding: 10px 20px;
  background-color: ${lighterBlue};

  span {
    align-self: center;
    margin: 20px 10px 0 10px;
  }

  & > div:nth-child(1),
  & > div:nth-child(4) {
    margin-right: 20px;
  }

  & > div:nth-child(1),
  & > div:nth-child(2),
  & > div:nth-child(4),
  & > div:nth-child(5),
  & > div:nth-child(7) {
    min-width: 160px;
  }
`;

export const SortContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;

  svg {
    cursor: pointer;
  }
`;

export const CheckIcon = styled.div`
  display: flex;

  height: 25px;
  width: 25px;
  margin-right: 8px;
  margin-top: 8px;
  background-size: contain;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.3s;
  background-image: url('data:image/svg+xml,
  <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
  <path fill="rgb(28, 202, 148)" d="M10.75 18.25L19.5625 9.4375L17.8125 7.6875L10.75 14.75L7.1875 11.1875L5.4375 12.9375L10.75 18.25ZM12.5 25C10.7708 25 9.14583 24.6717 7.625 24.015C6.10417 23.3592 4.78125 22.4688 3.65625 21.3438C2.53125 20.2188 1.64083 18.8958 0.985 17.375C0.328333 15.8542 0 14.2292 0 12.5C0 10.7708 0.328333 9.14583 0.985 7.625C1.64083 6.10417 2.53125 4.78125 3.65625 3.65625C4.78125 2.53125 6.10417 1.64042 7.625 0.98375C9.14583 0.327917 10.7708 0 12.5 0C14.2292 0 15.8542 0.327917 17.375 0.98375C18.8958 1.64042 20.2188 2.53125 21.3438 3.65625C22.4688 4.78125 23.3592 6.10417 24.015 7.625C24.6717 9.14583 25 10.7708 25 12.5C25 14.2292 24.6717 15.8542 24.015 17.375C23.3592 18.8958 22.4688 20.2188 21.3438 21.3438C20.2188 22.4688 18.8958 23.3592 17.375 24.015C15.8542 24.6717 14.2292 25 12.5 25Z" />
  </svg>
  ');
`;

export const PlusContainer = styled.div`
  display: flex;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  background-color: ${blue};
  transition: 0.3s;

  &:hover {
    background-color: #0160b6;
  }

  font-family: ${rr};
  font-size: 1.6em;
  padding: 5px 10px;
  margin: 0;
  color: #ffffff;
`;

export const DebtorInfo = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
`;

export const StatusAuction = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 10px;
  border-radius: 5px;
  padding: 3px 5px;
  border: 1px solid #e99f3b;
  transition: background-color 0.7s ease, width 0.5s ease;
  cursor: pointer;

  &:hover {
    background-color: #e99f3b;

    p {
      color: white !important;
    }
  }

  p {
    margin: 0;
  }
`;