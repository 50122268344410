import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { STORE, REQUEST_STATUSES, USER_PERMISSIONS } from 'globaltypes';
import { history } from 'src/shared/utils/History';

import {
  Document,
  APPLICATION_STATUSES,
  ContractFinancingRead,
  APPLICATION_TYPE_ROUTES
} from 'Application/types';
import {
  Spinner,
  ActionResponseBlock,
  ACTION_RESPONSE_TEMPLATE
} from 'Layouts/components';

import {
  ApplicationViewStyled,
  EditApplicationBtn,
  LoaderContainerStyled,
  ViewSeparatorTop
} from './styles';
import { set as setApplicationProcessMode } from 'Application/actions/setApplicationProcessMode';
import {
  RequestDataType as SetApplicationProcessModeRequestDataType,
  APPLICATION_PROCESS_MODES
} from 'Application/reducers/setApplicationProcessMode';
import { ResponseData } from 'src/features/Application/reducers/getCompanyHints';
import { ResponseData as ResponseProductOfApplication } from 'src/features/Application/actions/getProductApplication';
import { req as postSelectApplication } from 'Application/actions/postSelectApplication';
import { req as getExternalApiGIR_BO } from 'Application/actions/getExternalApiGIR_BO';
import { ResponseData as ResDocumentListByApplicationId } from 'src/features/BorrowerCompanies/actions/getDocumentListByApplicationId';
import { ResponseData as FinancailsSummaryData } from 'Application/reducers/getFinancailsSummary';
import {
  ModalContainer,
  PurchaseContent
} from 'pages/Cabinet/InternalAgent/Pages/ApplicationProccess/GuaranteesForm/GuaranteesFormStep0/styles';
import { DelegateInternalAgentToApplicationSelect } from 'src/features/Application/components/ApplicationView/EachApplicationView/DelegateInternalAgentToApplicationSelect/DelegateInternalAgentToApplicationSelect';
import { SwitchBanksBlock } from './SwitchBanksBlock/SwitchBanksBlock';
import { ContentContainer } from './ContentContainer/ContentContainer';
import { ActionButtonsBlock } from './ActionButtonsBlock/ActionButtonsBlock';
import { ChooseBanksModal } from './ChooseBanksModal/ChooseBanksModal';
import { ActionModal } from './ActionModal/ActionModal';
import { ANIMATION_NAMES, TransitionWrapper } from 'src/features/Common';
import { CommentTabs } from './CommentTabs/CommentTabs';
import { redirectToApplication } from 'shared/lib/routing/redirectToApplication';

export interface Info {
  name: string;
  value: JSX.Element | string;
}

export interface IApplicationInfo {
  mainInfo: Info[];
  subInfo: Info[];
}

export interface IApplicationDocuments {
  documents?: {
    general: Document[];
    company: Document[] | any;
  };
}

export type IApplicationData = IApplicationInfo & IApplicationDocuments;

export interface IOwnProps extends IApplicationData {
  children?: any;
}

interface StateToProps {
  externalApplication: Partial<ContractFinancingRead>;
  statusExternalApplication: REQUEST_STATUSES;
  switchBankStatus: REQUEST_STATUSES;
  sendToBankStatus: REQUEST_STATUSES;
  permissions: USER_PERMISSIONS[];
  roles: string[];
  companyHints: ResponseData[];
  productOfApplication: ResponseProductOfApplication[];
  financailsSummaryData: FinancailsSummaryData[];
  statusExternalApiGIR_BO: REQUEST_STATUSES;
  statusFinancailsSummaryData: REQUEST_STATUSES;
  sendApiError: string;
  documentList: ResDocumentListByApplicationId;
}

interface DispatchToProps {
  setApplicationProcessMode: (
    data: SetApplicationProcessModeRequestDataType
  ) => void;
  postSelectApplication: (data: any) => void;
  getExternalApiGIR_BO: (inn: string, year: number) => void;
}

interface MatchParams {
  id: string;
  inBankId?: string;
}

type Props = RouteComponentProps<MatchParams> &
  IOwnProps &
  StateToProps &
  DispatchToProps;

const EachApplicationView: React.FC<Props> = ({
  setApplicationProcessMode,
  externalApplication,
  statusExternalApplication,
  switchBankStatus,
  sendToBankStatus,
  permissions,
  roles,
  mainInfo,
  subInfo,
  documents,
  companyHints,
  productOfApplication,
  postSelectApplication,
  getExternalApiGIR_BO,
  statusExternalApiGIR_BO,
  financailsSummaryData,
  statusFinancailsSummaryData,
  sendApiError,
  documentList,
  children,
  match
}: Props) => {
  const productRef = React.useRef<HTMLDivElement>(null);
  const sendApiErrorModalRef = React.useRef<HTMLDivElement>(null);
  const [isSendApiError, setIsSendApiError] = React.useState(false);
  const [
    filtredFinancailsSummaryData,
    setFiltredFinancailsSummaryData
  ] = React.useState([]);

  React.useEffect(
    () => {
      if (statusExternalApplication === REQUEST_STATUSES.GOT) {
        redirectToApplication(externalApplication);
      }
    },
    [externalApplication, statusExternalApplication]
  );

  React.useEffect(
    () => {
      if (!!financailsSummaryData.length) {
        setFiltredFinancailsSummaryData(
          financailsSummaryData.sort((a, b) => a.year - b.year)
        );
      }
    },
    [financailsSummaryData]
  );

  const scrollToProduct = () => {
    if (productRef.current) {
      productRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleOutsideClick = (
    event: MouseEvent,
    targetName: React.RefObject<unknown>
  ) => {
    const target = event.target as Element;

    if (
      targetName.current &&
      !(targetName.current as Element).contains(target)
    ) {
      setIsSendApiError(false);
    }
  };

  const sendRequestsGIR_BO = (year: number) => {
    if (year < 2019) return;

    sendRequest(year)
      .then(() => sendRequestsGIR_BO(year - 1))
      .catch(() => sendRequestsGIR_BO(year - 1));
  };

  const sendRequest = (year: number) => {
    return new Promise((resolve, reject) => {
      resolve(getExternalApiGIR_BO(externalApplication.code, year));
    });
  };

  return (
    <ApplicationViewStyled>
      {/* Application modals, opens when you click on ActionButtons */}
      <ActionModal />
      <ChooseBanksModal />

      <ViewSeparatorTop>
        <TransitionWrapper in={true} animationName={ANIMATION_NAMES.FADE}>
          <EditApplicationBtn
            onClick={() => {
              setApplicationProcessMode({
                mode: APPLICATION_PROCESS_MODES.EDIT
              });

              history.push(
                `/cabinet/partner/application-form-${APPLICATION_TYPE_ROUTES[
                  externalApplication.financingType
                ].replace('_', '-')}/edit/${externalApplication.id}`
              );
            }}
          >
            Редактировать заявку
          </EditApplicationBtn>
        </TransitionWrapper>
      </ViewSeparatorTop>

      {/* Change application id block.
        Visible only in if application was transfered to banks  */}
      <SwitchBanksBlock />

      {/* Application content */}
      <ContentContainer
        application={externalApplication}
        mainInfo={mainInfo}
        subInfo={subInfo}
        documents={documents}
        permissions={permissions}
        roles={roles}
        companyHints={companyHints}
        productOfApplication={productOfApplication}
        postSelectApplication={postSelectApplication}
        scrollToProduct={scrollToProduct}
        sendRequestsGIR_BO={sendRequestsGIR_BO}
        financailsSummaryData={filtredFinancailsSummaryData}
        statusFinancailsSummaryData={statusFinancailsSummaryData}
        statusExternalApiGIR_BO={statusExternalApiGIR_BO}
        documentList={documentList}
      >
        {children}
      </ContentContainer>

      {/* Application action buttons block,
        contains specific set of buttons, which changes application status */}
      <ActionButtonsBlock />

      {permissions.includes(USER_PERMISSIONS.DELEGATE_TO_INTERNAL_AGENTS) &&
        externalApplication.status ===
          APPLICATION_STATUSES.TRANSFERRED_TO_AGENT && (
          <DelegateInternalAgentToApplicationSelect
            applicationId={externalApplication.id}
          />
        )}
      {/* Application Comments, not visible in drafts */}
      <CommentTabs />

      <ModalContainer
        visible={isSendApiError}
        onClick={e => handleOutsideClick(e, sendApiErrorModalRef)}
      >
        <PurchaseContent ref={sendApiErrorModalRef}>
          <p>{sendApiError}</p>
        </PurchaseContent>
      </ModalContainer>

      {(switchBankStatus === REQUEST_STATUSES.REQUEST ||
        sendToBankStatus === REQUEST_STATUSES.REQUEST) && (
        <LoaderContainerStyled>
          <Spinner size={100} />
        </LoaderContainerStyled>
      )}

      <ActionResponseBlock
        showIn={switchBankStatus === REQUEST_STATUSES.ERROR}
        template={ACTION_RESPONSE_TEMPLATE.UNEXPECTED_ERROR}
      />
    </ApplicationViewStyled>
  );
};

const mapStateToProps = ({
  Application,
  ExternalAgentRole,
  User,
  Leads,
  BorrowerCompanies
}: STORE) => ({
  externalApplication: ExternalAgentRole.getApplicationExternal.data,
  statusExternalApplication: ExternalAgentRole.getApplicationExternal.status,
  companyHints: Application.getCompanyHints.data,
  switchBankStatus: Application.getInBankApplication.status,
  sendToBankStatus: Application.sendApplicationToBanks.status,
  productOfApplication: Application.getProductApplication.data,
  statusProductOfApplication: Application.getProductApplication.status,
  errorProductOfApplication: Application.getProductApplication.error,
  permissions: User.getUserData.data.permissions,
  roles: User.getUserData.data.roles,
  activities: Application.getActivitiesByApplication.data,
  statusActivities: Application.getActivitiesByApplication.status,
  errorActivities: Application.getActivitiesByApplication.error,
  statusPostAlfaBank: Application.postExternakApiAlfaBank.status,
  statusPostPSB: Application.postExternakApiPSB.status,
  financailsSummaryData: Application.getFinancialsSummary.data,
  statusFinancailsSummaryData: Application.getFinancialsSummary.status,
  statusExternalApiGIR_BO: Application.getExternalApiGIR_BO.status,
  statusSendToBank: Application.putSendToBank.status,
  errorSendToBank: Application.putSendToBank.error,
  openLoanLimits: Application.getOpenLoanLimits.data,
  statusOpenLoanLimits: Application.getOpenLoanLimits.status,
  errorOpenLoanLimits: Application.getOpenLoanLimits.error,
  statusCompanyToScoring: Leads.postSendCompanyToScoring.status,
  sendApiError: Application.postOfferSend.error.body,
  documentList: BorrowerCompanies.getDocumentListByApplicationId.data
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { setApplicationProcessMode, postSelectApplication, getExternalApiGIR_BO },
    dispatch
  );

const EachApplicationViewConnect = withRouter(
  connect<StateToProps, DispatchToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(EachApplicationView)
);

export { EachApplicationViewConnect as EachApplicationView };
