import * as React from 'react';
import { Route, Switch } from 'react-router';
import { DiscountAuctionsList } from './DiscountAuctionsList/DiscountAuctionsList';
import { DiscountAuctionsView } from './DiscountAuctionsView/DiscountAuctionsView';
import { PageStyled } from './styled';

const DiscountAuctionsPage = () => {
  return (
    <PageStyled>
      <Switch>
        <Route
          exact={true}
          path={'/debtor/discount_auctions'}
          render={() => <DiscountAuctionsList />}
        />

        <Route
          exact={true}
          path={'/debtor/discount_auctions/:guid/:tab?'}
          render={() => <DiscountAuctionsView />}
        />
      </Switch>
    </PageStyled>
  );
};

export default DiscountAuctionsPage;
