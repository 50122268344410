import * as React from 'react';
import { connect } from 'react-redux';
import { history } from 'src/shared/utils/History';
import { Dispatch, bindActionCreators } from 'redux';
import { Loader, Pagination } from 'Layouts/components';
import {
  req as getMyBuyers,
  RequestDataType,
  ResponseDataType
} from 'src/features/SCF/actions/getMyBuyers';
import { req as postAddBuyer } from 'src/features/SCF/actions/postAddBuyer';
import { req as putRejectDebtor } from 'entities/SCF/Debtor/model/actions/putRejectDebtor';

import {
  AddBtn,
  AddBtnText,
  InnFilter,
  InnContainer,
  ListSection,
  FactoringCheck,
  FactoringBtn,
  FactoringBtnText,
  SpinnerIcon,
  CheckIcon,
  StatusContainer,
  StatusArea,
  IconPopup,
  StatusAreaRow,
  ConfirmContainer,
  HandleContainer,
  DeclineContainer,
  TimesIcon,
  GreyIcon
} from './styles';
import {
  PaginationWrapper,
  CreateLimitsNudge,
  PageStyled
} from 'src/features/SCF/UI/ScfStyles';
import './styles/style.css';
import 'src/features/SCF/UI/simpleCSS/style.css';
import { STORE, REQUEST_STATUSES, ResponseError } from 'globaltypes';
import {
  TableHeaderCellStyled,
  TableStyled
} from 'src/features/SCF/UI/Table/styles';
import { TableRowStyled } from 'src/features/SCF/UI/Table/TableRow/styles';
import { TableHeaderStyled } from 'src/features/SCF/UI/Table/TableHeader/styles';
import SCFApi from 'src/features/SCF/api';
import { Href } from 'history';
import {
  req as checkAvailableLimits,
  RequestDataType as LimitsRequestDataType,
  AvailableLimits
} from 'src/features/SCF/actions/checkAvailableLimits';
import { Button } from 'src/shared/ui/Button';

interface StateToProps {
  status: REQUEST_STATUSES;
  error: ResponseError;
  myBuyers: ResponseDataType;
  buyersUploadStatus: REQUEST_STATUSES;
  availableLimits: AvailableLimits[];
}

interface DispatchToProps {
  getMyBuyers: (data: RequestDataType) => void;
  checkAvailableLimits: (data: LimitsRequestDataType) => void;
  postAddBuyer: (inn: string) => void;
  putRejectDebtor: (inn: string) => void;
}

interface State {
  pageUrl: Href;
  innToFilter: string;
  myBuyers: ResponseDataType['items'];
}

type Props = StateToProps & DispatchToProps;

class BuyersPage extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    {
      this.state = {
        pageUrl: '',
        innToFilter: '',
        myBuyers: []
      };
    }
  }

  handlePageChange = (newHref: Href) => {
    newHref = window.location.href;
    this.setState({ pageUrl: newHref });
    window.scrollTo({
      top: 500,
      behavior: 'smooth'
    });
  };

  handleInnToFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ innToFilter: event.target.value });
  };

  handleCheckChange() {
    const { myBuyers } = this.props;
    let innListToCheck: string;
    let innListToCheckPrettify: string;
    myBuyers.items.map(value => {
      innListToCheck += value.debtorInn + ' ';
      innListToCheckPrettify = innListToCheck.replace('undefined', '');
    });
    this.props.checkAvailableLimits({ innlist: innListToCheckPrettify });
  }

  onRawClick = (inn: string) => {
    history.push(`/supplier/debtors/${inn}`);
  };

  onFactoringBtnClick = () => {
    history.push(`/supplier/factoring_connection`);
  };

  initFetch = () => {
    const { getMyBuyers } = this.props;
    const urlParams = new URLSearchParams(history.location.search);
    const page = parseInt(urlParams.get('page'), 10);
    {
      this.state.innToFilter == ''
        ? getMyBuyers({ page, pageSize: 10 })
        : getMyBuyers({ page: 1, debtorInnToFilterBy: this.state.innToFilter });
    }
  };

  findInn(index: string) {
    const res = this.props.availableLimits.find(x => x.inn == index);
    return res === undefined ? (
      <SpinnerIcon className="rotating" />
    ) : res.hasLimit == false ? (
      <FactoringCheck
        onClick={() => {
          this.onRawClick(res.inn);
        }}
      />
    ) : (
      <FactoringCheck>
        <CheckIcon />
        <p>Есть лимиты</p>
        <FactoringBtn
          onClick={e => {
            e.stopPropagation();
            SCFApi.postFactoringConnection({
              debtorINN: res.inn,
              debtorName: res.companyName,
              factorINN: undefined,
              factorName: undefined,
              visibleToDebtor: true,
              userID: undefined
            });
          }}
        >
          <FactoringBtnText>Подобрать фактора</FactoringBtnText>
        </FactoringBtn>
      </FactoringCheck>
    );
  }

  componentDidMount() {
    this.initFetch();
  }

  componentDidUpdate(prevProps, prevState: State) {
    if (prevProps.status !== this.props.status) {
      this.setState({ myBuyers: this.props.myBuyers.items });
    }
    if (
      this.props.buyersUploadStatus !== prevProps.buyersUploadStatus ||
      this.state.pageUrl !== prevState.pageUrl ||
      this.state.innToFilter !== prevState.innToFilter
    ) {
      this.initFetch();
    } else if (this.props.myBuyers !== prevProps.myBuyers) {
      this.handleCheckChange();
    }
  }

  AddDebtorsClick = () => {
    history.push('/supplier/debtors/add');
  };

  acceptSupplier = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    inn: string,
    index: number
  ) => {
    e.stopPropagation();
    const newData = [...this.state.myBuyers];
    newData[index].confirmedBySupplier = true;
    this.setState({ myBuyers: newData });
    this.props.postAddBuyer(inn);
  };

  declineSupplier = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    inn: string
  ) => {
    e.stopPropagation();
    const newData = [...this.state.myBuyers];
    const filtredData = newData.filter(buyer => buyer.debtorInn !== inn);
    this.setState({ myBuyers: filtredData });
    this.props.putRejectDebtor(inn);
  };

  render() {
    const { status } = this.props;
    const { myBuyers } = this.state;

    return (
      <PageStyled>
        <CreateLimitsNudge>
          <h2>Мои покупатели</h2>
          <Button
            label="+ Добавить покупателей"
            onClick={this.AddDebtorsClick}
          />
        </CreateLimitsNudge>
        <InnFilter>
          <p>ИНН Покупателя</p>
          <InnContainer>
            <input
              type="text"
              placeholder="XXXXXXXXXX"
              maxLength={10}
              onChange={this.handleInnToFilterChange}
              className="input-inn"
            />
          </InnContainer>
        </InnFilter>
        <ListSection>
          {status === REQUEST_STATUSES.REQUEST && <Loader />}
          {status === REQUEST_STATUSES.GOT && (
            <TableStyled sizes={[]} cellSpacing="0" cellPadding="0">
              <TableHeaderStyled>
                <tr>
                  <TableHeaderCellStyled width="20%">
                    ИНН ПОКУПАТЕЛЯ
                  </TableHeaderCellStyled>
                  <TableHeaderCellStyled width="40%">
                    НАИМЕНОВАНИЕ ПОКУПАТЕЛЯ
                  </TableHeaderCellStyled>
                  <TableHeaderCellStyled width="35%">
                    Статус
                  </TableHeaderCellStyled>
                  <TableHeaderCellStyled width="35%">
                    ФАКТОРИНГ
                  </TableHeaderCellStyled>
                </tr>
              </TableHeaderStyled>
              <tbody>
                {myBuyers.map((item, key) => (
                  <TableRowStyled
                    key={key}
                    className="table-row"
                    onClick={() => this.onRawClick(item.debtorInn)}
                  >
                    <td>{item.debtorInn}</td>
                    <td>{item.debtorName}</td>
                    <td>
                      <StatusContainer>
                        {item.confirmedByDebtor && item.confirmedBySupplier ? (
                          <>
                            <StatusArea className="icon-to-show-popup">
                              <CheckIcon />
                              <IconPopup className="icon-popup">
                                <p>Подтверждено покупателем</p>
                              </IconPopup>
                            </StatusArea>
                            <StatusArea className="icon-to-show-popup">
                              <CheckIcon />
                              <IconPopup className="icon-popup">
                                <p>Подтверждено вами</p>
                              </IconPopup>
                            </StatusArea>
                          </>
                        ) : !item.confirmedBySupplier &&
                        item.confirmedByDebtor &&
                        !item.rejectedBySupplier ? (
                          <>
                            <StatusArea className="icon-to-show-popup">
                              <CheckIcon />
                              <IconPopup className="icon-popup">
                                <p>Подтверждено покупателем</p>
                              </IconPopup>
                            </StatusArea>
                            <StatusAreaRow>
                              <ConfirmContainer>
                                <HandleContainer
                                  onClick={e =>
                                    this.acceptSupplier(e, item.debtorInn, key)
                                  }
                                >
                                  <p>Подтвердить</p>
                                </HandleContainer>
                              </ConfirmContainer>
                              <DeclineContainer>
                                <HandleContainer
                                  onClick={e =>
                                    this.declineSupplier(e, item.debtorInn)
                                  }
                                >
                                  <p>Отклонить</p>
                                </HandleContainer>
                              </DeclineContainer>
                            </StatusAreaRow>
                          </>
                        ) : item.confirmedBySupplier &&
                        !item.confirmedByDebtor &&
                        item.rejectedByDebtor ? (
                          <>
                            <StatusArea className="icon-to-show-popup">
                              <CheckIcon />
                              <IconPopup className="icon-popup">
                                <p>Подтверждено вами</p>
                              </IconPopup>
                            </StatusArea>
                            <StatusArea className="icon-to-show-popup">
                              <TimesIcon />
                              <IconPopup className="icon-popup">
                                <p>Отклонено покупателем</p>
                              </IconPopup>
                            </StatusArea>
                          </>
                        ) : (
                          item.confirmedBySupplier &&
                          !item.confirmedByDebtor &&
                          !item.rejectedByDebtor && (
                            <>
                              <StatusArea className="icon-to-show-popup">
                                <CheckIcon />
                                <IconPopup className="icon-popup">
                                  <p>Подтверждено вами</p>
                                </IconPopup>
                              </StatusArea>
                              <StatusArea className="icon-to-show-popup">
                                <GreyIcon />
                                <IconPopup className="icon-popup">
                                  <p>Еще не подтверждено покупателем</p>
                                </IconPopup>
                              </StatusArea>
                            </>
                          )
                        )}
                      </StatusContainer>
                    </td>
                    <td>{this.findInn(item.debtorInn)}</td>
                  </TableRowStyled>
                ))}
              </tbody>
            </TableStyled>
          )}

          <PaginationWrapper onClick={this.handlePageChange}>
            <Pagination list={this.props.myBuyers} />
          </PaginationWrapper>
        </ListSection>
      </PageStyled>
    );
  }
}

const mapStateToProps = ({ SCF }: STORE) => ({
  myBuyers: SCF.getMyBuyers.data,
  status: SCF.getMyBuyers.status,
  error: SCF.getMyBuyers.error,
  buyersUploadStatus: SCF.sendBuyers.status,
  availableLimits: SCF.checkAvailableLimits.data
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getMyBuyers,
      checkAvailableLimits,
      postAddBuyer,
      putRejectDebtor
    },
    dispatch
  );

const BuyersPageConnect = connect<StateToProps, DispatchToProps>(
  mapStateToProps,
  mapDispatchToProps
)(BuyersPage);

export { BuyersPageConnect as BuyersPage };
