import styled from 'styled-components';
import { rl, rr } from 'shared/styled';

export const SectionSeparatorStyled = styled.div`
  padding-top: 5px;
  margin: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #686f86;

  font-family: ${rr};
  font-size: 14px;
  text-decoration: none;
  color: #686f86;
`;

export const CounterBox = styled.span`
  position: absolute;
  right: 5px;
  top: 20%;
  font-family: ${rl};
  background-color: #e99f3b;
  padding: 9px 11px 8px 11px;
  color: black;
  border-radius: 6px;
`;

export const CounterBoxGreen = styled(CounterBox)`
  background-color: #5fc798;
`;